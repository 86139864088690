import { event } from "vue-gtag";

export default function (type, precost) {
  if (!window.gtag) return false;

  if (window.gtag) {
    event("draft_sa", {
      click: type,
      value: precost,
    });
  }
}
