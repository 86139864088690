// import { HTTP } from "@/components/api/index.js";
// import VueCookies from "vue-cookies";
// import { Application } from "@/components/api/";
// import { isWhiteLabel, whiteLabelInfo } from "@sutochno/whitelabel";

const state = () => ({
  token: null,
  // viewPort: null,
  // currency: {
  //   id: 1,
  //   title: "RUB",
  // },
  // currenciesList: null,
  modalsToggle: {
    galleryDetail: false,
    guestsDetail: false,
    roomInformation: false,
  },
  panelFriend: false,
  is_mobile: false,
  // isWhiteLabel, // значение  true/false - показывает запущен ли серчапп в режиме вайтлейбла
  // whiteLabelInfo,
  isWebview: false,
  loyaltyCardNumber: null,
});

// getters
const getters = {
  // currenciesList: (state) => {
  //   if (state.currenciesList) {
  //     return state.currenciesList;
  //   }
  // },
  whitelabelLinkTarget: (state) => state.isWebview ? '_self': '_blank'
};

// actions
const actions = {
  getToken({ commit }) {
    commit("getToken");
  },
  // setCurrency({ commit }, currency) {
  //   let formData = new FormData();
  //   let currencyObj = {
  //     id: currency.id,
  //     title: currency.code,
  //   };
  //   formData.append("id", currencyObj.id);
  //   HTTP.post("/json/currencies/setCurrency", formData)
  //     .then(
  //       commit("currency", currencyObj),
  //       VueCookies.set("wp_currency_id", currencyObj.id, "30d"),
  //       VueCookies.set("wp_currency_title", currencyObj.title, "30d")
  //     )
  //     .catch((error) => {
  //       console.log("Ошибка при смене валюты", "Ajax error:", error);
  //     });
  // },
  // getCurrencies({ commit }) {
  //   Application.getCurrencies()
  //     .then((response) => {
  //       if (response.data) commit("currencies", response.data);
  //     })
  //     .catch((error) => {
  //       console.log("Ошибка при получении данных", "Ajax error:", error);
  //     });
  // },
};

// mutations
const mutations = {
//   viewPort(state, value) {
//     state.viewPort = value;
//   },
//   currency(state, value) {
//     state.currency = value;
//     localStorage.setItem("currency", value);
//   },
//   currencies(state, value) {
//     state.currenciesList = value.data.currencies;
//   },
  setModalsToggle(state, value) {
    state.modalsToggle[value.name] = value.val;
  },
  setPanelFriend(state, value) {
    state.panelFriend = value
  },
  setIsWhiteLabel(state, value) {
    state.isWhiteLabel = value
  },
  setWhiteLabelInfo(state, value) {
    state.whiteLabelInfo = value
  },
  setIsWebview(state, value) {
    state.isWebview = value
  },
  setIsMobile(state, value) {
    state.is_mobile = value
  },
  setLoyaltyCardNumber(state, value) {
    state.loyaltyCardNumber = value;
  },
};

export default {
  namespaced: true,
  namespace: "application",
  state,
  getters,
  actions,
  mutations,
};

