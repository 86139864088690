import { useStore } from "vuex";
import { isMobileOnly } from "mobile-device-detect";

export default () => {
  const store = useStore();

  const checkViewPort = () => {
    let viewPort = null;
    const isIOS =/ip(hone|ad|od)/i.test(navigator.userAgent.toLowerCase());
    
    if (window.innerWidth <= 768) {
      viewPort = "mobile";
    } else if (window.innerWidth > 768) {
      viewPort = isMobileOnly ? "mobile" : "desktop";
    }
    store.commit("user/viewPort", viewPort);

    store.commit("user/setIsIOS", isIOS);
  }

  return {
    checkViewPort,
  }
}