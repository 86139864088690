<i18n>
{
  "ru": {
    "address": "Введите адрес",
    "choiseDates": "Выберите даты",
    "guestChoice":"Выбор гостей",
    "guests": "Гости",
    "adult": "0|{n} взрослый | {n} взрослых | {n} взрослых",
    "guest": "0|{n} гость | {n} гостя | {n} гостей",
    "withPets": "питомец",
    "days": "{n} суток | {n} сутки | {n} суток",
    "searchInMap": "Поиск по карте"
  },
  "en": {
    "address": "Enter an address",
    "choiseDates": "Choose dates",
    "guestChoice":"Guest Choice",
    "guests": "Guests",
    "adult": "0|{n} adult | {n} adults | {n} adults",
    "guest": "0|{n} guest | {n} guest | {n} guests",
    "withPets": "pet",
    "days": "{n} days | {n} day | {n} days",
    "searchInMap": "Search by map"
  }
}
</i18n>

<template>
  <PageModal @back="back" v-show="showOptionType" :closeArrow="true">
    <template #header>
      <div class="top">
        <div
          class="location regular-p5"
          :class="{
            active: showChoiseLocation,
            'semibold-p5': showChoiseLocation,
          }"
        >
          <template v-if="locationText">
            {{ locationText }}
          </template>
          <template v-else>
            {{
              searchInMap && $route.name !== "Main"
                ? t("searchInMap")
                : t("address")
            }}
          </template>
        </div>
        <div class="date-guests regular-p6">
          <div
            class="date"
            :class="{
              active: showChoiseDates,
              'semibold-p6': showChoiseDates,
            }"
          >
            {{ dateForamted }},
          </div>
          <div
            class="guests"
            :class="{
              active: showChoiseGuests,
              'semibold-p6': showChoiseGuests,
            }"
          >
            {{ getGuestsText }}
          </div>
        </div>
      </div>
    </template>
    <template #default>
      <transition name="fade">
        <ChoiseLocation
          v-show="showChoiseLocation"
          :locationText="locationText"
          :modelValue="location"
          :showOnlyOptions="showOnlyOptions"
          @update:modelValue="setLocation"
          @finishChoiseLocation="finishChoiseLocation"
        />
      </transition>
      <transition name="fade">
        <ChoiseDates
          v-show="showChoiseDates"
          v-model:dates="dates"
          :activeField="activeField"
          :showOnlyOptions="showOnlyOptions"
          @finishChoiseDates="finishChoiseDates"
        />
      </transition>
      <transition name="fade">
        <ChoiseGuests
          v-show="showChoiseGuests"
          v-model="guests"
          :showOnlyOptions="showOnlyOptions"
          @finishChoiseGuests="finishChoiseGuests"
        />
      </transition>
    </template>
  </PageModal>
</template>

<script setup>
import { nextTick, computed } from "vue";
import ChoiseLocation from "./ChoiseLocation";
import ChoiseDates from "./ChoiseDates/index.vue";
import ChoiseGuests from "./ChoiseGuests/index.vue";
import PageModal from "./PageModal";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

const { t, locale } = useI18n();
const store = useStore();

const emits = defineEmits([
  "update:showOptionType",
  "update:location",
  "setTypeLocation",
  "update:dates",
  "update:guests",
  "resetOptions",
  "search",
]);
const props = defineProps({
  location: {
    type: Object,
    default: function () {
      return null;
    },
  },
  dates: {
    type: Object,
    default: function () {
      return {
        in: null,
        out: null,
      };
    },
  },
  guests: {
    type: Object,
    default: function () {
      return {
        adults: 2,
        childrens: [],
        pets: {
          value: false,
          description: "",
        },
      };
    },
  },
  showOptionType: {
    type: String,
    validator(value) {
      return ["location", "dates-in", "dates-out", "guests", ""].includes(
        value
      );
    },
    default: "",
  },
  locationTerm: String,
  showOnlyOptions: Boolean,
});

const locationText = computed(
  () => props.location?.title || props.locationTerm
);
const searchInMap = computed(() => store.state.search.searchInMap);

// переключение опций(выбор локации, дат, гостей)
const showChoiseLocation = computed(() => props.showOptionType === "location");
const showChoiseDates = computed(
  () =>
    props.showOptionType === "dates-in" || props.showOptionType === "dates-out"
);
const showChoiseGuests = computed(() => props.showOptionType === "guests");

async function finishChoiseLocation(locationValue = {}, listType = "") {
  if (listType === "history") {
    if (!locationValue.date?.in && !locationValue.date?.out) {
      emits("update:showOptionType", "dates-in");
      return;
    }

    if (!locationValue.guests?.adults) {
      emits("update:showOptionType", "guests");
      return;
    }

    setLocation(locationValue, listType);
    await nextTick();
    emits("search");
    return;
  }

  if (props.showOnlyOptions) {
    emits("update:showOptionType", "dates-in");
  } else {
    emits("update:showOptionType", "");
  }
}
function finishChoiseDates() {
  if (props.showOnlyOptions) {
    emits("update:showOptionType", "guests");
  } else {
    emits("update:showOptionType", "");
  }
}
function finishChoiseGuests() {
  if (props.showOnlyOptions) {
    emits("search");
    emits("update:showOptionType", "");
  } else {
    emits("update:showOptionType", "");
  }
}
function back() {
  if (!props.showOnlyOptions) {
    emits("update:showOptionType", "");
    return;
  }

  if (showChoiseLocation.value) {
    emits("update:showOptionType", "");
    emits("resetOptions");
  }
  if (showChoiseDates.value) {
    emits("update:showOptionType", "location");
  }
  if (showChoiseGuests.value) {
    emits("update:showOptionType", "dates-in");
  }
}

// выбор локации
function setLocation(location, type = "") {
  emits("update:location", location);
  emits("setTypeLocation", type);
}

// изменение дат
const dates = computed({
  get() {
    const result = {
      in: null,
      out: null,
    };
    if (props.dates?.in) {
      result.in = new Date(props.dates.in.setHours(0, 0, 0, 0));
    }
    if (props.dates?.out) {
      result.out = new Date(props.dates.out.setHours(0, 0, 0, 0));
    }
    return result;
  },
  set(value) {
    emits("update:dates", value);
  },
});
const activeField = computed(() => {
  if (props.showOptionType === "dates-in") {
    return "in";
  }
  if (props.showOptionType === "dates-out") {
    return "out";
  }
  return "";
});

// изменение гостей
const guests = computed({
  get() {
    return props.guests;
  },
  set(value) {
    emits("update:guests", value);
  },
});

// вывод общей информации
const isOneYear = computed(() => {
  if (props.dates?.in && props.dates?.out) {
    return props.dates.in.getFullYear() === props.dates.out.getFullYear();
  }
  return true;
});
const isOneMonth = computed(() => {
  if (props.dates.in && props.dates.out) {
    return (
      props.dates.in.getFullYear() === props.dates.out.getFullYear() &&
      props.dates.in.getMonth() === props.dates.out.getMonth()
    );
  }
  return true;
});
const getCountDays = computed(() => {
  if (!props.dates?.out && !props.dates?.in) {
    return "";
  }
  const diffTime = Math.abs(props.dates.out - props.dates.in);
  return t("days", Math.ceil(diffTime / (1000 * 60 * 60 * 24)));
});
const dateForamted = computed(() => {
  const result = {
    in: "",
    out: "",
  };
  if (props.dates?.in) {
    result.in = isOneMonth.value
      ? String(props.dates.in.getDate())
      : props.dates.in.toLocaleString(locale, {
          day: "numeric",
          month: "short",
        });
    if (result.in.slice(-1) === ".") {
      result.in = result.in.slice(0, -1);
    }
    if (!isOneYear.value) {
      result.in += ` ${props.dates.in.toLocaleString(locale, {
        year: "numeric",
      })}`;
    }
  }
  if (props.dates?.out) {
    result.out = props.dates.out.toLocaleString(locale, {
      day: "numeric",
      month: "short",
    });
    if (result.out.slice(-1) === ".") {
      result.out = result.out.slice(0, -1);
    }
    if (!isOneYear.value) {
      result.out += ` ${props.dates.out.toLocaleString(locale, {
        year: "numeric",
      })}`;
    }
  }

  if (result.in && result.out) {
    return result.in + " - " + result.out + `, ${getCountDays.value}`;
  }
  return t("choiseDates");
});
const getGuestsText = computed(() => {
  let countGuests = guests.value?.adults || 1;
  countGuests += guests.value?.childrens?.length || 0;
  return t("guest", countGuests);
});
</script>

<style lang="scss" scoped>
.top {
  padding: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffffb2;
}
.date-guests {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 5px;
}
.date,
.guests {
  white-space: nowrap;
}
.active {
  color: #ffffff;
}

.v-enter-active,
.v-leave-active {
  transition: transform 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  transform: translateX(100%);
}
</style>
