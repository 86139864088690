import HTTP from "../http-common";

// Отказаться от платежа

export default (formData) =>
  HTTP.post("json/payments/cancelInvoiceForByInvoice", formData, {
    headers: {
      "api-version": "null",
    },
    cache: false,
  });
