import HTTP from "../http-common";

/**
 * Получение номеров телефона авторизованного пользователя
 *
 * @module getMyMobiles
 * @return {mobiles} Promise
 */
export default () =>
  HTTP.get("json/users/getMyMobiles", {
    // headers: {
      // "api-version": 1.8 // Если указать версию, то будет минимум данных
    // },
    cache: false,
  });
// import HTTP from "../http-common";

// /**
//  * Авторизация пользователя
//  *
//  * @module getMyMobiles
//  */
// export default payload => {
//   let formData = new FormData();
//   return HTTP.post("json/users/getMyMobiles", formData, {
//     headers: {
//       "api-version": String(null) // Если указать версию, то будет минимум данных
//     }
//   });
// };
