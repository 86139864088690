<template>
  <button class="location" :class="{ hotels: isHotels }">
    <span class="location__title">
      {{ getlocationTitle.beforeMatch }}<span>{{ getlocationTitle.match }}</span>{{ getlocationTitle.afterMatch }}
    </span>
  </button>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  variant: Object,
  locationFieldValue: String,
  isHotels: Boolean,
});

const getlocationTitle = computed(() => {
  const title =
    props.variant.title +
    (props.variant.title_ext && props.variant.title !== props.variant.title_ext
      ? `, ${props.variant.title_ext}`
      : "");

  const index = title.indexOf(props.locationFieldValue);
  if (index !== -1) {
    const beforeMatch = title.substring(0, index);
    const match = title.substr(index, props.locationFieldValue.length);
    const afterMatch = title.substring(index + props.locationFieldValue.length);
    return {
      beforeMatch,
      match,
      afterMatch,
    };
  }
  return {
    beforeMatch: "",
    match: title,
    afterMatch: "",
  };
});
</script>

<style lang="scss" scoped>
.location{
  width: 100%;
  padding: 11px 18px;
  display: flex;
  align-items: center;
  background: none;
  border: none;

  &::before{
    content: url("~/assets/img/search.svg");
    margin-right: 10px;
    transform: translateY(2px);
  }
  &.hotels::before{
    content: url("~/assets/img/mapmarker_sm.svg");
  }

  &__title{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #5A5D63;

    span{
      color: #1C1C1C;
    }
  }
}
</style>
