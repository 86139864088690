import getGuestStatus from "./getGuestStatus";
import getOwnerStatus from "./getOwnerStatus";
import gaCreateUser from "./gaCreateUser";
import gaClientID from "./gaClientID";
import ymClentId from "./ymClentId";
import gaGetClientId from "./gaGetClientId";

/**
 * Модуль содержит работу с данными пользователя на всём сайте
 *
 * Статус: авторизован или не авторизован
 * Тип пользователя: гость, арендодатель и тд.
 *
 * @module Global
 */
export default {
  getGuestStatus,
  getOwnerStatus,
  ymClentId,
  gaGetClientId,
  gaCreateUser,
  gaClientID,
};
