import HTTP from "../http-common";
import searchObjectsMapping from "@/utils/searchObjectsMapping.js";
import { checkRelevanceMetrikaFlags } from "@/composables/ymab";
/**
 * Получение списка объектов
 *
 * @module searchObjects
 * @return {object} Promise
 */
function api(params) {
  let request = { ...params };
  if (typeof params?.occupied === "string") {
    let date = params.occupied.split(";");
    let [dateBegin, dateEnd] = date;
    request.dateBegin = dateBegin;
    request.dateEnd = dateEnd;
    delete request.occupied;
  }
  return HTTP.get("/json/search/searchObjectsByLocation", {
    headers: {
      "api-version": "2.0",
    },
    params: request,
    cache: false,
  });
}

export default async (params) => {
  return api(await checkRelevanceMetrikaFlags(params)).then((res) => {
    const newResponse = JSON.parse(JSON.stringify(res));

    const data = newResponse.data.data;

    if (typeof data === "object") {
      const newData = {
        total_count: data.totalCount || 0,
        objects: searchObjectsMapping(data.objects),
      };

      newResponse.data.data = newData;
    }

    return newResponse;
  });
};
