import HTTP from "../http-common";

/**
 * Данный метод должен подготовить данные для ПС, 
 * чтобы заморозить (захолдировать) средства пользователя на банковской карте, 
 * для внесения предоплаты по бронированию.
 *
 * @module createPaymentHold
 *
 * @return {object} Promise
 *
 */
export default (orderId) => {
  let objectData = new FormData();
  objectData.append("orderId", orderId || "");
  return HTTP.post("json/payments/createPaymentHold", objectData, {
    headers: {
      "api-version": String(null),
    },
    cache: false,
  });
};
