
// маппинг для методов searchObject и searchObjectsByLocation версии 2.0
export default function (objects) {
  if (!Array.isArray(objects)) {
    return [];
  }

  return objects.map((elem)=>{
    let res = {
      cashback: elem.cashback || null,
      id: elem?.id || null,
      address:
        elem?.location?.address?.title && elem?.location?.address?.city?.title
          ? `${elem?.location?.address?.city?.title}, ${elem?.location?.address?.title}`
          : "",
      area: elem?.properties?.area || 0,
      bedrooms: elem?.properties?.bedrooms || 0,
      booking_now: elem?.properties?.bookingNow || false,
      count_beds: elem?.properties?.beds || 0,
      count_reviews: elem?.rating?.count || 0,
      favorite: false, // отсутсвует в этом api, потом добавляем из userData
      guests: elem?.properties?.maxGuests || 0,
      is_recommended: elem?.properties?.isRecommended || false,
      lat: elem?.location?.lat || null,
      lng: elem?.location?.lng || null,
      name: elem?.title || "",
      occupied: "unbusy", // ???? взять с getPricesAndAvailabilities
      rating: elem?.rating?.value || 0,
      title: elem?.type || "",
    };
    res.hot_sale = {
      price_per_night: elem?.hotSale?.pricePerNight || 0,
      sale: elem?.hotSale?.sale || 0,
      hot_time: elem?.hotSale?.hotTime ?? null,
    };

    // нужены для совместимости со старым api
    res.conveniences = {};
    res.external_reviews = [
      { rating: 0, count: 0 },
      { rating: 0, count: 0 },
    ];

    if (elem?.hotel?.id) {
      res.hotel = {
        cert_date: elem.hotel.certDate || null,
        cert_num: elem.hotel.certNum || null,
        id: elem.hotel.id,
        stars: elem.hotel.stars || null,
        title: elem.hotel.title || "",
        type: elem.hotel.type || "",
      };
    }

    res.location = {};
    if (elem?.location?.address?.city?.id) {
      res.location.city = {
        id: elem.location.address.city.id,
        title: elem.location.address.city.title || "",
      };
    }
    if (elem?.location?.address?.place?.id) {
      res.location.place = {
        id: elem.location.address.place.id,
        title: elem.location.address.place.title || "",
      };
    }

    if (elem?.location?.relations?.metro) {
      res.location.metro = {
        distance: elem.location.relations.metro?.distance || 0,
        line_color: elem.location.relations.metro?.lineColor || "#ADACAC",
        title: elem.location.relations.metro?.title,
        walk_time: elem.location.relations.metro?.walkTime || 0,
        walk_time_format: "", // ????
      };
    }
    if (elem?.location?.relations?.sea) {
      res.location.sea = {
        distance: elem.location.relations.sea?.distance || null,
        name: elem.location.relations.sea?.name || "",
        title: elem.location.relations.sea?.title || "",
      };
    }
    if (elem?.location?.relations?.ropeway) {
      res.location.ropeway = {
        distance: elem.location.relations.ropeway?.distance || null,
        title: elem.location.relations.ropeway?.title || "",
      };
    }

    // отсутствует в новом api
    if (elem?.hotel?.meal) {
      res.meal = elem.hotel.meal;
    } else {
      res.meal = elem.meal;
    }

    if (elem?.media?.images?.length) {
      res.media = elem.media.images;
    } else {
      res.media = [];
    }

    res.owner = {
      id: null, // ???
      permissions: [],
    };
    if (elem.isSuperhost) {
      res.owner.permissions.push("showSuperhostLabelAbility");
    }

    res.popularity = {
      name: elem?.popularity?.name || "",
      value: elem?.popularity?.value || 0,
    };

    res.price = {
      base: elem?.prices?.base?.value || null,
      full: elem?.prices?.full?.value || elem?.prices?.base?.value || null,
      per_night:
        elem?.prices?.perDay?.value || elem?.prices?.base?.value || null,
      without_discounts:
        elem?.prices?.fullBase?.value || elem?.prices?.base?.value || null,
    };

    return res;
  });
}
