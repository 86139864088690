// Задаем приватные свойства
/**
 * храним временую зону клиента
 * @type { String }
 */
let _timeZone = null;
/**
 * значение временой зоны по умолчанию
 * @type { String }
 */
const defaultTimeZone = '+03:00';

/**
 * Один день в миллисекундах
 * @type { Number }
 */
const oneDayInMilisec = 86400000;

// публичные функции

/**
 * Получаем временую зону
 * @return { String } возвращяет временую зону
 */
 const getTimeZone = () => {
  return _timeZone;
}
/**
 * возвращает дату
 * @param { Date | String | Number } payload 
 * @return { Date } возвращает дату
 */
const getDate = (payload) => _convertToDate(payload);

/**
 * Возвращает час
 * @param { Date | String | Number }
 * @return { Number } возвращяет час даты
 */
const getHours = (payload) => _convertToDate(payload).getHours();

/**
 * форматирует дату в формат YYYY-MM-DD
 * @param { Date | String | Number } payload любой формат даты
 * @return { String }
 */
const formatDate = (payload) => {
  const date = _convertToDate(payload);
  const month = (date.getMonth() + 1).toString().padStart(2, 0);
  const day = date.getDate().toString().padStart(2, 0);
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

/**
 * Форматирует дату исходя из локализации
 * @param { Date | String | Number } date дата
 * @param { String } location локализация
 * @return { String }
 */
const formatLocationDate = (date, location = 'ru', config = {
  year: "numeric",
  month: "long",
  day: "numeric",
}) => new Intl.DateTimeFormat(location, config).format(_convertToDate(date)).slice(0, -3);

/**
 * Увеличивает год на указаное количество с форматирование
 * @param { Date } date дата 
 * @param { Number } increase на сколько увеличить год
 * @return { String }
 */
const increaseYearFormat = (date, increase = 1) => formatDate(increaseYear(date, increase));

/**
 * преобразует дату в формат YYYY-MM-DDTHH:mm:ss+timeZone
 * @param { Date | String | Number } payload дата в любом формате
 * @return YYYY-MM-DDTHH:mm:ss+timeZone
 */
const convertISOString = (payload, isTimeZoneDefault = false) => {
  const date = _convertToDate(payload);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, 0);
  const day = date.getDate().toString().padStart(2, 0);
  const hour = date.getHours().toString().padStart(2, 0);
  const minute = date.getMinutes().toString().padStart(2, 0);
  const second = date.getSeconds().toString().padStart(2, 0);
  return `${year}-${month}-${day}T${hour}:${minute}:${second}${isTimeZoneDefault ? defaultTimeZone : getTimeZone()}`;
}

/**
 * Уменьшает год на указаное количество
 * @param { Date | String | Number } payload дата
 * @param { Number } increase на сколько увеличить год
 * @return { Date }
 */
 const decreaseYear = (payload, decrease = 1) => {
  const date = new Date(_convertToDate(payload));
  date.setFullYear(date.getFullYear() - decrease);
  return date;
}

/**
 * Увеличивает год на указаное количество
 * @param { Date | String | Number } payload дата
 * @param { Number } increase на сколько увеличить год
 * @return { Date } возвращяет дату
 */
 const increaseYear = (payload, increase = 1) => {
  const date = new Date(_convertToDate(payload));
  date.setFullYear(date.getFullYear() + increase);
  return date;
}

/**
 * Смещает дату назад на количество месяцев
 * @param { Date | String | Number } payload дата в любом формате
 * @param { Number } decrease количество уменьшения месяцев
 * @return { Date } возвращяет дату
 */
 const decreaseMonth = (payload, decrease = 1) => {
  const date = new Date(_convertToDate(payload));
  date.setMonth(date.getMonth() - decrease);
  return date;
}

/**
 * Смещает дату вперед на количество месяцев
 * @param { Date | String | Number } payload дата в любом формате
 * @param { Number } increase количество увеличения месяцев
 * @return { Date } возвращяет дату
 */
const increaseMonth = (payload, increase = 1) => {
  const date = new Date(_convertToDate(payload));
  date.setMonth(date.getMonth() + increase);
  return date;
}

/**
 * Смещает дату назад на количество дней
 * @param { Date | String | Number } payload дата в любом формате
 * @param { Number } decrease количество уменьшения дней
 * @return { Date } возвращяет дату
 */
 const decreaseDay = (payload, decrease = 1) => {
  const date = new Date(_convertToDate(payload));
  date.setDate(date.getDate() - decrease);
  return date;
}

/**
 * Смещает дату вперед на количество дней
 * @param { Date | String | Number } payload - дата в любом формате
 * @param { Number } increase - количество увеличения дней
 * @return { Date }
 */
const increaseDay = (payload, increase = 1) => {
  const date = new Date(_convertToDate(payload));
  date.setDate(date.getDate() + increase);
  return date;
}
/**
 * возвращаем метку времени
 * @param { Date | String | Number } payload дата в любом формате
 * @return { Number } возвращяет метку времени
 */
const getTime = (payload) => {
  return _convertToDate(payload).getTime();
}
/**
 * возвращает метку времени без времени
 * @param { Number | String } year год 
 * @param { Number | String } month месяц
 * @param { Number | String } day день
 * @return { Number } возвращает метку времени
 */
const getTimestamp = (year, month, day) => {
  return new Date(year, month, day).getTime();
}
/**
 * возвращает метку времени без времени
 * @param { Date | String | Number } payload дата в любом формате
 * @return { Number } возвращает метку времени
 */
const getTimeDate = (payload) => {
  const date = _convertToDate(payload);
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  ).getTime();
}


const weekDayAdapter = (weekDay) => {
  return {
    'sunday': 0,
    'monday': 1,
    'tuesday': 2,
    'wednesday': 3,
    'thrursday': 4,
    'friday': 5,
    'saturday': 6,
  }[weekDay.toLowerCase()]
}

// Приватные функции

/**
 * Инициализируем временую зону
 */
 const _initTimeZone = () => {
  let hoursOffset = (new Date().getTimezoneOffset() / 60) | 0;
  const minutesOffset = Math.abs(new Date().getTimezoneOffset() % 60)
    .toString()
    .padStart(2, "0");
  const signOffset = Math.sign(hoursOffset) < 0 ? "+" : "-";
  hoursOffset = Math.abs(hoursOffset).toString().padStart(2, "0");
  _timeZone = `${signOffset}${hoursOffset}:${minutesOffset}`;
}

/**
 * 
 * @param { Date, String, Number } date - дата в любом формате
 * @return { Date } - возвращает дату
 */
const _convertToDate = (date) => {
  return date instanceof Date
    ? date
    : typeof date === 'string'
      ? new Date(date.toString().replace('+03:00', ''))
      : new Date(date);
}

// Инициализации
_initTimeZone();

export {
  oneDayInMilisec,
  getDate,
  getHours,
  formatDate,
  increaseYearFormat,
  convertISOString,
  decreaseYear,
  increaseYear,
  decreaseMonth,
  increaseMonth,
  decreaseDay,
  increaseDay,
  getTime,
  getTimestamp,
  getTimeDate,
  formatLocationDate,
  weekDayAdapter,
}

export default {
  oneDayInMilisec,
  getDate,
  getHours,
  formatDate,
  increaseYearFormat,
  convertISOString,
  decreaseYear,
  increaseYear,
  decreaseMonth,
  increaseMonth,
  decreaseDay,
  increaseDay,
  getTime,
  getTimestamp,
  getTimeDate,
  formatLocationDate,
  weekDayAdapter,
}
