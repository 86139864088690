import { set } from "vue-gtag";
import _ from "lodash";


export default _.debounce(function () {
  // TODO: проверить доступность clientID
  if (window?.gaGlobal?.vid){
    set("dimension16", window.gaGlobal.vid);
  } else {
    return false
  }
}, 1000);
