<i18n>
{
  "ru": {
    "monthNames": {
      "0": "Январь",
      "1": "Февраль",
      "2": "Март",
      "3": "Апрель",
      "4": "Май",
      "5": "Июнь",
      "6": "Июль",
      "7": "Август",
      "8": "Сентябрь",
      "9": "Октябрь",
      "10": "Ноябрь",
      "11": "Декабрь",
    },
    "daysWeek": {
      "1": "пн",
      "2": "вт",
      "3": "ср",
      "4": "чт",
      "5": "пт",
      "6": "сб",
      "7": "вс"
    }
  }
}
</i18n>

<template>
  <div class="month">
    <table>
      <caption class="semibold-p5">
        {{
          getMonthName
        }}
      </caption>
      <thead>
        <tr>
          <th v-for="day in 7" :key="day">{{ t(`daysWeek.${day}`) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(week, index) in cells" :key="index">
          <td v-for="(day, tdIndex) in week" :key="tdIndex">
            <Day
              v-if="day"
              class="month__day"
              :day="day"
              :count="countDays"
              @click="emits('choiceDate', $event)"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import _ from "lodash";

import Day from "./Day.vue";

const { t } = useI18n();

const props = defineProps({
  date: {
    type: Object,
    default: new Date(),
  },
  firstCalendarDay: Number,
  lastCalendartDay: Number,
  currentDate: Number,
  dates: {
    type: Object,
    default: function () {
      return {
        in: null,
        out: null,
      };
    },
  },
});

const emits = defineEmits(["choiceDate"]);

const getMonthName = computed(() => t("monthNames." + props.date.getMonth()));

const lastDayInMonth = computed(
  () => new Date(props.date.getFullYear(), props.date.getMonth() + 1, 0)
);
const cells = computed(() => {
  const weekDay = props.date.getDay() === 0 ? 7 : props.date.getDay();
  const lastDay = lastDayInMonth.value.getDate();

  const calendarDates = _.range(0, lastDay).map((day) => {
    const result = {
      value: props.date.getTime() + 86400000 * day,
      currentDay: props.currentDate === props.date.getTime() + 86400000 * day,
      dayNumber: day + 1,
    };

    if (props.firstCalendarDay || props.lastCalendartDay) {
      result.disabled =
        result.value < props.firstCalendarDay ||
        result.value > props.lastCalendartDay;
    }

    if (props.dates.in || props.dates.out) {
      if (props.dates.in && props.dates.in.getTime() === result.value) {
        result.active = "first";
      } else if (
        props.dates.out &&
        props.dates.out.getTime() === result.value
      ) {
        result.active = "last";
      }
    }

    if (
      props.dates.in &&
      props.dates.out &&
      props.dates.in.getTime() <= result.value &&
      props.dates.out.getTime() >= result.value
    ) {
      result.betweenDates = true;
    }

    return result;
  });

  const emptyCells = _.fill(Array(weekDay - 1), null);
  const cellsCountToLastDay = [...emptyCells, ...calendarDates];

  return _.chunk(cellsCountToLastDay, 7);
});

const countDays = computed(() => {
  if (props.dates.in && props.dates.out) {
    return Math.floor(props.dates.out - props.dates.in) / 86400000;
  }
  return 0;
});
</script>

<style lang="scss" scoped>
.month {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  font-weight: 500;
  color: #252525;

  caption {
    caption-side: top;
    color: #1c1c1c;
    padding: 0 0 10px;
    text-align: left;
  }

  table {
    width: 100%;
  }
  th,
  td {
    padding: 0;
    text-align: center;
    border: none;
  }
  th {
    padding-bottom: 13px;
  }
  tr:not(:last-child) > td {
    padding-bottom: 9px;
  }

  .td:first-child &__day {
    padding-right: 2px;
  }
  .td:last-child &__day {
    padding-left: 2px;
  }
}
</style>
