// /orders/getOrderDraft?draftCode=EK8RY5kwV&token=mJVruGMgV39AkYqSfFML8Q178283272
import HTTP from "../http-common";
import { formatWithTime } from "@/utils/format";

/**
 * Получение данных для черновика и генерация ссылки в ответе
 *
 * @module getOrderDraft
 * @param {object} payload Данные для черновика
 * @param {string} payload.draftCode Сгенерированный хэш, генерируется в ответе из этого же метода
 * @return {object} Promise
 */

export default (payload) => {
  let objectData = new FormData();
  objectData.append("draftCode", payload.draftCode || "");

  return HTTP.post("json/orders/getOrderDraft", objectData, {
    headers: {
      // "api-version":  // Если указать версию, то будет минимум данных
    },
  });
};
