

<template>
  <button class="ui-button" :class="theme" :disabled="isDisabled">
    <div class="loader-wrapper" v-if="loading">
      <div class="loader"></div>
    </div>
    <div :class="{'ui-button--hide': loading}">
      <slot></slot>
    </div>
  </button>
</template>

<script>
export default {
  name: "UIButton",
  data() {
    return {
      buttonThemeList: ["default", "red", "dark", "pale-blue", "light"],
    };
  },
  props: {
    buttonTheme: String,
    loading: {
      type: Boolean,
      default: false
    },
    disabled: Boolean
  },
  computed: {
    theme() {
      if (this.buttonThemeList.includes(this.buttonTheme)) {
        return this.buttonTheme;
      }
      return "default";
    },
    isDisabled(){
      return this.disabled || this.loading
    }
  },
};
</script>

<style lang="scss" scoped>
.ui-button {
  position: relative;
  padding: 14px 24px;
  width: 100%;
  height: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 14px;
  border: none;
  outline: none;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #1c1c1c;

  transition: all 0.2s;

  &--hide{
    opacity: 0;
  }
}

.default {
  background: #ffffff;
  border: 1px solid #e7e9f3;

  &:hover {
    border: 1px solid #c0c3d3;
  }
  &:active {
    background: #f8faff;
    border: 1px solid #c0c3d3;
  }
  &:disabled {
    border: 1px solid #e7e9f3;
    color: rgba(28, 28, 28, 0.2);
  }
}

.red {
  background: #f51449;
  color: #ffffff;

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)),
      #f51449;
  }
  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
      #f51449;
  }
  &:disabled {
    background: rgba(245, 20, 73, 0.2);
  }
}

.dark {
  background: #1c1c1c;
  color: #ffffff;

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      #1c1c1c;
  }
  &:active {
    background: #000000;
  }
  &:disabled {
    background: rgba(28, 28, 28, 0.2);
  }
}

.pale-blue {
  background: #f1f3fb;

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),
      #f4f5f9;
  }
  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)),
      #f4f5f9;
  }
  &:disabled {
    background: #f1f3fb;
    color: rgba(28, 28, 28, 0.2);
  }
}
.mobile .pale-blue{
  &:hover {
    background: #f1f3fb;
  }
  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)),
      #f4f5f9;
  }
  &:disabled {
    background: #f1f3fb;
    color: rgba(28, 28, 28, 0.2);
  }
}

.light {
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.1),
    0px 6px 10px rgba(20, 45, 136, 0.02);

  &:hover {
    box-shadow: 0px 3px 5px rgba(20, 45, 136, 0.1),
      0px 6px 10px rgba(20, 45, 136, 0.02);
  }
  &:active {
    box-shadow: 0px 1px 3px rgba(20, 45, 136, 0.1),
      0px 6px 9px rgba(20, 45, 136, 0.02);
  }
  &:disabled {
    box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.1),
      0px 6px 10px rgba(20, 45, 136, 0.02);
    color: rgba(28, 28, 28, 0.2);
  }
}


.loader-wrapper{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  height: 25%;
  aspect-ratio: 5;
  --_g: no-repeat radial-gradient(circle closest-side,#ffffff 90%,rgba(255, 255, 255, 0));
  background:
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 100%;
  animation: l7 1s infinite linear;
}
.default .loader, .pale-blue .loader, .light .loader{
  --_g: no-repeat radial-gradient(circle closest-side,#1C1C1C 90%,#1C1C1C00);
}
@keyframes l7 {
  33%{background-size:calc(100%/3) 0%  ,calc(100%/3) 100%,calc(100%/3) 100%}
  50%{background-size:calc(100%/3) 100%,calc(100%/3) 0%  ,calc(100%/3) 100%}
  66%{background-size:calc(100%/3) 100%,calc(100%/3) 100%,calc(100%/3) 0%  }
}
</style>
