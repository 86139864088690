export default () => {
  let newHost = "";
  if (process.server) {
    const { host } = window.location;
    newHost = host;
  } else {
    newHost = process.env.VUE_APP_API_ADDRESS.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/)[1];
  }
  
  newHost = newHost.split(".");
  if (newHost.length >= 3) {
    newHost = newHost.slice(1);
  }

  newHost = newHost.join(".");
  let domain = `.${newHost}`;

  if (newHost.indexOf("localhost") >= 0) {
    newHost = "localhost";
    domain = "localhost";
  }

  return {
    domain: newHost,
    domainDot: domain,
  };
};
