import { useGtm } from "@gtm-support/vue-gtm";

export default function (page, products) {
  const gtmData = {
    event: "partner_pageview",
    eventCategory: "page",
    eventAction: "newpage",
    eventLabel: "open",
  };
  if (page) {
    gtmData.page = page;
  }
  if (products) {
    gtmData.products = products;
  }
  useGtm().trackEvent(gtmData);
}
