/**
 *
 * Отправка данных в аналитику, при докруткt скролла до блока с отзывами
 *
 * @function
 *
 * @param {string} device  - 'desktop' или 'mobile' - тип устройства
 * @param {number} id - id объекта
 *
 */
export default function (device, id) {
  if (!window.ga || !id) return false;
  // if (device === "desktop") {
  //   window.ga("send", "event", "object_sa", "load", "reviews", id);
  // } else if (device === "mobile") {
  //   window.ga("send", "event", "object_sa_m", "load", "reviews", id);
  // }
}