import HTTP from "../http-common";

/**
 * Получение данных об объекте
 *
 * @module getAvgReviewsRatings
 *
 * @param {number} objectIds - Массив id-шников объектов
 *
 * @return {object} Promise
 *
 */
export default (objectIds) => {
  
  let formData = new FormData();
  objectIds.map(item => {
    formData.append("objectIds[]", item);
  })

  return HTTP.post(
    "/json/messages/getAvgReviewsRatings", 
    formData, 
    {
      headers: {
        "api-version": "1.10"
      },
      cache: false,
    }
  );
}