import HTTP, { TOKEN } from "../http-common";

/**
 * Получение j, j,]trnt
 *
 * @module getPricesAndAvailabilities
 * @param {object} payload Передаваемый объект
 * @param {string} payload.guests Сколько гостей
 * @param {string} payload.time_begin Время заезда
 * @param {string} payload.time_end Время отъезда
 * @param {string} payload.date_begin Дата заезда в формате Y-m-d hh:mm:ss
 * @param {string} payload.date_end Дата отъезда в формате Y-m-d hh:mm:ss
 * @param {array} payload.objects id объекта
 * @param {object} payload.max_rooms количество комнат
 * @return {object} Promise
 */
export default (payload) => {
    let objectData = new FormData();
    objectData.append("guests", payload.guests || "");
    if (typeof payload.time_begin === "number") {
        objectData.append("time_begin", payload.time_begin);
    }
    if (typeof payload.time_end === "number") {
        objectData.append("time_end", payload.time_end);
    }
    objectData.append("date_begin", payload.date_begin || "");
    objectData.append("date_end", payload.date_end || "");
    if (payload.no_time) {
        objectData.append("no_time", payload.no_time || "");
    }
    if (payload.rooms_cnt) {
        //objectData.append("rooms_cnt", payload.rooms_cnt);
    }
    if (typeof payload.objects === "number") {
        objectData.append("objects[]", payload.objects);
    } else {
        for (let i = 0; i < payload.objects.length; i++) {
            const element = payload.objects[i];
            if(payload.max_rooms) {
                const room = payload.max_rooms[element] || 1;
                //objectData.append(`rooms_cnt[${element}]`, room);
            }
            objectData.append("objects[]", element);
        }
    }
    objectData.append("currency_id", payload.currency_id || "");

    return HTTP.post("json/objects/getPricesAndAvailabilitiesBronevik", objectData, {
        adapter: null,
        cancelToken: payload?.cancelToken?.token || null,
        headers: {
            "api-version": "null", // Если указать версию, то будет минимум данных
            token: TOKEN,
        },
        cache: false,
    });
};
