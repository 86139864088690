<template>
  <ErrorDesktop v-if="viewPort === 'desktop'" />
  <ErrorMobile v-else />
</template>

<script setup>
import ErrorDesktop from "./components/desktop/pages/404.vue";
import ErrorMobile from "./components/mobile/pages/404.vue";

import { useStore } from "vuex";
import useMeta404 from '@/composables/meta/404';

const store = useStore();

useMeta404();

const viewPort = computed(() => store.state.user.viewPort);

</script>