export default (cb) => {
  // TODO: NUXT посмотреть варианты проверять webp в ssr
  if (typeof document === 'undefined') {
    cb(false);
    return;
  }

  var img = new Image();
  img.onload = function () {
    cb(img.width > 0 && img.height > 0);
  };
  img.onerror = function () {
    cb(false);
  };
  img.src =
    "data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA";
  };
  