import HTTP from "../http-common";

/**
 * Получение реквизитов пользователя на странице черновика(Booking)
 *
 * @module getUserRequisites
 * @return {requisites} Promise
 */
export default (orderCode) =>
  HTTP.get("json/users/get_user_requisites", {
    params: { orderCode },
    // headers: {
      // "api-version": 1.8 // Если указать версию, то будет минимум данных
    // },
    cache: false,
  });
