import _ from "lodash";
import { event as sendEventGtag } from "@/composables/gtag/index.js";
/**
 *
 * Обработка изменений в форме бронирования
 *
 * @function
 *
 * @param {string} city - 'Москва' - город объекта
 *
 */
export default _.debounce(function (city) {
  try {
    sendEventGtag("booking", {
      step1: city,
    })

  } catch (error) {
    console.error(error);
  }
}, 300);
