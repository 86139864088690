import HTTP from "../http-common";

/**
 * Получение j, j,]trnt
 *
 * @module getOtherReviews
 * @return {object} Promise
 */
export default (id, page) =>
  HTTP.get(`json/reviews/get/${id}?page=${page ? page : null}`, {
    // headers: {
      // "api-version": 1.8 // Если указать версию, то будет минимум данных
    // },
    cache: false,
  });
