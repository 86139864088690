import HTTP from "../http-common";

/**
 * Получить черновик отзыва на объект
 * @module getDraftReviewByOrderId
 * @param {Number} $orderId - ID заявки
 * @return {Array}
 */
export default (orderId) => {
  let formData = new FormData();
  formData.append("orderId", orderId || "");

  return HTTP.post("json/messages/getDraftReviewByOrderId", formData, {
    headers: {
      "api-version": String(null),
    },
    cache: false,
  });
};
