import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_client_Eorgdr12jA from "/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_1_setup_client_4wPGmaAPLg from "/app/plugins/01.1.setup.client.js";
import _01_i18n_tuYmYnlJNX from "/app/plugins/01.i18n.js";
import _01_legacyMain_9UeMuAKmGr from "/app/plugins/01.legacyMain.js";
import _01_node_client_tlCw2ycr25 from "/app/plugins/01.node.client.js";
import _02_store_jPcK0KR4kw from "/app/plugins/02.store.js";
import _03_cookies_48eNFzz1A5 from "/app/plugins/03.cookies.js";
import _04_gtag_ceOZvuCHjH from "/app/plugins/04.gtag.js";
import _05_gtm_ulCztwz0j0 from "/app/plugins/05.gtm.js";
import _06_panZoom_fpRN9jcaAG from "/app/plugins/06.panZoom.js";
import _07_clickOutside_NyQTLVTUCF from "/app/plugins/07.clickOutside.js";
import _08_vueSafeTeleport_2FKG7qPhVG from "/app/plugins/08.vueSafeTeleport.js";
import _09_customDirectives_etzdYXTD7h from "/app/plugins/09.customDirectives.js";
import _10_viewPort_client_MKiaN0M4x0 from "/app/plugins/10.viewPort.client.js";
import toaster_GqoxFfmFJW from "/app/plugins/toaster.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_client_Eorgdr12jA,
  chunk_reload_client_UciE0i6zes,
  _01_1_setup_client_4wPGmaAPLg,
  _01_i18n_tuYmYnlJNX,
  _01_legacyMain_9UeMuAKmGr,
  _01_node_client_tlCw2ycr25,
  _02_store_jPcK0KR4kw,
  _03_cookies_48eNFzz1A5,
  _04_gtag_ceOZvuCHjH,
  _05_gtm_ulCztwz0j0,
  _06_panZoom_fpRN9jcaAG,
  _07_clickOutside_NyQTLVTUCF,
  _08_vueSafeTeleport_2FKG7qPhVG,
  _09_customDirectives_etzdYXTD7h,
  _10_viewPort_client_MKiaN0M4x0,
  toaster_GqoxFfmFJW
]