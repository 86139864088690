import { defineRule, configure } from "vee-validate";
import {
  required,
  alpha,
  numeric,
  min_value,
  max,
  email,
} from "@vee-validate/rules";

export default (translation) => {
  configure({
    generateMessage: (context) => {
      const values = {
        field: context.rule.name,
        ...context.rule.params,
      };
      return translation(`validation.${values.field}`, values);
    },
  });
  
  // Install required rule, message getting from locales/ru.json .
  defineRule("required", required);
  
  // Install numeric rule, message getting from locales/ru.json .
  defineRule("alpha", alpha);
  defineRule("max", max);
  defineRule("email", email);
  defineRule("numeric", numeric);
  defineRule("min_value", min_value);
  defineRule("min_phone", (value) => {
    return value ? value.replace(/\s/g, "").replace("+", "").length > 6 : false;
  });
  defineRule("settlementAccount", (value) => {
    return value ? value.length === 20 : false;
  });
  defineRule("phone", (v) => {
    const number = v ? String(v).match(/\d/g) : null;
    if (
      (number && number.length >= 11) ||
      (number[0] === "4" && number[1] === "7" && number.length === 10)
    ) {
      return true;
    }
    return false;
  });
}
