import HTTP from "../http-common";

export default (params, headers = {}) => {
  return HTTP.get("json/objects/getMedia", {
    params,
    headers,
    cache: false,
  });
}

