import createPaymentHold from "./createPaymentHold";
import cancelInvoiceForByInvoice from "./cancelInvoiceForByInvoice";
import testPayedBonus from "./testPayedBonus";
import createpayment from "./createpayment";
import checkInvoiceforByInvoice from "./checkInvoiceforByInvoice";
/**
 * @module Payments
 */
export default {
  createPaymentHold,
  cancelInvoiceForByInvoice,
  testPayedBonus,
  createpayment,
  checkInvoiceforByInvoice,
};
