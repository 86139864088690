/**
 *
 * Отправка данных в Яндекс метрику при клике на кнопку Оплатить
 *
 * @function
 *
 * Все параметры по default
 *
 */
export default function () {
  if (!window.ym) return false;
  return window.ym(8562148, "reachGoal", "bookingstep3com");
}
