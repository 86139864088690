import HTTP from "../http-common";

/**
 * Отправка отзыва о локации
 *
 * @module addLocationReview
 * 
 * @return {object} Promise
 *
 */
 export default (formData) => {
  return HTTP.post("json/messages/addLocationReview ", formData, {
    headers: {
      "api-version": String(null),
    },
    cache: false,
  });
};