<template>
  <div class="calendar">
    <Month
      class="calendar__month"
      v-for="(month, index) in months"
      :key="index"
      :date="month"
      :firstCalendarDay="currentDateMS"
      :lastCalendartDay="lastDate"
      :currentDate="currentDateMS"
      :dates="dates"
      @choiceDate="choiceDate"
    />
  </div>
</template>


<script setup>
import { computed, onMounted } from 'vue';
import _ from "lodash";

import Month from "./Month.vue"


const props = defineProps({
  dates: {
    type: Object,
    default: function () {
      return {
        in: null,
        out: null,
      };
    },
  },
  activeField: String,
})

const emits = defineEmits(["update:dates", "setLastDateElement"])

//создает месяца
const currentDate = new Date();
const currentDateMS = currentDate.setHours(0, 0, 0, 0);
const currentYear = currentDate.getFullYear();
const lastDate = new Date(
  currentYear + 1,
  currentDate.getMonth(),
  currentDate.getDate()
).setHours(0, 0, 0, 0);
const months = computed(() => {
  return _.range(0, 13).map(
    (index) => new Date(currentYear, currentDate.getMonth() + index)
  );
})

function choiceDate({ date, event }) {
  if (props.activeField === "out") {
    emits("setLastDateElement", event.target)
    emits("update:dates", {
      in: props.dates.in,
      out: new Date(date)
    })
  } else {
    emits("update:dates", {
      in: new Date(date),
      out: props.dates.out
    })
  }
}

onMounted(() => {
  setTimeout(() =>{
    const lastActiveDay = document.querySelector(".day-wrapper--active-last")
    if (lastActiveDay) {
      lastActiveDay.scrollIntoView({ block: "center", behavior: "smooth" })
    }
  }, 500)
})
</script>

<style lang="scss" scoped>
.calendar{
  &__month{
    padding: 0px 16px;
  }

  &__month + &__month{
    padding-top: 20px;
  }
}
</style>>