import getAvgReviewRatingForObject from "./getAvgReviewRatingForObject";
import updateObjectReviewDraft from "./updateObjectReviewDraft";
import loadReviewForm from "./loadReviewForm";
import getHotelRating from "./getHotelRating";
import getAvgReviewsRatings from "./getAvgReviewsRatings";
import getDraftReviewByOrderId from "./getDraftReviewByOrderId";
import addObjectReviewDraft from "./addObjectReviewDraft";
import addLocationReview from "./addLocationReview";
import addObjectReviewResponse from "./addObjectReviewResponse";

/**
 * Работа с локациями
 *
 * @module Locations
 */
export default {
  getAvgReviewRatingForObject,
  updateObjectReviewDraft,
  loadReviewForm,
  getHotelRating,
  getAvgReviewsRatings,
  getDraftReviewByOrderId,
  addObjectReviewDraft,
  addLocationReview,
  addObjectReviewResponse,
};
