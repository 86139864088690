<i18n lang="json">
{
	"ru": {
		"mo": "пн",
    "tu": "вт",
    "we": "ср",
    "th": "чт",
    "fr": "пт",
    "sa": "сб",
    "su": "вс"
	},
  "en": {
		"mo": "mo",
    "tu": "tu",
    "we": "we",
    "th": "th",
    "fr": "fr",
    "sa": "sa",
    "su": "su"
	}
}
</i18n>

<template>
  <div
    class="sc-datepickerext-month"
    :class="{ privet: view }"
    data-cy="sc-datepickerext-month"
    :data-cy-date="dateMonth.toLocaleDateString()"
  >
    <div
      class="sc-datepickerext-month-title tmp-font--medium_bold"
      :class="{ horizontal: orientation == 'horizontal' }"
      v-once
    >
      {{ dateMonth.toLocaleString($i18n.locale, { month: "long" }) }}
      {{ dateMonth.getFullYear() }}
    </div>

    <div
      class="sc-datepickerext-days_week"
      v-once
      v-if="orientation == 'horizontal'"
    >
      <div class="days_week-text">
        <span
          v-for="(day, i) in $parent.daysWeek"
          :key="day"
          class="days_week-item tmp-font--small"
          >{{ t(day) }}</span
        >
      </div>
    </div>

    <table cellspacing="0" cellpadding="0">
      <tr v-for="(weeks, index) in days" :key="index">
        <!-- <template v-if="view"> -->
        <!-- v-if="date" -->
        <template v-for="(date, i) in weeks" :key="date">
          <Day
            v-if="date"
            :index="i + 1"
            :date="date"
            :date-month="dateMonth"
            :start-disable="startDisable"
            :end-disable="endDisable"
            :cntMin="cntMin"
            :employment="fillEmployment[date]"
            :class="[
              fillEmployment[date] &&
                `sc-datepickerext-day-employment__${fillEmployment[date]}`,
            ]"
          />
          <td v-else />
        </template>
        <!-- <td v-else></td> -->
        <!-- </template>
        <template v-else v-once>
          <DaySimple
            v-for="(date, i) in weeks"
            :key="date"
            :date="date"
          />
        </template> -->
      </tr>
    </table>

    <!-- <div class="sc-datepickerext-simple" v-else></div> -->
  </div>
</template>

<script>
import Day from "./Day.vue";
// import DaySimple from "./DaySimple.vue";
import flatten from "lodash/flatten";
import { useI18n } from 'vue-i18n';

export default {
  name: "Month",
  components: {
    Day,
    // DaySimple,
  },
  props: {
    dateMonth: {
      type: Date,
      required: true,
    },
    startDisable: {
      type: Date,
      default: () => new Date(),
    },
    endDisable: {
      type: Date,
    },
    employment: {
      type: Array,
      default: () => [],
    },
    cntMin: {
      type: Number,
      default: null,
    },
    days: {
      type: Array,
      default: () => [],
    },
    orientation: {
      default: "horizontal",
      type: String,
      validator(value) {
        return ["vertical", "horizontal"].includes(value);
      },
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      view: true,
    };
  },
  computed: {
    weekday() {
      return this.days[1].map((date) =>
        new Date(
          this.dateMonth.getFullYear(),
          this.dateMonth.getMonth(),
          date
        ).toLocaleString(this.$i18n.locale, { weekday: "short" })
      );
    },
    fillEmployment() {
      const paris = this.employment.map((value) => [
        value.from.getTime(),
        value.to.getTime(),
      ]);
      if (!paris.length) return [];

      const startTime = this.dateMonth.getTime();

      const fill = flatten(this.days)
        .filter((v) => v)
        .map((day) => {
          if (!day) return;
          const leftPart = startTime + (day - 1) * 86400000;
          const rightPart = startTime + day * 86400000 - 1;
          const isLeftPartEmploy = paris.find(
            (v) => v[0] <= leftPart && leftPart < v[1]
          );
          const isRightPartEmploy = paris.find(
            (v) => v[0] <= rightPart && rightPart < v[1]
          );

          if (isLeftPartEmploy && isRightPartEmploy) {
            return "both";
          } else if (isLeftPartEmploy) {
            return "left";
          } else if (isRightPartEmploy) {
            return "right";
          }
        });
      fill.unshift(undefined); // Так как дни идут с 1, не с 0
      return fill;
    },
  },
  async mounted() {
    await this.$nextTick();
    if (
      this.$parent.orientation == "vertical" &&
      this.$parent.dateIn &&
      this.dateMonth.getFullYear() == this.$parent.dateIn.getFullYear() &&
      this.dateMonth.getMonth() == this.$parent.dateIn.getMonth()
    ) {
      setTimeout(() => {
        this.$el.scrollIntoView({ block: "center", behavior: "smooth" });
      }, 500);
    }
  },
};
</script>

<style lang="scss" scoped>
.sc-datepickerext-month {
  table {
    margin-left: -3px;
    width: calc(100% + 6px);
    border-collapse: collapse;
    th {
      text-align: center;
      line-height: 18px;
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 400;
      padding: 8px 0;
      &:nth-child(7n-1),
      &:nth-child(7n) {
        color: #f51449;
      }
    }

    tr {
      border-bottom: 10px solid transparent;
      box-sizing: content-box !important;
    }
  }
  &-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-main-black);
    margin-bottom: 10px;
    text-align: left;
    // font-size: 17px;
    // text-transform: capitalize;
    &.horizontal {
      text-align: center;
      text-transform: capitalize;
      // margin-bottom: 0;
    }
  }
}

.sc-datepickerext-days_week {
  width: 100%;
  position: sticky;
  top: 0;
  // top: 93px;
  background: #fff;
  z-index: 20;

  .days_week {
    &-text {
      margin-bottom: 8px;
      display: flex;
      justify-content: center;
      gap: 14px;
    }
    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 24px;
      font-weight: 500;
      color: #252525;
      &.red {
        color: #f51449;
      }
    }
  }
  .hr {
    height: 1px;
    width: 100%;
    background-color: #d8d8d8;
  }
}
.sc-datepickerext-simple {
  background: #fcfcfc;
  width: 319px;
  height: 260px;
}
</style>
