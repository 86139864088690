// /orders/getOrderDraft?draftCode=EK8RY5kwV&token=mJVruGMgV39AkYqSfFML8Q178283272
import HTTP from "../http-common";
import { formatWithTime } from "@/utils/format";

/**
 * Получения данных о свободных заявках
 *
 * @module getOffersList
 * @param {object} payload Данные для свободных заявок
 * @param {string} payload.hotId Идентификатор offers
 * @return {object} Promise
 */

export default (payload) => {
  let objectData = new FormData();
  objectData.append("offerId", payload.offerId || "");

  return HTTP.post("/json/offers/setBookingByOffer", objectData, {
    headers: {
      "api-version": 'null', // Если указать версию, то будет минимум данных
    },
  });
};
