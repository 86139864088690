import { set, event } from "vue-gtag";
import _ from "lodash";

/**
 *
 * Отправка расширенной аналитики продукта по черновику
 *
 * @module detail
 *
 * @function
 *
 * @param {string} id      - 'dimension1' - '123456'	   - ID объекта
 * @param {string} price   - 'dimension4' - '1000'       - Базовая стоимость суток
 * @param {string} rating  - 'dimension5' - '4'          - Оценка по отзывам
 * @param {string} dateIn  - 'dimension2' - '2018-05-28' - Дата заезда если были выбраны
 * @param {string} dateOut - 'dimension3' - '2018-05-31' - Дата отъезда, ГГГГ-ММ-ДД
 * @param {string} name         - '1-комнатная квартира' - Название объекта
 * @param {string} brand        - 'Москва'               - Город объекта
 * @param {string} item_category     - 'Отдельный объект'      - Тип объекта
 * @param {string} item_category2    - 'Квартира'              - Тип объекта
 * @param {string} variant      - '3 комнаты'            - Количество комнат
 * @param {string} position     - '27'	                 - Количество приоритетов
 *
 */

export default _.debounce(function (
  id,
  name,
  price,
  brand,
  item_category,
  item_category2,
  variant,
  position,
  quantity,
  guests,
  booking,
  list
) {
  set("dimension6", booking);
  set("dimension7", guests);
  set("metric5", guests);

  const items = [
    {
      id,
      name,
      price,
      brand,
      item_category,
      item_category2,
      variant,
      quantity,
      list
    },
  ];
  event("begin_checkout", {
    items,
  });
},
500);
