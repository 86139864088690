import HTTP from "../http-common";

/**
 * Получение данных об объекте
 *
 * @module loadreviewform
 *
 * @return {object} Promise
 *
 */
export default (formData) => {
  return HTTP.post("json/messages/loadreviewform", formData, {
    headers: {
      "api-version": String(null),
    },
    cache: false,
  });
};
