// Удаляем из объекта пустые строки, массивы и тд
export default function(obj) {
  for (let propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "" || obj[propName] === "{}" || obj[propName] === "[]") {
      delete obj[propName];
    }
    if(typeof(obj[propName]) === "object") {
      if(obj[propName].length === 0 || Object.keys(obj[propName]).length === 0) {
        delete obj[propName];
      }
    }
  }
  return obj
}
