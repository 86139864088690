// /orders/getOrderDraft?draftCode=EK8RY5kwV&token=mJVruGMgV39AkYqSfFML8Q178283272
import HTTP from "../http-common";
import { formatWithTime } from "@/utils/format";

/**
 * Метод подключает к объекту указанное число приоритетов, отключает услугу или изменяет ее.
 *
 * @module getOffersList
 * @param {object} payload Данные для метода
 * @param {string} payload.serviceName payload. Название услуги, в данном случае всегда "pr_object"
 * @param {int} payload.objectId ID-объекта для которого нужно изменить услугу ПР
 * @param {string} payload.action Действие с услугой: enable, disable, change
 * @param {object} payload.data Данные для услуги
 * @return {object} Promise
 */

export default (payload) => {
  // let objectData = new FormData();
  // objectData.append("serviceName", payload.serviceName || "");
  // objectData.append("objectId", payload.objectId || "");
  // objectData.append("data", payload.data || "");
  return HTTP.post("/json/services/editServiceObject", JSON.stringify(payload), {
    headers: {
      "api-version": 1.8, // Если указать версию, то будет минимум данных
      "Content-Type": "application/json",
    },
    cache: false
  });
};
