import HTTP from "../http-common";

/**
 * @description Вызов метода должен осуществлять авторизованный пользователь, для которого будет возвращен список его объектов.
 *
 * @module Objects/getObject
 * @return {object} Promise
 */
export default () =>
  HTTP.get("json/objects/getObjectsListUser", { cache: false });
