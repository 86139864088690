import json from "@/assets/json/preferenceFilters.json";
import jsonRopeway from "@/components/base/Filters/dataPerCities.json";
import _ from "lodash";
import { isWhiteLabel, whiteLabelInfo } from "@sutochno/whitelabel";

const PRICE_PER_LOCALE_NAME = "filterPricePer";

const defaultFilters = () => {
  let keys = Object.values(json).reduce((acc, value) => {
    if (Array.isArray(value)) {
      value.forEach((o) => {
        const filterValue = +(
          o.key === "booking_now" && whiteLabelInfo.name === "tinkoff"
        );
        return o.key && (acc = { ...acc, [o.key]: filterValue });
      });
    }
    return acc;
  }, {});
  const localStoragePricePer = localStorage.getItem(PRICE_PER_LOCALE_NAME);
  if (localStoragePricePer) {
    keys.price_per = parseInt(localStoragePricePer);
  }

  return {
    area: [null, null],
    floor: [null, null],
    price: [null, null],
    metro: [null],
    type_id: [],
    separate_bedrooms: null,
    is_studio: null,
    count_all_beds: null,
    count_double_beds: null,
    distance_to_sea: null,
    ropeway_distance: [null],
    place_id: [],
    city_id: [],
    region_id: [],
    price_per: 1,
    ids: [],
    hotel: null,
    stars: [],
    ...keys,
  };
};

const state = () => ({
  filter: { ...defaultFilters() },
  filterRequest: {},
  preference: json,
  selectedFilters: [],
  selectedMetro: [],
  selectedPlace: [],
  selectedRopeway: [],
  metro: [],
});

const getters = {
  getFilter: (state) => {
    return state.filter;
  },

  getMetroDistance: (state) => {
    if (state.filter.metro?.length) {
      const lastItem = state.filter.metro[state.filter.metro.length - 1];
      return typeof lastItem === "number"
        ? lastItem
        : lastItem == null
        ? null
        : undefined;
    }
    return undefined;
  },

  getFilterRequest: (state) => {
    return state.filterRequest;
  },

  getSelectedFilters: (state) => {
    return state.selectedFilters;
  },

  getFilterDescription: (state) => (key, value) => {
    const preferenceKeys = Object.keys(state.preference);

    if (key == "is_studio") {
      const isStudioDescription = state.preference.variantAllocation.find(
        (item) => item.key === "is_studio"
      );
      return {
        ...isStudioDescription,
        value,
        key,
      };
    }

    if (key == "area" || key == "price") {
      return {
        key,
        text: key,
        value,
      };
    }

    if (key == "floor") {
      return {
        key,
        text: "floorValue",
        value,
      };
    }

    if (preferenceKeys.includes(key)) {
      const result = state.preference[key].find(
        (item) => Number(item.value) === Number(value)
      );
      return {
        text: result.text,
        value: result.value,
        key,
      };
    } else {
      for (const pKey of preferenceKeys) {
        if (Array.isArray(state.preference[pKey])) {
          const descriptionIndex = state.preference[pKey].findIndex(
            (item) => item.key === key
          );
          if (descriptionIndex !== -1) {
            const result = { ...state.preference[pKey][descriptionIndex] };
            return result;
          }
        }
      }
    }
    return null;
  },

  priceAllPeriod: (state, getters, rootState, rootGetters) => {
    return state.filter.price
      ?.map((item) =>
        item ? (item / rootGetters["search/days"]).toFixed() : ""
      )
      .join(",");
  },

  locationId: (state, getters, rootState, rootGetters) => {
    return rootState.search.locationForFilter?.id;
  },
  modifiedFiltersForQueries(state, getters, rootState, rootGetters) {
    let filters = {};
    for (const [name, value] of Object.entries(state.filter)) {
      let filterValue = "";
      if (
        name === "metro" &&
        value.length === 1 &&
        typeof value[0] === "number"
      ) {
        filterValue = [null, ...value].join(",");
      } else {
        filterValue = Array.isArray(value)
          ? [...new Set(value)].join(",")
          : value;
      }

      if (filterValue) {
        filters[name] = filterValue;
      }
    }

    //для фильтра избранных
    if (filters.is_favorites) {
      const { objects } = rootState.user.userData;
      filters.ids = objects?.favorites_ids || [];
      delete filters.is_favorites;
    }

    if (!filters.price) {
      delete filters.price_per;
    }

    delete filters.search_history;

    const { pets } = rootState.search.guests;
    if (pets?.value) {
      filters.pets = true;
    } else {
      delete filters.pets;
    }

    const filterValues = _.omit(filters, [
      "1_stars",
      "2_stars",
      "3_stars",
      "4_stars",
      "5_stars",
    ]);

    return filterValues;
  },

  joinedMetro: (state) => {
    const copy = _.cloneDeep(state.selectedMetro);
    const target = copy.map((station) => {
      station.ids = [station.id];
      state.selectedMetro.forEach((item) => {
        if (item.title === station.title) {
          station.ids.push(item.id);
          station.line_colors.push(item.line_colors.at(0));
        }
      });
      station.ids = Array.from(new Set(station.ids));
      station.line_colors = Array.from(new Set(station.line_colors));
      return station;
    });

    return target.reduce((acc, obj) => {
      if (!acc.find((item) => item.title === obj.title)) {
        acc.push(obj);
      }
      return acc;
    }, []);
  },
};

const mutations = {
  setFilter: (state, { key, value }) => {
    state.filter[key] = value;
  },
  setSelectedFilters: (state, payload) => {
    state.selectedFilters = payload;
  },
  setSelectedMetro: (state, payload) => {
    state.selectedMetro = payload;
  },
  setSelectedRopeway: (state, payload) => {
    state.selectedRopeway = payload;
  },
  setSelectedPlace: (state, payload) => {
    state.selectedPlace = payload;
  },
  // запрос с searchObjectsOnMap летит только при обновлении filterRequest
  setFilterRequest: (state, payload) => {
    const filters = _.omit(payload, [
      "1_stars",
      "2_stars",
      "3_stars",
      "4_stars",
      "5_stars",
    ]);
    state.filterRequest = filters;
  },
  setMetro: (state, payload) => {
    state.metro = payload;
  },
};

const actions = {
  updateFilters(
    { state, commit, getters, dispatch },
    { key = "", value, item }
  ) {
    if ((key === "area" || key === "floor" || key === "price") && !value) {
      commit("setFilter", { key, value: [null, null] });
    } else if (key === "hotel") {
      commit("setFilter", { key, value });
      commit("setFilter", { key: "search_hotel", value });
    } else if (key.includes("_stars")) {
      commit("setFilter", { key, value });
      const stars = {
        "1_stars": 1,
        "2_stars": 2,
        "3_stars": 3,
        "4_stars": 4,
        "5_stars": 5,
      };
      const activeStars = Object.keys(stars).filter(
        (star) => state.filter[star]
      );
      const starsValue = activeStars.map((star) => stars[star]);
      commit("setFilter", {
        key: "stars",
        value: state.filter["1_stars"] ? [0, ...starsValue] : starsValue,
      });
    } else if (key.includes("review_score")) {
      commit("setFilter", { key, value });
      const reviewScore = {
        "9_review_score": 9,
        "8_review_score": 8,
        "7_review_score": 7,
        "6_review_score": 6,
      };
      const activeReviewScore = Object.keys(reviewScore).filter(
        (star) => state.filter[star]
      );

      const reviewScoreValue = activeReviewScore.map(
        (star) => reviewScore[star]
      );
      commit("setFilter", {
        key: "review_score",
        value: reviewScoreValue,
      });
    } else if (key === "price") {
      if (Array.isArray(value) && value.length) {
        commit("setFilter", { key, value });
      } else if (value) {
        commit("setFilter", { key, value: [value, value] });
      } else {
        commit("setFilter", { key, value });
      }
    } else {
      commit("setFilter", { key, value });
    }

    if (key === "metro") {
      if (Array.isArray(item)) {
        commit("setSelectedMetro", item);
        if (Array.isArray(value)) {
          const distanceValue = value[value.length - 1];
          const filterItem = getters.getFilterDescription(
            "metroDistance",
            distanceValue
          );
          dispatch("updateSelectedFilters", {
            key,
            value: distanceValue,
            item: {
              ...filterItem,
              key,
            },
          });
        }
      } else {
        const distanceValue = Array.isArray(value)
          ? value[value.length - 1]
          : value;
        dispatch("updateSelectedFilters", { key, value: distanceValue, item });
      }
    } else if (key === "ropeway_distance") {
      const ropeways = value.filter(
        (ropeway, index) => index !== value.length - 1
      );
      const ropewayDistance = value.find(
        (ropeway, index) => index === value.length - 1
      );
      const ropewayDistanceDescription = getters.getFilterDescription(
        "distanceToRopeway",
        ropewayDistance
      );
      const ropewayDistanceData = {
        ...ropewayDistanceDescription,
        key: "ropeway_distance",
      };

      if (jsonRopeway[getters.locationId]) {
        const ropewayData = jsonRopeway[getters.locationId]?.variants;
        const ropewaySelected = ropewayData.filter((ropeway) =>
          ropeways.includes(ropeway.value)
        );
        const ropewaySelectedData = ropewaySelected.map((ropeway) => {
          return {
            ...ropeway,
            key: "ropeway_distance",
          };
        });

        const ropewayPayload = {
          key: "ropeway_distance",
          value: [...ropeways, ropewayDistance],
          item: [...ropewaySelectedData, ropewayDistanceData],
        };
        dispatch("updateSelectedFilters", ropewayPayload);
      } else {
        const ropewayPayload = {
          key: "ropeway_distance",
          value: [null],
          item: [],
        };
        dispatch("updateSelectedFilters", ropewayPayload);
      }

      commit("setFilter", { key, value });
    } else if (key === "place_id" || key === "region_id") {
      commit("setSelectedPlace", item);
      dispatch("updateSelectedFilters", { key, value, item });
    } else if (key !== "price_per" && key !== "ids" && key !== "stars") {
      dispatch("updateSelectedFilters", { key, value, item });
    }
  },

  updateSelectedFilters({ state, commit, getters }, { key, value, item = [] }) {
    let selected = [...state.selectedFilters];
    const isValue = Array.isArray(value)
      ? value.filter((elem) => elem).length
      : value;

    if (key === "ropeway_distance") {
      const newSelected = selected.filter(
        (elem) => elem.key !== "ropeway_distance"
      );
      if (Array.isArray(item)) {
        item.forEach((ropewayItem) => {
          if (ropewayItem.value) {
            newSelected.push(ropewayItem);
          }
        });
      }
      commit("setSelectedFilters", newSelected);
      return;
    }

    const newSelected = selected.filter((elem) => elem.key !== key);
    if (isValue) {
      const items = Array.isArray(item) ? item : [item];
      items.forEach((elem) => {
        if (elem.value) {
          const payload = {
            ...elem,
            text: elem.text,
          };
          newSelected.push(payload);
        }
      });
    }
    commit("setSelectedFilters", newSelected);
  },

  resetFilter({ state, commit, dispatch, getters }, filterKey = []) {
    const filterOption = { ...defaultFilters() };
    const objKeys = Object.keys(filterOption);
    if (filterKey.length) {
      filterKey.forEach((key) => {
        dispatch("updateFilters", { key, value: filterOption[key], item: [] });
        if (key === "price") {
          dispatch("updateFilters", {
            key: "price_per",
            value: filterOption["price_per"],
          });
        }

        if (key === "metro") {
          commit("setSelectedMetro", []);
        }

        if (key === "place_id" || key === "region_id") {
          commit("setSelectedPlace", []);
        }
      });
    } else {
      objKeys.forEach((key) => {
        dispatch("updateFilters", { key, value: filterOption[key] });
      });
      commit("setSelectedMetro", []);
      commit("setSelectedPlace", []);
    }
  },

  setQueryFilters({ state, commit, getters, dispatch }, route) {
    let { query, hash } = route;
    commit("search/positionMutate", Boolean(query.isPosition), {
      root: true,
    });
    if (query.objectPosition) {
      commit("searchPr/objectPositionMutate", query.objectPosition, {
        root: true,
      });
    }
    // исключения которые не должны входить в фильтры из query
    let filters = _.omit(
      query,
      "id",
      "type",
      "max_guests",
      "guests_adults",
      "guests_childrens",
      "occupied",
      "sortings",
      "cheap_and_fast",
      "place",
      "map",
      "ropeways",
      "SW.lat",
      "SW.lng",
      "NE.lat",
      "NE.lng",
      "point",
      "term",
      "objectScroll",
      "search_history",
      "isPosition",
      "objectPosition"
    );

    for (const [name, value] of Object.entries(filters)) {
      if (value && value.toString().includes(",")) {
        if (name === "metro" || name === "ropeway_distance") {
          const filterValue = value.split(",").map((item) => Number(item));
          const distance = filterValue[filterValue.length - 1];
          filterValue.splice(filterValue.length - 1);
          const list =
            filterValue.length === 1 && !filterValue[0] ? [null] : filterValue;
          filters[name] = [...list, distance ? Number(distance) : null];
        } else {
          filters[name] = value
            .split(",")
            .map((item) => (item ? Number(item) : null));
        }
      }
    }

    if (query.place_id) {
      if (query.place_id.includes(","))
        filters.place_id = query.place_id.split(",");
      else filters.place_id = [query.place_id];
    }

    if (query.region_id) {
      if (query.region_id.includes(","))
        filters.region_id = query.region_id.split(",");
      else filters.region_id = [query.region_id];
    }

    if (query.stars) {
      if (query.stars.includes(",")) {
        filters.stars = query.stars.split(",");
      } else filters.stars = [query.stars];
    }

    if (query.review_score) {
      if (query.review_score.includes(",")) {
        filters.review_score = query.review_score.split(",");
      } else {
        filters.review_score = [query.review_score];
      }
    }

    // VUECLIENT-834 исключение объектов броневика для wl
    if (isWhiteLabel) {
      filters.exclude_users = 4784337;
    }

    const filterKeys = Object.keys(filters);
    filterKeys.forEach((key) => {
      if (key === "metro" || key === "ropeway_distance") {
        let distance = 0,
          list = [];

        if (Array.isArray(filters[key])) {
          distance = filters[key][filters[key].length - 1];
          list = filters[key].filter(
            (item, index) => index !== filters[key].length - 1
          );
        } else {
          distance = filters[key];
        }

        if (distance) {
          const descriptionKey =
            key === "metro" ? "metroDistance" : "distanceToRopeway";
          const filterItem = getters.getFilterDescription(
            descriptionKey,
            distance
          );
          const payload = {
            key,
            value: [filterItem.value],
            item: {
              ...filterItem,
              key,
            },
          };
          dispatch("updateFilters", payload);
        }
        if (list.length && list[0]) {
          const payload = {
            key,
            value: [...list, distance],
            item: [],
          };
          dispatch("updateFilters", payload);
        }
      } else if (key === "place_id" || key === "region_id") {
        if (filters[key]) {
          const placeIds = filters[key].map((item) => Number(item));
          const payload = {
            key,
            value: placeIds,
            item: [],
          };
          dispatch("updateFilters", payload);
        }
      } else if (key === "review_score") {
        dispatch("updateFilters", { key, value: filters[key], item: [] });

        const reviewScore = {
          "9_review_score": 9,
          "8_review_score": 8,
          "7_review_score": 7,
          "6_review_score": 6,
        };
        const activeReviewScore = Object.keys(reviewScore).filter((item) =>
          filters[key].includes(String(reviewScore[item]))
        );
        activeReviewScore.forEach((item) => {
          const filterItem = getters.getFilterDescription(
            "reviews",
            reviewScore[item]
          );
          const payload = {
            key: item,
            value: filterItem?.value,
            item: {
              ...filterItem,
              key: item,
            },
          };
          dispatch("updateFilters", payload);
        });
      } else if (key === "stars") {
        dispatch("updateFilters", { key, value: filters[key], item: [] });

        const stars = {
          "1_stars": 1,
          "2_stars": 2,
          "3_stars": 3,
          "4_stars": 4,
          "5_stars": 5,
        };
        const activeStars = Object.keys(stars).filter((star) =>
          filters[key].includes(String(stars[star]))
        );
        activeStars.forEach((star) => {
          const filterItem = getters.getFilterDescription(
            "hotelStars",
            stars[star]
          );
          const payload = {
            key: star,
            value: filterItem?.value,
            item: {
              ...filterItem,
              key: star,
            },
          };
          dispatch("updateFilters", payload);
        });
      } else if (key === "price_per") {
        const localStoragePricePer = localStorage.getItem(PRICE_PER_LOCALE_NAME);
        const filterItem = getters.getFilterDescription(key, filters[key]);
        if (filterItem) {
          const payload = {
            key,
            value: localStoragePricePer ? parseInt(localStoragePricePer) : filterItem.value,
            item: filterItem,
          };
          dispatch("updateFilters", payload);
        }
      } else {
        const filterItem = getters.getFilterDescription(key, filters[key]);
        if (filterItem) {
          const payload = {
            key,
            value: filterItem.value,
            item: filterItem,
          };
          dispatch("updateFilters", payload);
        }
      }
    });

    commit("setFilterRequest", { ...state.filter });
  },
  setPricePerFilter({ commit }, value) {
    localStorage.setItem(PRICE_PER_LOCALE_NAME, value);
    commit("setFilter", {
      key: "price_per",
      value
    });
  }
};

export default {
  namespaced: true,
  namespace: "filter",
  state,
  getters,
  actions,
  mutations,
};
