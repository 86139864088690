import { event as sendEventGtag } from "@/composables/gtag/index.js";
/**
 *
 * Отправка данных в аналитику, при отправке формы
 *
 * @function
 *
 * @param {string} city  - 'Москва' - город объекта
 * @param {string} price - '2000'   - общая стоимость проживания
 *
 */
export default function (city, price) {

  sendEventGtag("booking", {
    step2: city,
    value: price,
  })

}
