<i18n lang="json">
{
  "ru": {
    "pageNotFound": "К сожалению, такой страницы нет.",
    "haveQuestion": "Если есть вопросы,",
    "contactUs": "обращайтесь к нам",
    "orderApartment": "А если хотите забронировать жильё, начните прямо здесь:"
  },
  "en": {
    "pageNotFound": "Unfortunately, there is no such page.",
    "haveQuestion": "If you have any questions,",
    "contactUs": "contact us",
    "orderApartment": "And if you want to book accommodation, start right here:"
  }
}
</i18n>

<template>
  <main>
    <div class="container">
      <div class="notfound">
        <div class="notfound__content">
          <h1 class="notfound__title">404</h1>
          <h2 class="notfound__subtitle">{{ t("pageNotFound") }}</h2>
          <p class="notfound__text">
            {{ t("haveQuestion") }}
            <a href="https://sutochno.ru/about/feedback?from=404">{{
              t("contactUs")
            }}</a>.
            {{  t("orderApartment")  }}
          </p>
        </div>
        <SearchPanelMain v-if="isMobile"/>
        <SearchWidget v-else/>
      </div>
    </div>
  </main>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { computed } from "vue";
import SearchWidget from "@/components/desktop/Main/SearchWidget.vue";
import SearchPanelMain from "@/components/mobile/NewSearch/SearchParams/SearchPanelMain.vue";

const { t } = useI18n();
const store = useStore();
const isMobile = computed(() => store.getters["user/isMobile"]);
</script>

<style lang="scss" scoped>
h1 {
  font-size: 150px;
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 1;

  @media (max-width: 768px) {
    font-size: 80px;
  }
}

h2 {
  font-size: 22px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
}

a {
  color: #f51449;
}

p {
  color: #5a5d63;
  line-height: 20px;
  font-size: 14px;
  margin-top: 10px;
}

.notfound {
  margin: 140px 0;
  font-family: "Inter", sans-serif;

  @media (max-width: 768px) {
    margin: 90px 0;
  }

  &__content {
    text-align: center;
    @media (max-width: 768px) {
      margin-bottom: 30px;
    }
  }

  &__text {
    max-width: 406px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
