import HTTP, { TOKEN } from "../http-common";

/**
 * Получение j, j,]trnt
 *
 * @module getOrdersByObject
 * @return {order} Promise
 */
export default (object_id) =>
  HTTP.get("json/orders/getOrdersByObject", {
    params: { object_id },
    headers: {
      "api-version": 1.8, // Если указать версию, то будет минимум данных
      token: TOKEN,
    },
    cache: false,
  });
