<i18n lang="json">
{
	"ru": {
		"label": "В какой город едете?"
	},
	"en": {
	}
}
</i18n>
<template>
  <div v-click-outside.mousedown="clickOutside" class="suggest-wrapper">
    <input
      ref="input"
      v-model="query"
      class="suggest-input"
      :placeholder="t('label')"
      :class="{ 'suggest-input--fill': query && query.length }"
      autocomplete="off"
      type="text"
      name="suggest"
      @focus="onFocus"
    />
    <div class="suggest-close icon-app-cross-min" @click="onClear"></div>
    <div class="suggest-icon" @click="onClear"></div>
    <List
      v-if="focused"
      class="suggest-list"
      :value="query"
      :is-complete="false"
      @choice="onChoice"
    />
  </div>
</template>
<script>
import List from "./List";
import { mapState } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "BaseSuggestionsSearchHeader",
  components: {
    List,
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      query: "",
      focused: false,
    };
  },
  computed: {
    ...mapState("search", ["term"]),
  },
  watch: {
    term: {
      deep: true,
      handler(term) {
        if (this.query != term) {
          this.query = term;
          this.$nextTick(() => {
            this.focused = false;
          });
        }
      },
    },
  },
  mounted() {
    this.query = this.term;
  },
  methods: {
    clickOutside() {
      this.query = this.term;
      this.focused = false;
    },
    onChoice(variant) {
      this.query = variant.title;
      this.$nextTick(() => {
        this.$refs.input.blur();
        this.focused = false;
      });
    },
    onClear() {
      this.query = "";
      this.$refs.input.focus();
    },
    onFocus() {
      this.focused = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.suggest {
  &-wrapper {
    position: relative;
    height: initial !important;
    // display: flex;
    // align-content: center;
  }
  &-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background-image: url("~/assets/img/search.svg");
    height: 16px;
    width: 16px;
    // background-position: 10px center;
    // background-repeat: no-repeat;
    // border-radius: 2px;
    // width: 100%;
    // border-color: transparent;
    // padding: 4px;
    // padding-left: 32px;
  }
  &-input {
    // height: 60px;
    padding: 0 32px;
    width: 100%;
    font-size: 16px;
    height: 36px;
    background-color: rgba(255, 255, 255, 0.09);
    border-radius: 3px;
    border: 1px solid transparent;
    color: white;
    &:focus {
      color: black;
      border: 1px solid transparent;
      outline: none;
      background: white;
    }
  }
  &-close {
    position: absolute;
    right: 0px;
    top: 50%;
    font-size: 16px;
    padding: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0;
  }
  &-input:focus ~ &-close {
    opacity: 1;
  }
  // &-list {
  //   display: none;
  // }
  &-list {
    margin-top: 10px;
    left: 0;
    width: 592px;
  }
}
</style>
