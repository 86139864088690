import { event } from "vue-gtag";

export default function (type, device = "desktop") {
  if (!window.gtag || !type) return false;
  if (device === "mobile") {
    if (type === "default") {
      event("search_sa_m", {
        click: "review_sort_useful",
      });
    } else {
      event("search_sa_m", {
        click: `review_sort_${type}`,
      });
    }
  } else {
    if (type === "default") {
      event("search_sa", {
        click: "review_sort_useful",
      });
    } else {
      event("search_sa", {
        click: `review_sort_${type}`,
      });
    }
  }
}
