import HTTP, { TOKEN } from "../http-common";

/**
 * Получение j, j,]trnt
 *
 * @module getObject
 * @return {object} Promise
 */
export default (id, currency_id) =>
  HTTP.get("json/objects/getObject", {
    params: { id, currency_id },
    headers: {
      "api-version": '1.10', // Если указать версию, то будет минимум данных
      token: TOKEN
    },
    cache: false,
  });
