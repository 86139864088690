import { useGtm } from "@gtm-support/vue-gtm";

export default function (eventCategory, page, products, bookingId) {
  const gtmData = {
    event: "partner_event",
    eventCategory: eventCategory,
    eventAction: "success",
    eventLabel: "sutochno",
  };
  if (page) {
    gtmData.page = page;
  }
  if (products) {
    gtmData.products = products;
  }
  useGtm().trackEvent(gtmData);
}
