import { event as sendEventGtag } from "@/composables/gtag/index.js";

/**
 *
 * Отправка события авторизации на сайте
 *
 * @function
 *
 * @param {string} status  - "success | error" - авторизация прошла успешно или нет
 * @param {string} method - "sms | telegram | sm_vk | password | etc" - каким способом была авторизация
 * @param {string} type - "global | fast" - global - шапка, fast - карточка объекта
 *
 */
export default function (status = "success", method = "sms", type = "fast") {
  sendEventGtag("login", {
    method: method,
    login_status: status,
    login_type: type,
  });
}
