<i18n>
{
  "ru": {
    "save": "Сохранить",
    "search": "Найти"
  }
}
</i18n>

<template>
  <div class="choise-guests">
    <div class="choise-guests__main">
      <LightGuestOptions
        :guests="getLocalGuestsData"
        @changeChildren="changeChildren"
        @changeAdults="changeAdults"
        @changePetsValue="changePetsValue"
      />
    </div>

    <div class="choise-guests__save">
      <UIButton
        :buttonTheme="showOnlyOptions ? 'red' : 'dark'"
        @click="saveGuestsValues"
        :class="{ 'search btn-search-main' : showOnlyOptions }"
        :disabled="searchDisabled && showOnlyOptions"
      >
        {{ showOnlyOptions ? t("search") : t("save") }}
      </UIButton>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";

import LightGuestOptions from "@/components/base/SelectGuestsCopy/LightGuestOptions";
import UIButton from "@/components/UIKit/UIButton.vue";
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  modelValue: {
    type: Object,
    default: function () {
      return {
        adults: 2,
        childrens: [],
        pets: {
          value: false,
          description: "",
        },
      };
    },
  },
  showOnlyOptions: Boolean,
});
const emits = defineEmits(["update:modelValue", "finishChoiseGuests"]);
const store = useStore();

const adults = ref(1);
const children = ref([]);
const pets = ref(1);

const searchDisabled = ref(false);

onMounted(() => {
  adults.value = props.modelValue.adults;
  children.value = [...props.modelValue.childrens];
  pets.value = props.modelValue.pets.value;

  store.commit("search/setChildrenWithoutAgeError", false);
});

const getLocalGuestsData = computed(() => {
  return {
    adults: adults.value,
    childrens: children.value,
    pets: {
      value: pets.value,
    },
  };
});

function changeAdults(val) {
  adults.value = val;
}
function changeChildren(val) {
  children.value = val;
  if (!val.includes(null)) {
    store.commit("search/setChildrenWithoutAgeError", false);
    searchDisabled.value = false;
  }
}
function changePetsValue(val) {
  pets.value = val;
}

function saveGuestsValues() {
  if (children.value.includes(null)) {
    store.commit("search/setChildrenWithoutAgeError", true);
    searchDisabled.value = true;
    return;
  }
  emits("update:modelValue", getLocalGuestsData.value);
  emits("finishChoiseGuests");
}
</script>

<style lang="scss" scoped>
.choise-guests {
  height: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: space-between;

  &__main {
    padding: 16px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: calc(100% - 125px);
  }

  &__save {
    padding: 10px 16px 20px;
    position: fixed;
    bottom: -2px;
    left: 0;
    width: calc(100% + 1px);
    background: #fff;
    z-index: 2;
  }

  .search {
    display: flex;
    gap: 5px;
    &::before {
      content: url("~/assets/img/icon/icon_navigation_search.svg");
      transform: translateY(2px);
    }
  }
}
</style>
