// Получение id клиента
import { query } from "vue-gtag";

export default function () {
  return new Promise((resolve, reject) => {
    if (!window.gtag) {
      reject();
    }
    query("get", "G-B0K4L0V6J8", "client_id", (id) => {
      if (id) {
        resolve(id);
      }
      reject();
    })
  });
}
