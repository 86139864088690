import HTTP, { TOKEN } from "../http-common";

/**
 * Получение данных о рейтинге отеля
 *
 * @module getAvgReviewRatingForObject
 *
 * @param {number} objectId - ID объекта
 *
 * @return {object} Promise
 *
 */
export default (hotel_id) =>
  HTTP.get("json/messages/getHotelRating", {
    params: { hotel_id },
    headers: {
      "api-version": "1.10", // Если указать версию, то будет минимум данных
      token: TOKEN,
    },
    cache: false,
  });
