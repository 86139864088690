/**
 *
 * Отправка данных в Яндекс метрику по заявке черновика
 *
 * @function
 *
 * Все параметры по default
 *
 */
export default function () {
  if (!window.ym) return false;

  if (window.ym) {
    return window.ym(89152724, "reachGoal", "booking");
  }
}
