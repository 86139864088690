import { event } from "vue-gtag";
/**
 *
 * Отправка данных в аналитику ,при бронировании на странице черновика нижней кнопкой
 *
 * @function
 *
 *
 */
export default function () {
  if (!window.gtag) return false;

  if (window.gtag) {
    event("draft_sa", {
      click: "btn_bottom",
    });
  }
}
