import { useGtm } from '@gtm-support/vue-gtm';
/**
 *
 * Обработка изменений в форме бронирования
 *
 * @function
 *
 * @param {string} id       - '123456' - ID объекта
 * @param {string} price    - '200.99' - Общая рассчитанная стоимость проживания после отправки
 * @param {string} quantity - '2'      - Количество дней
 * @param {string} currency - 'RUB'    - Валюта при отправке формы
 *
 */
export default function (id, price, quantity, currency) {
  useGtm().trackEvent({
    event: "crto_basketpage",
    crto: {
      email: "79054025255fb1a26e4bc422aef54eb4",
      currency: currency,
      products: [
        {
          id,
          price,
          quantity,
        },
      ],
    },
  });
}
