<i18n>
{
  "ru": {
    "locationTitle": "Курорт, город или адрес",
    "where": "Куда едем",
    "in": "Заезд",
    "out": "Отъезд",
    "choise": "Когда",
    "guestChoice":"Выбор гостей",
    "guests": "Гости",
    "adult": "0|{n} взрослый | {n} взрослых | {n} взрослых",
    "guest": "0|{n} гость | {n} гостя | {n} гостей",
    "child": " без детей |{n} ребёнок | {n} ребёнка | {n} детей",
    "withoutChildrenPets": "без детей и питомцев",
    "withPets": "с питомцами",
    "reset":"Сбросить",
    "search": "Найти"
  },
  "en": {
    "locationTitle": "Resort, city or address",
    "where": "Where we are going",
    "in": "Check-in",
    "out": "Departure",
    "choise": "When",
    "guestChoice":"Guest Choice",
    "guests": "Guests",
    "adult": "0|{n} adult | {n} adults | {n} adults",
    "guest": "0|{n} guest | {n} guest | {n} guests",
    "child": "no children |{n} child | {n} child | {n} children",
    "withoutChildrenPets": "without children and pets",
    "withPets": "with pets",
    "reset":"Reset",
    "search": "Find"
  }
}
</i18n>
<template>
  <div class="search-widget">
    <div class="search-widget__main">
      <div
        class="field-location field"
        @click="emits('setShowOptionType', 'location')"
      >
        <span class="field__title">
          {{ t("locationTitle") }}
        </span>
        <span class="field__value">
          {{ locationText ? locationText : t("where") }}
        </span>
      </div>

      <div class="field-dates field">
        <div
          class="field-dates__item"
          @click="emits('setShowOptionType', 'dates-in')"
        >
          <span class="field__title">
            {{ t("in") }}
          </span>
          <span class="field__value lowercase">
            {{ getDatesText.in ? getDatesText.in : t("choise") }}
          </span>
        </div>
        <div
          class="field-dates__item"
          @click="emits('setShowOptionType','dates-out')"
        >
          <span class="field__title">
            {{ t("out") }}
          </span>
          <span class="field__value lowercase">
            {{ getDatesText.out ? getDatesText.out : t("choise") }}
          </span>
        </div>
      </div>

      <div
        class="field-location field"
        @click="emits('setShowOptionType', 'guests')"
      >
        <span class="field__title">
          {{ t("guests") }}
        </span>
        <span class="field__value">
          {{ getGuestsText.adults }},
          <span>{{ getGuestsText.childrenPets }}</span>
        </span>
      </div>
    </div>

    <div class="search-widget__btns">
      <UIButton @click="emits('resetOptions')">{{ t("reset") }}</UIButton>
      <UIButton
        class="btn-search-main"
        buttonTheme="red"
        :disabled="isSearchDisabled"
        :loading="loading"
        @click="emits('search')"
      >
        <span class="search-widget__search">{{ t("search") }}</span>
      </UIButton>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import UIButton from "@/components/UIKit/UIButton.vue";
import { useStore } from "vuex";

const { t, locale } = useI18n();
const store = useStore();

const props = defineProps({
  locationTerm: String,
  dates: {
    type: Object,
    default: function () {
      return {
        in: null,
        out: null,
      };
    },
  },
  guests: {
    type: Object,
    default: function () {
      return {
        adults: 2,
        childrens: [],
        pets: {
          value: false,
          description: "",
        },
      };
    },
  },
  locationLocalValue: Object,
  datesLocalValue: Object,
  guestsLocalValue: Object,
});
const emits = defineEmits([
  "setShowOptionType",
  "resetOptions",
  "search",
  "update:locationLocalValue",
  "update:datesLocalValue",
  "update:guestsLocalValue",
]);

const locationText = computed(
  () => props.locationLocalValue?.title || props.locationTerm
);
const dates = computed(() => props.datesLocalValue || props.dates || {});
const guests = computed(() => props.guestsLocalValue || props.guests || {});
const storeLocation = computed(() => store.state.search.location)
if (!props.locationLocalValue) {
  if (props.locationTerm === storeLocation.value.title){
    emits("update:locationLocalValue", storeLocation.value);
  }
}
if (!props.datesLocalValue) {
  emits("update:datesLocalValue", props.dates);
}
if (!props.guestsLocalValue) {
  emits("update:guestsLocalValue", props.guests);
}

// вывод информации
const isOneYear = computed(() => {
  if (dates.value.in && dates.value.out) {
    return dates.value.in.getFullYear() === dates.value.out.getFullYear();
  }
  return true;
});
const getDatesText = computed(() => {
  const result = {
    in: "",
    out: "",
  };

  if (dates.value.in) {
    result.in = dates.value.in.toLocaleString(locale, {
      day: "numeric",
      month: "short",
    });
    if (result.in.slice(-1) === ".") {
      result.in = result.in.slice(0, -1)
    }
    if (!isOneYear.value) {
      result.in += ` ${dates.value.in.toLocaleString(locale, {
        year: "numeric",
      })}`;
    }

    result.in +=
      ", " +
      dates.value.in.toLocaleString(locale, {
        weekday: "short",
      });
  }
  if (dates.value.out) {
    result.out = dates.value.out.toLocaleString(locale, {
      day: "numeric",
      month: "short",
    });
    if (result.out.slice(-1) === ".") {
      result.out = result.out.slice(0, -1)
    }
    if (!isOneYear.value) {
      result.out += ` ${dates.value.out.toLocaleString(locale, {
        year: "numeric",
      })}`;
    }

    result.out +=
      ", " +
      dates.value.out.toLocaleString(locale, {
        weekday: "short",
      });
  }

  return result;
});
const getGuestsText = computed(() => {
  const result = { adults: t("adult", guests.value.adults) };
  if (!guests.value.childrens?.length && !guests.value?.pets?.value) {
    result.childrenPets = t("withoutChildrenPets");
  } else {
    result.childrenPets = t("child", guests.value.childrens?.length || 0);
    if (guests.value?.pets?.value) {
      result.childrenPets += ", " + t("withPets");
    }
  }
  return result;
});

const isSearchDisabled = computed(
  () => !locationText.value || !dates.value.in || !dates.value.out
);

const loading = computed(() => store.state.search.isLoadingLocation)
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  justify-content: center;
  width: 100%;
}

.search-widget {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  justify-content: space-between;
  height: 100%;

  &__main {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  &__btns {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 16px 16px;
    display: flex;
    gap: 12px;
  }
  &__search {
    display: flex;
    align-items: center;
    gap: 10px;
    &::before {
      content: url("~/assets/img/icon/icon_navigation_search_sm-white.svg");
      width: 18px;
      height: 19px;
    }
  }
}

.field {
  padding: 0 16px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #e7e9f3;
  border-radius: 10px;

  &__title {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #5a5d63;
  }
  &__value {
    color: #1c1c1c;
    font-weight: 500;
    line-height: 20px;

    span{
      font-weight: 400;
      color: #5A5D63;
    }
  }
}
.field-dates {
  flex-direction: row;
  justify-content: space-between;

  &__item {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:first-child {
      padding-right: 5px;
    }
    &:last-child {
      padding-left: 16px;
      border-left: 1px solid #e7e9f3;
    }
  }
}
.lowercase {
  text-transform: lowercase;
}
</style>
