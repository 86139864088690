import editServiceObject from "./editServiceObject";
import getDelayedPrByObjects from "./getDelayedPrByObjects";

/**
 * Работа с сервисами
 *
 * @module Services
 */
export default {
  editServiceObject,
  getDelayedPrByObjects
};
