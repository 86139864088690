import HTTP from "../http-common";
import { formatWithTime } from "@/utils/format";

/**
 * Получение данных для черновика и генерация ссылки в ответе
 *
 * @module updateOrderDraft
 * @param {object} payload Данные для черновика
 * @param {string} payload.order_code Сгенерированный хэш, генерируется в ответе из этого же метода
 * @param {string} payload.id_legal_person ID лигального пользователя
 * @param {string} payload.is_legal_person Лигален ли пользователь
 * @param {string} payload.hotel_booking Если отель то true
 * @param {string} payload.rooms_cnt Количество комнат
 * @param {string} payload.is_doc Нажат ли чекбокс с отчетными документами
 * @param {string} payload.target Хз что такое
 * @param {string} payload.pets Строка с указанием какие питомцы
 * @param {string} payload.is_pets Нажат ли чекбокс с питомцами
 * @param {string} payload.phone Выбранный телефон пользователя, совершающего бронь
 * @param {string} payload.email Email, совершающего бронь
 * @param {string} payload.name Имя записанное в input
 * @param {string} payload.guests Количество выбранных гостей
 * @param {string} payload.time_begin Время заезда
 * @param {string} payload.time_end Время отъезда
 * @param {string} payload.date_begin Дата хаезда
 * @param {string} payload.date_end Дата отъезда
 * @param {string} payload.data_order_code Еще раз хэш
 * @param {string} payload.object_id Уникальный идентификатор объекта
 * @param {string} payload.price Сгенерированная цена по заданным параметрам
 * @param {string} payload.tm Тайм-зона пользователя
 * @return {object} Promise
 */

export default (payload, field) => {
  let objectData = new FormData();

  /*
  objectData.append("order_code", payload.code || "");
  objectData.append("data[id_legal_person]", payload.id_legal_person || "");
  objectData.append("data[is_legal_person]", payload.is_legal_person || "");
  if (payload.hotel_booking) {
    objectData.append("data[hotel_booking]", payload.hotel_booking || "");
  }
  objectData.append("data[rooms_cnt]", payload.rooms_cnt || "");
  objectData.append("data[is_doc]", payload.is_doc || "");
  objectData.append("data[target]", payload.target || "");
  objectData.append("data[pets]", payload.pets || "");
  objectData.append("data[is_pets]", payload.is_pets || "");
  objectData.append("data[phone]", payload.phone || "");
  objectData.append("data[email]", payload.email || "");
  objectData.append("data[name]", payload.name || "");

  payload.guests.map((g, i) => {
    objectData.append(`data[guests][${i}][type]`, String(g.type) || "");
    if (g.age) {
      objectData.append(`data[guests][${i}][age]`, String(g.age) || "");
    }
  });

  objectData.append("data[time_begin]", payload.time_begin || "");
  objectData.append("data[time_end]", payload.time_end || "");
  if (payload.date_begin instanceof Date) {
    objectData.append(
      "data[date_begin]",
      formatWithTime(payload.date_begin) || ""
    );
  } else {
    objectData.append("data[date_begin]", payload.date_begin || "");
  }
  if (payload.date_end instanceof Date) {
    objectData.append("data[date_end]", formatWithTime(payload.date_end) || "");
  } else {
    objectData.append("data[date_end]", payload.date_end || "");
  }
  objectData.append("data[order_code]", payload.code || "");
  objectData.append("data[object_id]", payload.object_id || "");
  objectData.append("data[price]", payload.price || "");
  objectData.append("tm", payload.tm || "");
  */

  objectData.append("order_code", payload.code || "");
  //TO DO: вернуть и не передавать в api метод в черновике //objectData.append("data[object_id]", payload.object_id || "");

  if (field.name == "id_legal_person") {
    objectData.append("data[id_legal_person]", payload.id_legal_person || "");
  }
  if (field.name == "is_legal_person") {
    objectData.append("data[is_legal_person]", payload.is_legal_person || "");
  }
  if (field.name == "is_doc") {
    objectData.append("data[is_doc]", payload.is_doc || "");
  }
  if (field.name == "target") {
    objectData.append("data[target]", payload.target || "");
  }
  if (field.name == "rooms_cnt") {
    objectData.append("data[rooms_cnt]", payload.rooms_cnt || "");
  }
  if (field.name == "is_pets") {
    objectData.append("data[is_pets]", payload.is_pets || "");
  }
  if (field.name == "pets") {
    objectData.append("data[pets]", payload.pets || "");
  }
  if (field.name == "email") {
    objectData.append("data[email]", payload.email || "");
  }
  if (field.name == "name") {
    objectData.append("data[name]", payload.name || "");
  }
  if (field.name == "phone") {
    objectData.append("data[phone]", payload.phone || "");
  }
  if (field.name == "time_begin") {
    objectData.append("data[time_begin]", payload.time_begin);
  }
  if (field.name == "time_end") {
    objectData.append("data[time_end]", payload.time_end);
  }
  if (field.name == "date_begin") {
    if (payload.date_begin instanceof Date) {
      objectData.append(
        "data[date_begin]",
        formatWithTime(payload.date_begin) || ""
      );
    } else {
      objectData.append("data[date_begin]", payload.date_begin || "");
    }
  }
  if (field.name == "date_end") {
    if (payload.date_end instanceof Date) {
      objectData.append("data[date_end]", formatWithTime(payload.date_end) || "");
    } else {
      objectData.append("data[date_end]", payload.date_end || "");
    }
  }

  if (field.name == "occupied") {
    if (payload.date_begin instanceof Date) {
      objectData.append(
        "data[date_begin]",
        formatWithTime(payload.date_begin) || ""
      );
    } else {
      objectData.append("data[date_begin]", payload.date_begin || "");
    }
    if (payload.date_end instanceof Date) {
      objectData.append("data[date_end]", formatWithTime(payload.date_end) || "");
    } else {
      objectData.append("data[date_end]", payload.date_end || "");
    }
  }

  if (field.name == "guests") {
    payload.guests.map((g, i) => {
      objectData.append(`data[guests][${i}][type]`, String(g.type) || "");
      if (g.age || g.age === 0) {
        objectData.append(`data[guests][${i}][age]`, String(g.age) || "");
      }
    });
  }

  return HTTP.post("json/orders/updateOrderDraft", objectData, {
    adapter: null,
    cancelToken: field?.cancelToken?.token || null,
    headers: {
      "api-version": "1.10" // Если указать версию, то будет минимум данных
    },
  });
};
