import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed, toRefs } from "vue";
import dateFormatter from "@/utils/dateFormatter";

function useCommonParams () {
  const route = useRoute();
  const store = useStore();
  const search = computed(() => store.state.search)

  const {
    guests,
    checkIn,
    checkOut,
    term,
  } = toRefs(search.value) || {};

  const start_date = computed(
    () => dateFormatter(checkIn?.value) === "1970-01-01"
      ? ""
      : dateFormatter(checkIn?.value)
  );

  const finish_date = computed(
    () => dateFormatter(checkOut?.value) === "1970-01-01"
      ? ""
      : dateFormatter(checkOut?.value)
  );

  return computed(() => {
    try {
      const result = {
        conversation_id: route?.query?.conversation_id || "",
        adt: guests?.value?.adults,
        chd: guests?.value?.childrens?.length,
        start_date: start_date.value,
        finish_date: finish_date.value,
        destination: Boolean(term?.value)
          ? term?.value
          : store.state.booking.objectData?.location?.city,
      };

      if (guests?.value?.childrens?.length) {
        result.parameter = "age:" + guests?.value?.childrens?.join(",");
      }
      return result;
    } catch (e) {
      console.log(e)
    }
  });
};

export default useCommonParams;
