import getLocations from "./getLocations";
import getLocationByIdAndType from "./getLocationByIdAndType";
import getSightsByType from "./getSightsByType";
import getDistanceSights from "./getDistanceSights";
/**
 * Работа с локациями
 *
 * @module Locations
 */
export default {
  getLocations,
  getLocationByIdAndType,
  getSightsByType,
  getDistanceSights,
};
