import HTTP from "../http-common";
import { formatWithTime } from "@/utils/format";

export default (payload) => {
  let objectData = new FormData();

  if (payload.phone) objectData.append("data[phone]", payload.phone || "");
  if (payload.name) objectData.append("data[name]", payload.name || "");
  if (payload.email) objectData.append("data[email]", payload.email || "");

  objectData.append("data[object_id]", payload.object_id || "");
  objectData.append("data[price]", payload.price || "");

  if (payload.rooms_cnt) {
    objectData.append("data[rooms_cnt]", payload.rooms_cnt || "");
  }

  if (payload.date_begin instanceof Date) {
    objectData.append(
      "data[date_begin]",
      formatWithTime(payload.date_begin) || ""
    );
  } else {
    objectData.append("data[date_begin]", payload.date_begin || "");
  }
  if (payload.date_end instanceof Date) {
    objectData.append("data[date_end]", formatWithTime(payload.date_end) || "");
  } else {
    objectData.append("data[date_end]", payload.date_end || "");
  }

  if (payload.time_begin) {
    objectData.append("data[time_begin]", payload.time_begin);
  }
  if (payload.time_end) {
    objectData.append("data[time_end]", payload.time_end);
  }

  payload.guests.map((g, i) => {
    objectData.append(`data[guests][${i}][type]`, String(g.type) || "");
    if (g?.age >= 0) {
      objectData.append(`data[guests][${i}][age]`, String(g.age) || "");
    }
  });

  if (payload.hotel_booking) {
    objectData.append("data[hotel_booking]", payload.hotel_booking || "");
  }

  if (payload.is_pets) {
    objectData.append("data[is_pets]", payload.is_pets);
    objectData.append("data[pets]", payload.pets || "");
  }

  if (payload.target) {
    objectData.append("data[target]", payload.target);
  }

  return HTTP.post("json/orders/createOrderDraft ", objectData, {
    headers: {
      "api-version": "1.10", // Если указать версию, то будет минимум данных
    },
  });
};
