import { set, event } from "vue-gtag";

/**
 *
 * Отправка расширенной аналитики продукта
 *
 * @function
 *
 * @param {string} dateIn  - 'dimension2' - '2018-05-28' - Дата заезда если были выбраны
 * @param {string} dateOut - 'dimension3' - '2018-05-31' - Дата отъезда, ГГГГ-ММ-ДД
 * @param {string} name         - '1-комнатная квартира' - Название объекта
 * @param {string} id           - '123456'	             - ID объекта
 * @param {string} price        - '1000'                 - Базовая стоимость суток
 * @param {string} brand        - 'Москва'               - Город объекта
 * @param {string} category     - 'Квартира'             - Тип объекта
 * @param {string} variant      - '3 комнаты'            - Количество комнат
 * @param {string} position     - '27'	                 - Количество приоритетов
 * @param {string} quantity     - '27'	                 - Количество приоритетов
 *
 */
export default function (
  dateIn,
  dateOut,
  name,
  id,
  price,
  brand,
  category,
  variant,
  position,
  quantity,
  list
) {
  if (dateIn !== null && dateOut !== null) {
    set("dimension2", dateIn);
    set("dimension3", dateOut);
  }
  const items = [
    {
      name,
      id,
      price,
      brand,
      category,
      variant,
      position,
      quantity,
      list
    },
  ];

  event("add", {
    items,
  });
}
