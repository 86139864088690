import HTTP, { TOKEN } from "../http-common";
/**
 * Получение списка локаций
 *
 * @module getTermSuggestionsWithBoundingBox
 * @return {object} Promise
 */
let cancelSource = null;
export default (query) => {
  if (cancelSource) {
    cancelSource.cancel("Operation canceled by the user.");
  }
  // cancelSource = CancelToken.source();
  return HTTP.get(
    "rest/search/getTermSuggestionsWithBoundingBox?query=" + query,
    {
      headers: {
        "api-version": String(2), // Если указать версию, то будет минимум данных
        token: TOKEN,
      },
      // cancelToken: cancelSource.token
    }
  )
  // .then((v) => v.data.data.suggestions);
};
