import HTTP from "../http-common";

// Статус платежа

export default (formData) =>
  HTTP.post("json/payments/checkInvoiceforByInvoice", formData, {
    headers: {
      "api-version": "null",
    },
    cache: false,
  });
