import HTTP from "../http-common";

/**
 * Отправка сообщения по бронированию
 *
 * @module addOrderMessage
 * @return {object} Promise
 */

export default (payload) => {
  let objectData = new FormData();
  objectData.append("order_id", payload.order_id || "");
  objectData.append("text", payload.text || "");

  return HTTP.post("json/messages/addOrderMessage", objectData, {
    headers: {
      "api-version": "1.1", // Если указать версию, то будет минимум данных
    },
  });
};
