import { set } from "vue-gtag";
import getGuestStatus from "./getGuestStatus";
import getOwnerStatus from "./getOwnerStatus";
import _ from "lodash";

export default _.debounce(function (userData) {
  if (userData.user == undefined) {
    set("dimension8", "unauthorized");
  } else {
    set("userId", userData.user?.id || "");
    set("dimension8", "authorized");

    set("dimension9", userData.user_is_owner > 0 ? "owner" : "guest");
    set("dimension11", getGuestStatus(userData));

    if (userData.user_is_owner > 0) {
      set("dimension10", getOwnerStatus(userData));
    }
  }
}, 1000);
