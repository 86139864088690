<template>
  <transition name="fade">
    <div v-if="modelValue" class="step-guests-alert">{{ text }}</div>
  </transition>
</template>
<script>
export default {
  name: "GuestsAlert",
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
$borderColor: #d8d8d8;
$border: solid 1px $borderColor;

$widthAlert: 177px;

$bgColor: #ffffff;
$bgColorSelected: #f6f6f6;
.step-guests-alert {
  position: absolute;
  right: 0;
  bottom: 80%;
  left: 0;
  margin: 0 auto 10px auto;
  padding: 11px 15px;
  border: $border;
  width: $widthAlert;
  text-align: left;
  background-color: $bgColorSelected;
  z-index: 11;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    border: 20px solid transparent;
    border-top: 20px solid $borderColor;
  }
  &::before {
    left: calc($widthAlert / 2);
    border: 12px solid transparent;
    border-top: 9px solid $borderColor;
  }
  &::after {
    left: calc($widthAlert / 2 + 2px);
    border: 10px solid transparent;
    border-top: 8px solid $bgColorSelected;
  }
}
</style>
