import { useGtm } from '@gtm-support/vue-gtm';

/**
 *
 * Карточка объекта - Просмотр страницы объекта
 *
 * @module detail
 *
 */

export default function (id = null, price = null) {
  useGtm().trackEvent({
    event: "objectpage",
    objectId: id,
    objectBasecost: price
  });
}