import HTTP from "../http-common";

/**
 * Создание черновика отзыва на объект
 * @module addObjectReviewDraft
 * @param {Number} $orderId
 * @return {Array}
 */
export default (orderId) => {
  let formData = new FormData();
  formData.append("orderId", orderId || "");

  return HTTP.post("json/messages/addObjectReviewDraft", formData, {
    headers: {
      "api-version": String(null),
    },
    cache: false,
  });
};
