const checkRelevanceMetrikaFlags = async (request) => {
  if (!window.ymab?.inited) return request;
  await new Promise((resolve) => {
    ymab("metrika.8562148", "getFlags", function (flags) {
      for (const [key, value] of Object.entries(flags)) {
        if (key === "ab" && value.includes("1.tmpl-7824")) {
          delete request.relevance;
        }
      }
      resolve(flags);
    });
  });
  return request;
};
const checkInteractivePointsOnMapMetrikaFlags = async () => {
  let result = false;
  if (!window.ymab?.inited) {return result}
  await new Promise((resolve) => {
    ymab("metrika.8562148", "getFlags", function (flags) {
      for (const [key, value] of Object.entries(flags)) {
        if (key === "ab" && value.includes("1.tmpl-8771")) {
          result = true;
        }
      }
      resolve(flags);
    });
  });
  return result;
};

export { checkRelevanceMetrikaFlags, checkInteractivePointsOnMapMetrikaFlags };
export default {
  checkRelevanceMetrikaFlags,
};
