import { event } from "vue-gtag";
import { computed } from "vue";
import baseParams from "./baseParams";

function useSearchGAS7 (step = "search", commonParams = {}) {
  try {
    const params = computed(() => ({
      ...baseParams,
      step,
      ...commonParams,
    }));
    return () => event("search", params.value);
  } catch (e) {
    console.log(e)
  }
}

export default useSearchGAS7;
