import HTTP, { TOKEN } from "../http-common";

/**
 * Получение j, j,]trnt
 *
 * @module getObjectReviewsMore
 * @return {object} Promise
 */
export default (id, limit, offset, is_hotel, sort = '') =>
  HTTP.get("json/messages/getObjectReviewsMore", {
    params: {
      objectId: id,
      limit: limit,
      offset: offset,
      is_hotel: is_hotel || null,
      sort
    },
    headers: {
      "api-version": 1.8, // Если указать версию, то будет минимум данных
      token: TOKEN,
    },
    cache: false,
  });
