import getCommonParams from "@/utils/sutochnoMetrika/commonParams";
import dateTimeFormatter from "@/utils/dateTimeFormatter";
import { Metrika } from "@/components/api";
import removeEmptyProperties from "@/utils/removeEmptyProperties";

export default async (
  { objectID, userID, userPhone, guests, cost, page, dateAdd, yaClid, glClid, orderId } = {
    objectID: null,
    userID: null,
    userPhone: null,
    dateIn: "",
    dateOut: "",
    guests: "",
    cost: null,
    page: "",
    dateAdd: "",
    yaClid: null,
    glClid: null,
    orderId: null
  }
) => {
  let commonParams = await getCommonParams()
  let data = {
    ...commonParams,
    a: "book",
    o: objectID,
    ph: userPhone,
    dateAdd: dateTimeFormatter(dateAdd),
    g: guests,
    cost,
    page,
    yaClid,
    glClid,
    orderId
  };

  if (userID) {
    data["u"] = userID;
  }

  data = removeEmptyProperties(data);

  await Metrika(data);
};
