import getOtherReviews from "./getOtherReviews";
import getReviewsGoodList from "./getReviewsGoodList";

/**
 * Подтягивание отзывов с других проектов
 *
 * @module Reviews
 */
export default {
  getOtherReviews,
  getReviewsGoodList,
};
