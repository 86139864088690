function getCategoryForYM({
  isHotel = false,
  route = "",
  roomName = "",
  hotelType = "",
  objectType = "",
}) {
  let type1 = "";
  let type2 = "";

  const routesForHotel = ["detail", "lightbooking", "booking"];
  if (isHotel && routesForHotel.includes(route)) {
    type1 = "отельный объект";
    type2 = roomName;
  }
  if (isHotel && route === "hotel") {
    type1 = "отель";
    type2 = hotelType;
  }
  if (!isHotel) {
    type1 = "отдельный объект";
    type2 = objectType;
  }
  return `${type1}/${type2}`;
}

function normalizedYMData(
  {
    id = null,
    name = null,
    price = null,
    brand = null,
    variant = null,
    category = null,
    quantity = null,
  },
  type = "detail"
) {
  return {
    ecommerce: {
      currencyCode: "RUB",
      [type]: {
        products: [
          {
            id,
            name,
            price,
            brand,
            variant,
            category,
            quantity,
          },
        ],
      },
    },
  };
}

function pushDataToYM(data = null) {
  if (!data) {
    console.error("Необходим объект с данными");
    return null;
  }
  if (window != null) {
    window.dataLayer_metrika = window.dataLayer_metrika || [];
    window.dataLayer_metrika.push(data);
  }
}

export { getCategoryForYM, normalizedYMData, pushDataToYM };
