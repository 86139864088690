import HTTP from "../http-common";

/**
 * Добавления email пользователь.
 *
 * @module users
 *
 * @return {object} Promise
 *
 */
export default (formData) => {
  return HTTP.post("/json/users/addContact/", formData, {
    headers: {
      "api-version": "1"
    },
    cache: false,
  });
};
