<i18n>
{
  "ru":{
    "days": "{n} суток | {n} сутки | {n} суток"
  },
  "en":{
    "days": "{n} days | {n} day | {n} days"
  }
}
</i18n>

<template>
  <div
    class="day-wrapper medium-p5"
    :class="{
      'day-wrapper--current-day': day.currentDay,
      'day-wrapper--disabled': day.disabled,
      'day-wrapper--active-first': day.active === 'first',
      'day-wrapper--active-last': day.active === 'last',
      'day-wrapper--between-dates': day.betweenDates,
      'day-wrapper--gradient': day.dayNumber === 1,
    }"
  >
    <span
      class="day"
      :class="{ 'day-count': count }"
      @click.prevent="handleClick"
      :data-label="t('days', count)"
    >
      {{ props.day.dayNumber }}
    </span>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps({
  day: Object,
  count: Number,
});

const emits = defineEmits(["click"]);

function handleClick(event) {
  if (!props.day.disabled) {
    emits("click", { date: props.day.value, event });
  }
}
</script>

<style lang="scss" scoped>
.day-wrapper {
  position: relative;
  padding: 0 2px;
  display: flex;
  justify-content: center;
  text-decoration: none;
  &--active-first,
  &--active-last {
    .day {
      background: #1c1c1c;
      color: #ffffff;
      position: relative;
    }
  }

  &--active-last .day-count {
    &::before {
      content: "";
      width: 14px;
      height: 14px;
      position: absolute;
      left: 50%;
      top: -22px;
      transform: translateX(-50%) rotate(45deg);
      background: #4d4d4d;
    }

    &::after {
      content: attr(data-label);
      position: absolute;
      left: 50%;
      top: -39px;
      transform: translateX(-50%);
      padding: 6px 10px;
      background: #4d4d4d;
      color: #ffffff;
      border-radius: 3px;
      white-space: nowrap;
    }
  }
  &--current-day .day {
    border: 1px solid #e7e9f3;
  }

  &--disabled .day {
    color: #c0c3d3;
    background: none;
    border: none;
    cursor: default;
  }
  &--between-dates {
    background: #f1f3fb;
  }
  &--between-dates.day-wrapper--active-first {
    background: none;

    &::before {
      content: "";
      position: absolute;
      width: 50%;
      height: 100%;
      left: 50%;
      top: 0;
      background: #f1f3fb;
    }
  }
  &--between-dates.day-wrapper--active-last {
    position: relative;
    background: none;

    &::before {
      content: "";
      position: absolute;
      width: 50%;
      height: 100%;
      right: 50%;
      top: 0;
      background: #f1f3fb;
    }
  }
}
.day {
  position: relative;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 2;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  -webkit-user-select: none;
}

td:last-child > .day-wrapper {
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, #ffffff 0%, #f1f3fb00 100%);
  }
}
td:first-child > .day-wrapper,
.day-wrapper--gradient {
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #ffffff 0%, #f1f3fb00 100%);
  }
}
</style>
