import HTTP from "../http-common";

// Создание платежа

export default (formData) =>
  HTTP.post("json/payments/createpayment", formData, {
    headers: {
      "api-version": "null",
    },
    cache: false,
  });
