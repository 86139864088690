import { createGtm } from "@gtm-support/vue-gtm";
import { whiteLabelInfo } from "@sutochno/whitelabel";

const isServer = typeof window === "undefined";
export default defineNuxtPlugin(({ vueApp }) => {
  const WHITELABEL_NAME = whiteLabelInfo.name;
  vueApp.use(
    createGtm({
      id: WHITELABEL_NAME === "cian" ? ["GTM-KTKFS5C", "GTM-KC2KW5"] :
      WHITELABEL_NAME === "tutu" ? ["GTM-KTKFS5C", "GTM-PWZDTFZ"] :
      "GTM-KTKFS5C",
      enabled: !isServer, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      vueRouter: useRouter(), // Pass the router instance to automatically sync with router (optional)
    })
  )
})