// Получение id клиента
export default function () {
  return new Promise((resolve, reject) => {
    if (!window.ym) {
      reject();
    }
    window.ym(8562148, "getClientID", (id) => {
      if (id) {
        resolve(id);
      }
      reject();
    });
  });
}
