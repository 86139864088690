// /orders/getOrderDraft?draftCode=EK8RY5kwV&token=mJVruGMgV39AkYqSfFML8Q178283272
import HTTP from "../http-common";
import { formatWithTime } from "@/utils/format";

/**
 * Завершение бронирования
 *
 * @module bookingTo
 * @param {object} payload Данные для черновика
 * @param {string} payload.order_code Код черновика
 * @param {string} payload.no_matter Первый раз ли отправляется данны черновик
 * @param {string} payload.cost Сумма бронирования
 * @param {string} payload.precost Сумма предоплаты
 * @param {string} payload.utm ХЗ
 * @param {string} payload.device хз зачем
 * @return {object} Promise
 */

export default (payload) => {
  let objectData = new FormData();
  objectData.append("order_code", payload.order_code || "");
  objectData.append("no_matter", payload.no_matter || "0");
  // objectData.append("cost", payload.cost || "");
  // objectData.append("precost", payload.precost || "");

  return HTTP.post("json/orders/bookingTo", objectData, {
    headers: {
      "api-version": String(null), // Если указать версию, то будет минимум данных
    },
  });
};
