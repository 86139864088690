import HTTP, { TOKEN } from "../http-common";

/**
 * Получение j, j,]trnt
 *
 * @module toggleFavorite
 * @return {object} Promise
 */
export default (id) =>{
  localStorage.removeItem("json/objects/getFavoritesInLocations")
  return HTTP.get(`json/objects/toggle_favorite/id/${id}`, {
    headers: {
      "api-version": String(null), // Если указать версию, то будет минимум данных
      token: TOKEN,
    },
    cache: false,
  });
}