import HTTP from "../http-common";

/**
 * @description Получает курсы валют к выбранной валюте.
 *
 * @module Currencies/getRates
 * @return {object} Promise
 */
export default (id) =>
  HTTP.get(`json/currencies/getRates`, {
    params: { id },
    cache: false,
  });