<template>
  <div class="ui-switcher" @click="clickHandle">
    <input v-model="isChecked" :id="id" type="checkbox" />
    <div class="ui-switcher__dot"></div>
  </div>
</template>

<script>
export default {
  name: "UISwitcher",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Boolean,
      default: true,
    },
    id: String,
    isDisabled:{
      type: Boolean,
      default: false,
    }
  },
  computed: {
    isChecked: {
      get(){
        return this.modelValue;
      },
      set(val){
        this.$emit("update:modelValue", val)
      }
    },
  },
  methods: {
    clickHandle(){
      if (this.isDisabled) {
        return false;
      }
      this.isChecked = !this.isChecked;
    }
  },
};
</script>

<style lang="scss" scoped>
.ui-switcher {
  width: 42px;
  height: 24px;
  cursor: pointer;

  input{
    position: absolute;
    visibility: hidden;
  }
  input + .ui-switcher__dot{
    position: relative;
    padding: 2px;
    width: inherit;
    height: inherit;
    display: flex;
    background: #F1F3FB;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    &::after{
      content: "";
      display: block;
      height: 100%;
      aspect-ratio: 1/1;
      border-radius: 100%;
      background: #FFFFFF;
      box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.1), 0px 6px 10px rgba(20, 45, 136, 0.02);
    } 
  }

  input:checked + .ui-switcher__dot{
    background: #f51449;
    justify-content: flex-end;
  }
}
</style>
