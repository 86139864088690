import HTTP from "../http-common";

/**
 * Получить параметры для оставления отзыва
 * @module getOrderForLeavingReview
 * @param {Number} $objectId - ID объекта
 * @return {Array}
 */
export default (objectId) => {
  let formData = new FormData();
  formData.append("objectId", objectId || "");

  return HTTP.post("json/orders/getOrderForLeavingReview", formData, {
    headers: {
      "api-version": String(null),
    },
    cache: false,
  });
};
