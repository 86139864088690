import { set, event } from "vue-gtag";
import _ from "lodash";

/**
 *
 * Отправка расширенной аналитики продукта
 *
 * @module detail
 *
 * @function
 *
 * @param {string} id      - 'dimension1' - '123456'	   - ID объекта
 * @param {string} price   - 'dimension4' - '1000'       - Базовая стоимость суток
 * @param {string} rating  - 'dimension5' - '4'          - Оценка по отзывам
 * @param {string} dateIn  - 'dimension2' - '2018-05-28' - Дата заезда если были выбраны
 * @param {string} dateOut - 'dimension3' - '2018-05-31' - Дата отъезда, ГГГГ-ММ-ДД
 * @param {string} name         - '1-комнатная квартира' - Название объекта
 * @param {string} brand        - 'Москва'               - Город объекта
 * @param {string} category     - 'Квартира'             - Тип объекта
 * @param {string} variant      - '3 комнаты'            - Количество комнат
 * @param {string} position     - '27'	                 - Количество приоритетов
 *
 */


export default _.debounce(function (
  id,
  price,
  rating,
  dateIn,
  dateOut,
  name,
  brand,
  category,
  variant,
  position,
  list
) {
  set("currencyCode", "RUR");

  // set('dimension16', this.getGoogleAnalyticsParam('clientId'));

  set("dimension1", id);
  set("dimension4", price);
  set("dimension5", "" + Math.round(parseFloat(rating)));

  if (dateIn !== null && dateOut !== null) {
    set("dimension2", dateIn);
    set("dimension3", dateOut);
  }

  const items = [
    {
      name,
      id,
      price,
      brand,
      category,
      variant,
      position,
      list,
    },
  ];
  event("view_item", {
    items,
  });
},
500);
