import ImgProxy from "@sutochno/imgproxy";
import checkWebp from "@/utils/checkWebp.js";

let hasWebP = false;
checkWebp((isWebp) => hasWebP = isWebp);

ImgProxy.init(
  "1b147116e57d9e06df3696b21260169902fe201ccdb9715267999176ccbfaae6f2c2b75b78110d0287643d98cde143e7a3f98a46ec825a1ab7ea10af426c2436",
  "1e4483006c56f05f1b42af2f2b61765f64b8a074bf993922dd5b4bca78f49280683beb368308b7f76d522027b071a25e6d4b26138686b43e1faa4be4c9b674ea",
);

export default {
  newRating(value) {
    if (isNaN(value) && value === null) return "0,0";
    return (value * 2).toFixed(1).toLocaleString().replace(".", ",");
  },
  newRatingExternal(value) {
    if (isNaN(value) && value === null) return "0,0";
    return value.toFixed(1).toLocaleString().replace(".", ",");
  },
  numberWithSpaces(value) {
    if (isNaN(value)) return value;
    return Number(value)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, "\u00a0")
      .replace(".", ",")
      .replace(",00", "");
  },
  srcImgProxy(url, width = 0, height = 0) {
    return ImgProxy.generate({
      url,
      width,
      height,
      extension: hasWebP ? "webp" : "jpg",
      resizingType: "fill"
    });
  }
};
