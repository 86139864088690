import { ref, watch } from "vue"
import { useI18n } from 'vue-i18n';

export default () => { 
  const { t, locale } = useI18n({ useScope: "global" });
  const route = useRoute();

  const baseUrl = ref(`https://sutochno.ru`);
  const url = ref(`${baseUrl.value}${route.path}`);

  const linkAlternateHref = ref("https://sutochno.com");
  const linkAlternateHreflang = ref("en");

  const ogLocale = ref("ru_RU");

  const localeWatchFn = (val) => {
    if (typeof location === "object") {
      url.value = linkAlternateHref.value = location.href;

      if (val === "en") {
        linkAlternateHreflang.value = "ru";
  
        ogLocale.value = "en_GB";
      } else {
        linkAlternateHreflang.value = "en";
  
        ogLocale.value = "ru_RU";
      }
    }

    if (val === "en") {
      baseUrl.value = "https://sutochno.com";
      url.value = `${baseUrl.value}${route.path}`;

      linkAlternateHref.value = "https://sutochno.ru";
      linkAlternateHreflang.value = "ru";

      ogLocale.value = "en_GB";
    } else {
      baseUrl.value = "https://sutochno.ru";
      url.value = `${baseUrl.value}${route.path}`;

      linkAlternateHref.value = "https://sutochno.com";
      linkAlternateHreflang.value = "en";

      ogLocale.value = "ru_RU";
    }
  }
  localeWatchFn(locale.value);
  watch(locale, localeWatchFn)
  useHead({
    htmlAttrs: {
      lang: locale,
    },
    title: t("meta.index.title"),
    meta: [
      {
        name: "description",
        content: t("meta.index.description"),
      },
      { name: "keywords", content: t("meta.index.keywords") },
      { name: "robots", content: "noyaca" },
      { name: "yandex-verification", content:"743ccdf8a9967c69" },
      {
        property: 'og:site_name',
        content: 'Суточно.ру'
      },
      {
        property: 'og:title',
        content: t("meta.index.title")
      },
      {
        property: 'og:type',
        content: 'website'
      },
      {
        property: 'og:url',
        content: url
      },
      {
        property: 'og:description',
        content: t("meta.index.description")
      },
      {
        property: 'og:locale',
        content: 'ru_RU'
      },
      {
        property: 'format-detection',
        content: 'telephone=no'
      },
      {
        property: 'chrome',
        content: 'nointentdetection'
      }
    ],
    link: [
      {
        rel: "alternate",
        href: linkAlternateHref,
        hreflang: linkAlternateHreflang,
      },
      {
        rel: "canonical",
        href: url,
      },
    ],
    script: [
      {
        type: "application/ld+json",
        children: {
          "@context": "http://schema.org",
          "@type": "Organization",
          url: baseUrl,
          logo: "https://sutochno.ru/touch-icon-ipad-retina.png",
        },
      },
    ]
  })
}
