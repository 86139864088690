<i18n lang="json">
{
	"ru":{
		"guests": "{n} гостей | {n} гость | {n} гостя | {n} гостей",
    "pet": ", питомец "
	},
	"en":{
		"guests": "{n} guests | {n} guest | {n} guests | {n} guests",
    "pet": ", pet "
	}
}
</i18n>

<template>
  <div
    :data-variant-id="index"
    class="suggestions-list-elem"
    :class="{
      'suggestions-list-elem--active': index === selectedIndex,
    }"
    @click="$emit('applyVariants', variant, 'history')"
  >
    <span class="icon-app-clock-info suggestions-list-elem--history"></span>
    <div class="suggestions-list-elem--main">
      <div class="suggestions-list-elem--top">
        <span class="suggestions-list-elem--title"
          >{{ variant.title }}{{ variant.title_ext ? "," : "" }}</span
        >
        <span class="suggestions-list-elem--location">{{
          variant.title_ext
        }}</span>
        <!-- <span class="suggestions-list-elem--count">{{
					variant.count | format
				}}</span> -->
      </div>
      <div
        v-if="historyDate || historyGuests"
        class="suggestions-list-elem--bottom"
      >
        <span v-if="historyDate" class="suggestions-list-elem--date">{{
          historyDate
        }}</span>
        <span v-if="historyGuests" class="suggestions-list-elem--guests">
          {{ historyGuests }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  name: "HistoryListElement",
	filters: {
    format(value) {
      if (Number(value) == 0) return "";
      return Number.isInteger(value)
        ? Number(value).toLocaleString("ru-RU")
        : value;
    },
  },
  props: {
    selectedIndex: {
      type: Number,
    },
    index: {
      type: Number,
    },
    variant: {
      type: Object,
      require: true,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  computed: {
    historyDate() {
      if (this.checkDate(this.variant.date)) {
        const dateIn = new Date(this.variant.date.in);
        const dateOut = new Date(this.variant.date.out);

        if (dateIn.getMonth() == dateOut.getMonth()) {
          return `${dateIn.getDate()} — ${this.formatDate(dateOut)}`;
        }
        return `${this.formatDate(dateIn)} — ${this.formatDate(dateOut)}`;
      }
      return "";
    },
    historyGuests() {
      if (this.variant.maxGuests) {
        const pet = this.variant.guests?.pets?.value ? this.t("pet") : "";
        return `${this.t("guests", this.variant.maxGuests)}${pet}`;
      }
      return "";
    },
  },
  methods: {
    checkDate(date) {
      if (date?.in && date?.out) {
        const currentDate = new Date();
        const dateIn = new Date(date.in);
        const dateOut = new Date(date.out);
        currentDate.setHours("00");
        currentDate.setMinutes("00");
        currentDate.setSeconds("00");
        currentDate.setMilliseconds("00");

        if (currentDate > dateIn || currentDate > dateOut) {
          return false;
        }
        return true;
      }
      return false;
    },
    formatDate(date) {
      const dateArr = date.toLocaleString(this.$i18n.locale, { day: "numeric", month: "short" }).split(' ');
      return `${dateArr[0]} ${dateArr[1].length > 3 ? dateArr[1].slice(0, -1) : dateArr[1] }`;
    },
  },
};
</script>

<style lang="scss" scoped>
.suggestions-list-elem {
  color: var(--color-main-black);
  padding: 7px 16px !important;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  height: auto !important;

  &--active {
    background-color: #F1F3FB;
    border-radius: 10px;
  }

  &--main{
    overflow: hidden;
  }
  &--top{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &--top + &--bottom {
    margin-top: 2px;
  }
  &--title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 4px;
  }
  &--location {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &--count {
    margin-left: auto;
    padding-left: 10px;
    white-space: nowrap;
  }

  &--bottom {
    font-size: 12px;
    color: #5a5d63;
  }
  &--date + &--guests {
    &::before {
      content: ", ";
    }
  }


  &--history {
    margin-right: 12px;
    font-size: 14px;
    font-weight: 700;
  }
}
</style>
