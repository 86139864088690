import { useStore } from "vuex";

const isInit = (to, from) => to.fullPath === from.fullPath;
const isNewPage = (to, from) => to.name !== undefined && (isInit(to, from) || to.name != from.name);

export default defineNuxtRouteMiddleware((to, from) => {
  // TODO: [VUECLIENT-1927][Nuxt] оптимизировать и проверить так как жесткое легаси
  if (process.client && isNewPage(to, from)) {
    const store = useStore();

    if (!to.meta?.ignoreRouterHook) {
      if (
        to.query.id &&
        to.query.id !== "undefined" &&
        to.query.type &&
        to.query.type !== "undefined" &&
        store.state.search.location.id != to.query.id
      ) {
        // TODO: [VUECLIENT-1927][Nuxt] ошибка ajax в сср
        store.dispatch("search/setLocation", to.query);
      }
    }
    // Востанавливаем из URL параметры
    store.dispatch("search/setQuery", to);
    store.dispatch("filter/setQueryFilters", to);
  }
})