const endpoint = "https://api.ipify.org?format=json";

export default async function () {
  try {
    const userIP = sessionStorage.getItem("userIP");
    if (userIP) {
      return userIP;
    }
    const response = await fetch(endpoint);
    const { ip } = await response.json();
    if (!ip) {
      console.error("Не удалось запросить ip пользователя.");
    }
    sessionStorage.setItem("userIP", ip);
    return ip;
  } catch (e) {
    console.error(e);
  }
}
