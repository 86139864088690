import HTTP from "../http-common";

/**
 * Получение данных об организации, по реквизитам ИНН и БИК
 *
 * @module add_user_requisite
 * @param {object} payload Данные об организации, по реквизитам ИНН и БИК
 * @param {string} payload.orderCode Сгенерированный хэш, генерируется в ответе от updateOrderDraft и в query
 * @param {string} payload.params Параметры которые выводятся пользователю
 * @param {string} payload.inn ИНН организации
 * @param {string} payload.bik БИК организации
 * @return {object} Promise
 */

export default (payload) => {
  let objectData = new FormData();

  const formFormat = (arg, root = "") => {
    const answer = {};
    const treverse = (value, path = `${root}`) => {
      if (value instanceof Array) {
        value.forEach((v, key) => {
          const pKey = `${path}[${key}]`;
          return treverse(v, pKey);
        });
      } else if (value instanceof Object) {
        Object.entries(value).forEach(([key, v]) => {
          const pKey = `${path}[${key}]`;
          return treverse(v, pKey);
        });
      } else {
        answer[path] = value;
        return value;
      }
    };
    treverse(arg);
    return answer;
  };

  objectData.append("orderCode", payload.orderCode || "");

  payload.params = Object.entries(payload.params);
  for (let i = 0; i < payload.params.length; i++) {
    objectData.append(`params[${i}][name]`, payload.params[i][0] || "");
    objectData.append(`params[${i}][value]`, payload.params[i][1] || "");
  }

  payload.inn = Object.entries(formFormat(payload.inn, "dataInn"));
  for (let i = 0; i < payload.inn.length; i++) {
    objectData.append(`${payload.inn[i][0]}`, `${payload.inn[i][1]}` || "");
  }

  payload.bik = Object.entries(formFormat(payload.bik, "dataBik"));
  for (let i = 0; i < payload.bik.length; i++) {
    objectData.append(`${payload.bik[i][0]}`, `${payload.bik[i][1]}` || "");
  }

  return HTTP.post("json/users/add_user_requisite", objectData, {
    headers: {
      // "api-version":  // Если указать версию, то будет минимум данных
    },
  });
};
