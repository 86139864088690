import _ from "lodash";
import { Search, Objects, Services } from "@/components/api";

const state = () => ({
  limitObjectListUser: 20,
  offsetObjectListUser: 0,
  searchPr: null, // Массив id-шников и общей суммы ракет, может быть до 10000 объектов
  userObjects: [], // Массив объектов пользователя которые удовлетворяют условиям, к которым можно применять ракеты
  pageNum: null, // Текущая страница выдачи, это информационное поле, изменяется внутри NewSearch/index.vue
  showMenu: false, // Boolean, true - показать полное меню, false - зеленая кнопка поднять объявление
  objectPosition: null, // Int, выбранный объект с которым работаем в текущем времени
  virtualObject: null, // Object, тут находятся все настройки текущего объекта с которым мы работаем, от текщей позиции...до ракет, в сторе это информационное поле, изменяется в NewSearch/BarPosition.vue
  delayedPrObjects: null, // Array, массив отвечающий за вывод модалки об отложенном подключении/отключении ракет для объектов пользователя
  loadingButton: false,
  refreshSearchModal: false,
  startModal: false,
  perPage: 50, //кол-во объектов на странице поиска
  isModalConnection: false, // показ модалки  с подключением
  barLoading: false,
});

// getters
//TODO: add occupied getter based on from and to params
const getters = {
  getPositionObjects: (state) => {
    if (!state.userObjects.length || !state.searchPr) return null;
    let objectsPosition = {};

    for (let i = 0; i <= state.userObjects.length - 1; i++) {
      // Используем findIndex он самый легкий, даже легче чем использование for, видимо внутренняя оптимизация поиска по индексу
      const index = state.searchPr.findIndex((val) => val.i === state.userObjects[i].id);
      if (index >= 0) {
        objectsPosition[state.searchPr[index].i] = {
          ...state.searchPr[index],
          pos: index + 1,
          page: Math.ceil((index + 1) / state.perPage),
          pr: state.userObjects[i].pr,
          pr_virtual: state.userObjects[i].pr_virtual,
          start_pos: index + 1,
          new_page: Math.ceil((index + 1) / state.perPage),
        };
      }
    }

    // state.userObjects.forEach(el => {
    //   if(!objectsPosition[el.id]) {
    //     objectsPosition[el.id] = {
    //       i: el.id,
    //       p: el.pr + el.pr_virtual,
    //       pos: null,
    //       page: null,
    //       pr: el.pr,
    //       pr_virtual: el.pr_virtual,
    //       start_pos: null,
    //       new_page: null
    //     }
    //   }
    // })

    return objectsPosition;
  },
  getPositionCurrentObjects: (state) => {
    if (!state.userObjects.length || !state.searchPr || !state.pageNum) return null;
    const prevCnt = (state.pageNum - 1) * state.perPage;
    const nextCnt = state.searchPr.length - prevCnt;
    return _.range(
      1,
      nextCnt >= state.perPage ? state.perPage + 1 : nextCnt + 1
    ).map((c, i) => {
      const index = prevCnt + c;
      return {
        pos: index,
        i: state.searchPr[index - 1].i,
        p: state.searchPr[index - 1].p,
      };
    });
  },
};

// actions
const actions = {
  setSearchPr({ commit }, { query, cancelSource }) {
    return Search.searchObjectsPr(query, cancelSource).then((res) => {
      if (res.data && res.data.data) {
        commit("searchPrMutate", res.data.data);
      }
      return res;
    });
  },
  async setObjectListUser({ state, dispatch, commit }) {
    const result = await Objects.getObjectsUser({
      limit: state.limitObjectListUser,
      offset: state.offsetObjectListUser,
    })
      .then(async response => {
        if (response.data.success) {
          const objects = Object.values(response.data.data.objects);
          const userObjects = objects
            .filter((obj) => obj.status_title === "Опубликовано")
            .map(obj => ({
              id: obj.id,
              type: obj.type_title,
              title: obj.name_object,
              address: address(obj.address),
              city: obj.address.city,
              lat: obj.address.lat,
              lng: obj.address.lng,
              images: obj.images,
              pr: obj.pr.real >= 100 ? 1 : 0,
              pr_virtual: obj.pr.virtual >= 100 ? 1 : 0,
              date_actual: obj.date_actual,
              date_update: obj.date_update,
              date_add: obj.date_add,
              status_id: providerStatusId(obj.status_title),
              self_number_object: obj.self_number_object,
              hotel: obj.hotel,
            }));

          commit('setObjectListUser', userObjects);
          if (objects.length >= state.limitObjectListUser) {
            commit('setOffsetObjectListUser', state.offsetObjectListUser + state.limitObjectListUser);
            await dispatch('setObjectListUser');
          }
        }
        return response;
      })
      .catch(err => console.warn(`AJAX error: ${err}`));

    if (state.userObjects.length) {
      const objectIds = state.userObjects.map((val) => val.id);
      await dispatch("setDelayedPrByObjects", {
        objectIds,
      });
    }

    return result;
  },
  setEditServiceObject({ commit }, payload) {
    return Services.editServiceObject(payload).then((res) => {
      return res;
    });
  },
  setDelayedPrByObjects({ commit }, payload) {
    return Services.getDelayedPrByObjects(payload).then((res) => {
      if (res.data && res.data.data)
        commit("delayedPrByObjectsMutate", res.data.data);
      return res;
    });
  },
  actionUserObject({ commit, state }) {
    commit("updateUserObject", state.virtualObject);
    return true;
  },
};

// mutations
const mutations = {
  setOffsetObjectListUser: (state, payload) => state.offsetObjectListUser = payload,
  searchPrMutate(state, value) {
    state.searchPr = value.objects;
  },
  setObjectListUser(state, payload) {
    if (state.userObjects.length) {
      state.userObjects = _.uniqBy(state.userObjects.concat(payload), "id");
    } else {
      state.userObjects = payload;
    }
  },
  pageMutate(state, value) {
    state.pageNum = value;
  },
  showMenuMutate(state, value) {
    state.showMenu = value;
  },
  objectPositionMutate(state, value) {
    state.objectPosition = Number(value);
    localStorage.setItem("objectPosition", value);
  },
  updateUserObject(state, value) {
    const userObjectIndex = state.userObjects.findIndex(
      (el) => el.id === value.i
    );
    let userObject = state.userObjects[userObjectIndex];
    userObject = { ...userObject, pr: value.pr };

    state.userObjects[userObjectIndex] = userObject;

    state.searchPr[value.pos - 1] = {
      i: value.i,
      p: value.pr + value.pr_virtual,
    };
    if (value.pos !== value.start_pos)
      state.searchPr.splice(value.start_pos - 1, 1);
  },
  virtualObjectMutate(state, value) {
    if (value)
      state.virtualObject = {
        ...state.virtualObject,
        ...value,
      };
    else state.virtualObject = value;
  },
  delayedPrByObjectsMutate(state, value) {
    state.delayedPrObjects = value;
  },
  loadingButtonMutate(state, value) {
    state.loadingButton = value;
  },
  refreshSearchModalMutate(state, value) {
    state.refreshSearchModal = value;
  },
  startModalMutate(state, value) {
    state.startModal = value;
  },
  setIsModalConnection(state, value) {
    state.isModalConnection = value;
  },
  setBarLoading(state, value) {
    state.barLoading = value;
  },
};

export default {
  namespaced: true,
  namespace: "searchPr",
  state,
  getters,
  actions,
  mutations,
};

function address (address) {
  const { city, house, korp, street, street_type } = address;
  return `${city || ''
  }${ street ? `, ${street}` : ''
  }${ street_type ? ` ${street_type}` : ''
  }${ house ? `, ${house}` : ''
  }${ korp ? `, к. ${korp}` : ''}`;
}

function providerStatusId (title) {
  title = title.toString().toLowerCase();
  switch (title) {
    case 'не опубликовано':
      return 0;
    case 'опубликовано':
      return 1;
    case 'отправлено на модерацию':
      return 2;
    case 'на модерации':
      return 3;
    case 'отклонено':
      return 4;
    case 'в архиве':
      return 5; // 6
    default:
      return -1;
  }
}
