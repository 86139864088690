import HTTP from "../http-common";

// Метод который списывает средства, если на счету уже есть деньги
// передаем параметры: orderId, paymentMethod - указываем "payu_sutochno_ru"

export default (formData) =>
  HTTP.post("json/orders/payOrder", formData, {
    headers: {
      "api-version": "1.9",
    },
    cache: false,
  });
