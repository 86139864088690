import getHotel from "./getHotel";

/**
 * Работа с отелями
 *
 * @module Hotels
 */
export default {
  getHotel,
};
