const format = (date) => {
  const month = (date.getMonth() + 1).toString().padStart(2, 0),
    day = date.getDate().toString().padStart(2, 0),
    year = date.getFullYear();
  return year + "-" + month + "-" + day;
};

const formatWithTime = (date) => {
  const hours = date.getHours().toString().padStart(2, 0);
  const minutes = date.getMinutes().toString().padStart(2, 0);
  const seconds = date.getSeconds().toString().padStart(2, 0);
  return `${format(date)} ${hours}:${minutes}:${seconds}`;
};

const formatSafari = (date) => {
  let hoursOffset = (new Date().getTimezoneOffset() / 60) | 0;
  const minutesOffset = Math.abs(new Date().getTimezoneOffset() % 60)
    .toString()
    .padStart(2, "0");
  const signOffset = Math.sign(hoursOffset) < 0 ? "+" : "-";
  hoursOffset = Math.abs(hoursOffset).toString().padStart(2, "0");
  const formatOffset = `${signOffset}${hoursOffset}:${minutesOffset}`;
  return date.replace(/ /g, "T") + formatOffset;
};

const formatPrice = (price, currencyCode, locale) => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode || "RUB",
    minimumFractionDigits: 0,
  }).format(price);
};

const formatPriceCeil = (price, currencyCode, locale) => {
  const costCeil = Math.ceil(price * 100);
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode || "RUB",
    minimumFractionDigits: costCeil % 100 === 0 ? 0 : 2,
  }).format(costCeil / 100);
};

const formatPriceCeilForCurrency = (price, currencyCode, locale) => {
  const cost = currencyCode === 'RUB' ? Math.ceil(price) : price;
  return formatPriceCeil(cost, currencyCode, locale);
}

const formatPriceFloorForCurrency = (price, currencyCode, locale) => {
  const cost = currencyCode === 'RUB' ? Math.floor(price) : price;
  return formatPriceCeil(cost, currencyCode, locale);
}

const formatDatePrint = (date) => {
  if (!(date instanceof Date)) return "";
  const day = date.toLocaleString("ru-RU", {
    day: "numeric",
  });
  const month = date
    .toLocaleString("ru-RU", {
      month: "short",
    })
    .slice(0, 3);
  return `${day} ${month}`.toLocaleLowerCase();
};

const formatWeekdayPrint = (date) => {
  if (!(date instanceof Date)) return "";

  const weekday = date.toLocaleString("ru-RU", {
    weekday: "short",
  });
  return `${weekday}`.toLocaleLowerCase();
};

const formatTimePrint = (date) => {
  const hours = date.getHours().toString().padStart(2, 0);
  const minutes = date.getMinutes().toString().padStart(2, 0);
  return `${hours}:${minutes}`;
};

export default format;

export {
  format,
  formatWithTime,
  formatSafari,
  formatPrice,
  formatPriceCeil,
  formatPriceCeilForCurrency,
  formatPriceFloorForCurrency,
  formatDatePrint,
  formatWeekdayPrint,
  formatTimePrint,
};
