import { useGtm } from '@gtm-support/vue-gtm';

/**
 *
 * Карточка объекта
 *
 * @module detail
 *
 * @function
 *
 * @param {string} object - id объекта
 *
 */

export default function (object) {
  useGtm().trackEvent({
    event: "crto_productpage",
    crto: {
      email: "79054025255fb1a26e4bc422aef54eb4",
      products: [`${object}`],
    },
  });
}
