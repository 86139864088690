import axios from "axios";
import {
  cacheAdapterEnhancer,
  throttleAdapterEnhancer,
  retryAdapterEnhancer,
} from "axios-extensions";
import { isWhiteLabel, whiteLabelInfo } from "@sutochno/whitelabel";

// Common Base Instance

// проверяем на VK приложение для замены токена
let isVkApp = false;
if (typeof location === "object" && location.search !== "") {
  isVkApp = location.search.includes("vk_app_id");
}

let TOKEN = "Hy6U3z61fflbgT2yJ/VdlQ2719"

// Меняем токен запросов в зависмости какое приложение работает,
// нужно чтобы точно знать где произошли проблемы
if (process.env.NODE_ENV === "production") {
  if (isVkApp) {
    TOKEN = "c8DbUrMUVJDHEgBbLxkpEg289958783"
  } else if (isWhiteLabel) {
    switch (whiteLabelInfo.name) {
      case "tutu":
        TOKEN = "9BZ9w4GprgpO-DNyyoa2mQ397941495";
        break;
      case "domclick":
        TOKEN = "fXikN4nLY01K56UpTiGvjA421551649";
        break;
      case "etagi":
        TOKEN = "6ExaxZ70byCCrjYm27TBrA915461206";
        break;
      case "s7":
        TOKEN = "Pep1-mb4gqIIdsPC9fd_XA624122727";
        break;
      case "utair":
        TOKEN = "rQFj4RH9lRuKBis-Ar-wbw682906713"
        break;
      case "aeroflot":
        TOKEN = "G4nWzQ1LAPmaQmBd_aL8pA687565881"
        break;
      case "privetmir":
        TOKEN = "sYk4IFfrYB8AZSaA96-72g652444105";
        break;
      case "tinkoff":
        TOKEN = "7jk0P2paWOcqO77WPglHdw779792327";
        break;
      case "grandtrain":
        TOKEN = "NuPjSBZ7gNrw1F26F_7kMA401610175";
        break;
      case "votpusk":
        TOKEN = "P32rZhCRifgkJHNyvuz3RQ417148855";
        break;
      case "uralairlines":
        TOKEN = "yRUmC0H-sUx3i4poDIMCvw775345746";
        break;
      case "leveltravel":
        // TODO - необходим токен
        TOKEN = "RsT1nvW_T2jP_m_jGkF2lw779785359";
        break;
      case "spasibo":
        TOKEN = "s6bJ0cCCHlonYPYJ1SS6tw856479232";
        break;
      case "pobeda":
        TOKEN = "4cug2KZyvdvlPwZtJWYWnw864409025";
        break;
      case "putevka":
        TOKEN = "PPboDbRUNb89BU6mm6hofg896295186";
        break;
      case "licard":
        TOKEN = "sCXHenE6an98bATUR0DN9w926342056";
        break;
      case "halva":
        TOKEN = "6rFv7l_RMMcJ8oGbrycSqQ1042277554";
        break;
      default:
        TOKEN = "QeVopIr1QKyCIBK53/5gZQ294283465";
    }
  }
}

export default axios.create({
  adapter: throttleAdapterEnhancer(
    cacheAdapterEnhancer(retryAdapterEnhancer(axios.defaults.adapter))
  ),
  baseURL: "/api",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "api-version": "1.7",
    platform: "js",
    token: TOKEN,
  },
});

export { TOKEN };
