import { whiteLabelInfo } from "@sutochno/whitelabel";

/**
 *
 * Отправка данных в Яндекс метрику, при отправке формы
 *
 * @function
 *
 * Все параметры по default
 *
 */
export default function () {
  if (window.ym) {
    const WHITELABEL_NAME = whiteLabelInfo.name;

    if (WHITELABEL_NAME === 'tutu') {
      window.ym(88488615, "reachGoal", "bookingstep1com");
    }
    return window.ym(8562148, "reachGoal", "bookingstep1com");
  }
}
