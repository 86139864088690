import getOffersList from "./getOffersList";
import getOffersListToUser from "./getOffersListToUser";
import setBookingByOffer from "./setBookingByOffer";

/**
 * Работа с пользовательскими данными
 *
 * @module Users
 */
export default {
  getOffersList,
  setBookingByOffer,
  getOffersListToUser
};
