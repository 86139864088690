import { Hotels, Objects, HTTP } from "@/components/api/";
import _ from "lodash";

// initial state
const state = () => ({
  hotelData: null,
  hotelObject: null,
  maxRooms: {},
  loadBooking: false,
  rating: {
    rating: "0",
    reviews: 0,
  },
  isModal: false,
  check: {
    // даты для модального окна в мобильном виде
    in: null,
    out: null,
  },
  guests: {
    // гости для модального окна в мобильном виде
    adults: 1,
    childrens: [],
  },
  titlePageHotel: null,
  refForScroll: '',
  objectsPriceDefault: null,
});

// getters
const getters = {
  hotel: (state) => {
    if (!state.hotelData || !state.hotelData.hotel) return null;

    const {
      id,
      type_title,
      properties,
      media,
      location,
      external_reviews,
      metro,
      meal,
      owner,
    } = state.hotelData.hotel;

    return {
      id,
      typeTitle: type_title,
      title: properties.title.properties[0].value,
      media: media,
      location: location,
      external_reviews: external_reviews,
      properties: properties,
      metro: metro,
      meal: meal,
      owner: owner,
    };
  },
  hotelStars: (state, getters) => {
    if(!getters['hotel']) {
      return null;
    }
    const { properties } = getters['hotel']?.properties?.info || {};
    if(!properties || !properties.length) {
      return null;
    }
    const starsObject = properties.find((prop) => prop.name === "stars");
    if(!starsObject || !starsObject?.value) {
      return null;
    }
    const { value } = starsObject;
    return Number(/\d+/.exec(value));
  },
  starCert: (state, getters) => {
    if(!getters['hotel']) {
      return null;
    }
    const { properties } = getters['hotel']?.properties?.info || {};
    if(!properties || !properties.length) {
      return null;
    }

    const result = {}

    properties.forEach((val) => {
      if(val?.name === 'cert_num') {
        result['certNum'] = val.value;
      }
      if(val?.name === 'cert_date') {
        result['certDate'] = val.value;
        result['isActive'] = new Date(val?.value) >= new Date();
      }
    })

    if(!Object.values(result).length) {
      return null;
    }

    return result;
  },
  isCert: (state, getters) => {
    if(!getters['starCert']) {
      return null;
    }
    const { certDate, certNum, isActive } = getters['starCert'];
    return (certDate && certNum && isActive);
  },
  isBronevik: (state, getters, rootState, rootGetters) => {
    const moder = rootState?.user?.userData?.user_is_moder;
    const userNoShow = rootState?.user?.userData?.user?.id;
    if (
      Number(state.hotelData.hotel.owner.id) === 4784337 &&
      !(userNoShow == "4784377")
    )
      return true;
    else return false;
  },
  hotelReviews: (state) => {
    if (!state.hotelData || !state.hotelData.reviews) return null;
    return state.hotelData.reviews;
  },
  hotelObjectsId: (state) => {
    if (!state.hotelData || !state.hotelData.objects) return null;
    const uniqId = _.uniq(state.hotelData.objects.map((o) => o.id));
    return uniqId;
  },
  hotelObjectsCategory: (state) => {
    if (!state.hotelData || !state.hotelData.objects) return null;
    const uniqCategory = _.uniq(
      state.hotelData.objects.map((category) => category.hotel_category.type)
    );
    //Object.keys(state.hotelData.objects.reduce((acc, category) => (acc[category.hotel_category.type] = true, acc) , {}));
    return uniqCategory.map((category, index) => ({
      categoryIndex: index,
      category,
      data: state.hotelData.objects
        .filter((o) => o.hotel_category.type == category)
        .map((o) => {
          const {
            id,
            title,
            type_title,
            media,
            hotel_category,
            location,
            properties,
            prices,
          } = o;
          return {
            id,
            title,
            type_title,
            hotel_category,
            media,
            location,
            properties,
            prices,
          };
        }),
    }));
  },
  hotelObjectPrice: (state) => {
    return state.hotelObject;
  },
  loadBooking: (state) => {
    return state.loadBooking;
  },
  getRating: (state) => {
    return state.rating;
  },
  guestsNum: (state) => {
    return state.guests.adults + state.guests.childrens.length;
  },
  getTitlePageHotel: (state) => {
    if (!state.titlePageHotel) return null;
    return state.titlePageHotel;
  },
};

// actions
const actions = {
  setHotelData({ commit }, hotelId) {
    return Hotels.getHotel(hotelId).then((response) => {
      if (response.data && response.data.data)
        commit("setHotelData", response.data.data);
      return response;
    });
  },
  setHotelObjectData(
    { commit },
    {
      guests = null,
      no_time = null,
      time_begin = null,
      time_end = null,
      date_begin = null,
      date_end = null,
      objects = [],
      max_rooms = {},
      currency_id = 1,
    }
  ) {
    commit("setLoadBooking", true);
    Objects.getPricesAndAvailabilities({
      guests,
      no_time,
      time_begin,
      time_end,
      date_begin,
      date_end,
      objects,
      max_rooms,
      currency_id,
    })
      .then((response) => {
        let priceObjects = response.data?.data?.objects;
        if (priceObjects?.length) {
          priceObjects = priceObjects.reduce(
            (acc, item) => ({ ...acc, [item.id]: item }),
            {}
          );
          commit("setHotelObjectData", priceObjects);
        }
        commit("setLoadBooking", false);
      })
      .catch((error) => {
        console.log("Ajax error: Objects.getPricesAndAvailabilities, ", error);
      });
  },
  resetHotelObjectData({ commit }) {
    commit("setLoadBooking", true);
    commit("setHotelObjectData", null);
    commit("setLoadBooking", false);
  },
  setRating({ commit }, { rating = null, reviews = 0 }) {
    commit("setRating", { rating, reviews });
  },
  setTitle({ commit }, titlePage) {
    commit("setTitle", titlePage);
  },
  getDefaultPriceHotelObject({ commit }, payload) {
    HTTP.get(`/json/search/searchObjectsByLocation`, {
      headers: {
        "api-version": "1.10",
      },
      params: {
        ids: payload,
      },
      cache: false,
    }).then((response) => {
      const objects = response?.data?.data?.objects || [];
      const objectsPrice = objects.reduce(
        (acc, item) => ((acc[item.id] = item?.price?.per_night), acc),
        {}
      );
      commit("setObjectsPriceDefault", objectsPrice);
      commit("setPriceObject", objectsPrice);
    });
  },
};

// mutations
const mutations = {
  setRating(state, value) {
    state.rating = value;
  },
  setHotelData(state, value) {
    state.hotelData = value;
  },
  setHotelObjectData(state, value) {
    state.hotelObject = value;
  },
  setMaxRooms(state, { objectId = 1, rooms = 1 }) {
    state.maxRooms[objectId] = rooms;
  },
  setLoadBooking(state, value) {
    state.loadBooking = value;
  },
  setModal(state, value) {
    state.isModal = value;
  },
  setCheck(state, value) {
    if (value) {
      state.check.in = value.in;
      state.check.out = value.out;
    } else {
      state.check.in = null;
      state.check.out = null;
    }
  },
  setGuests(state, value) {
    state.guests = value;
  },
  setTitle(state, value) {
    state.titlePageHotel = value;
  },
  setRefForScroll(state, value) {
    state.refForScroll = value
  },
  setPriceObject(state, value) {
    state.hotelData.objects.forEach((item) => {
      item.prices[0].value = value[item.id];
    });
  },
  setObjectsPriceDefault(state, value) {
    state.objectsPriceDefault = value;
  },
};

export default {
  namespaced: true,
  namespace: "hotel",
  state,
  getters,
  actions,
  mutations,
};
