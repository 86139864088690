import HTTP, { TOKEN } from "../http-common";

/**
 * Получение отзывов для главной стр.
 *
 * @module getReviewsGoodList
 * @return {object} Promise
 */
export default () =>
  HTTP.get("json/messages/getIndexReviews", {
    params: {
      perPage: 12,
    },
    headers: {
      "api-version": "1.10", // Если указать версию, то будет минимум данных
      token: TOKEN,
    },
    cache: false,
  });
