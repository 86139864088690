import HTTP from "../http-common";

export default (id) =>
  HTTP.get("json/locations/getDistanceSights", {
    params: { object_id: id },
    headers: {
      "api-version": "2.0",
    },
    cache: false,
  });
