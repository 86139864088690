import HTTP from "../http-common";

/**
 * Получение свободного запроса
 *
 * @module getOffersListToUser
 * @return {order} Promise
 */

export default (hotId) => {
  return HTTP.post(
    "json/offers/getOffersListToUser",
    { hotId },
    {
      headers: {
        "api-version": 1.8, // Если указать версию, то будет минимум данных
        "Content-Type": "application/json",
      },
      cache: false,
    }
  );
};
