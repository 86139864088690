import { useGtm } from '@gtm-support/vue-gtm';
/**
 *
 * Окончательная отправка заявки на странице черновика
 *
 */

export default function (objectID, UserEmail) {
  useGtm().trackEvent({
    event: "step2",
    objectID,
    UserEmail
  });
}
