import _ from "lodash";
import {
  HTTP,
  Locations,
  Messages,
  Users,
  Search,
} from "@/components/api/index.js";
import VueCookies from "vue-cookies";
import { Objects } from "@/components/api/";
import getHost from "@/utils/getHost";
// import router from "@/route";
//window doesnt work with ssr/

// TODO: [VUECLIENT-1927][Nuxt] сравнить с plugins/01.legacyMain.js
const isProd = process.env.NODE_ENV === "production";
const parseDomain = () => {
  if (process.server) {
    return `.${
      process.env.VUE_APP_API_ADDRESS.match(
        /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/
      )[1]
    }`;
  } else {
    return `.${window.location.hostname.match(/(\w+\.?\w+)$/)[0]}`;
  }
};

const DOMAIN = isProd
  ? parseDomain()
  : parseDomain().replace(".localhost", "localhost");
// const IS_WHITE_LABEL =
//   process.env &&
//   process.env.VUE_APP_WL_NAME != null &&
//   process.env.VUE_APP_WL_NAME != "null";

function removeTZ(date) {
  date = new Date(date);
  let month = (date.getMonth() + 1).toString().padStart(2, 0),
    day = date.getDate().toString().padStart(2, 0),
    year = date.getFullYear();
  return year + "-" + month + "-" + day;
}

function saveGuestsLS(guests) {
  if(!guests?.adults) {
    return false;
  }
  const guestsData = JSON.parse(JSON.stringify(guests));
  guestsData.childrens = guestsData.childrens?.filter((age) => age !== null);
  // куки для бека
  const EXPIRED = "10y";
  const PAYLOAD = {
    guests: guestsData,
  };
  VueCookies.set("select_guests", PAYLOAD, EXPIRED, null, getHost().domainDot);

  // бывает что в куках не хватает места, используем LS
  localStorage.setItem("select_guests", JSON.stringify(guestsData))
}

const state = () => ({
  isLoadFilter: false,
  filters: {},
  filtersTotalFound: null,
  filtersView: "desktop", // del используется только в FilterMain
  isVisibleFiltersMobile: false,
  isSupportFilters: false,
  guests: {
    adults: 2,
    childrens: [],
    pets: {
      value: false,
      description: "",
    },
  },
  childrenWithoutAgeError: false,
  checkIn: null,
  checkOut: null,
  sortings: null,
  cheap_and_fast: null,
  view: "list",
  positionOfObjectsInTheSearch: {},
  searchResultsObjectsIds: null,
  totalFound: 0,
  totalFoundAll: 0,
  // Map settings
  map: {
    lat: null,
    lng: null,
    zoom: null,
    title: null,
    bound: null,
  },
  place: {
    id: null,
    lat: null,
    lng: null,
    title: null,
  },
  // Location
  //set default location to Russia
  location: {
    id: 1,
    sort: 2,
    visible: 1,
    code: "russia",
    subdomain: null,
    title: "Россия",
    title_w: "России",
    type: null,
    has_sea: false,
  },
  // обновляется только в setQuery
  isDefaultLocationRender: false,
  isLoadingLocation: false,
  isInitLocation: false,
  term: "",
  selectedMapPointId: null,
  selectedMapPointsIds: [],
  hoverMapPointId: null,
  bound: {
    //координаты карты
    SW: {
      lat: 55.142221,
      lng: 36.803259,
    },
    NE: {
      lat: 56.021281,
      lng: 37.96779,
    },
  },
  boundFirstLoad: {
    //координаты карты
    SW: {
      lat: 55.142221,
      lng: 36.803259,
    },
    NE: {
      lat: 56.021281,
      lng: 37.96779,
    },
  },
  cardVisible: false,
  switchMap: false,
  objectRatings: {},
  hotelRatings: {},
  usersSearchParams: [],
  avgRatings: [],
  flag: {},
  listSuggestions: {
    isLoading: false,
    isError: false,
  },
  isPosition: false,
  objectScroll: null,
  dataKrym: {
    // нужно было где-то хрнаить одно значение, для WL
    term: "Крым",
    bbox: {
      SW: {
        lat: 44.206576,
        lng: 32.299905,
      },
      NE: {
        lat: 46.229215,
        lng: 36.752477,
      },
    },
  },
  objectsBronevik: [],
  loadBronevikPrice: false,
  pricesBronevik: null,
  flowPrice: [],
  facets: [],
  isSearchProcessActive: false,
  selectedVariant: null,
  condiateForHistory: null,
  variantsHistory: [],
  dateFromHistorylist: {},
  analiticParamForSearchWidList: null,
  statusActivePage: "loading",
  locationForFilter: {
    id: 1,
    sort: 2,
    visible: 1,
    code: "russia",
    subdomain: null,
    title: "Россия",
    title_w: "России",
    type: null,
    has_sea: false,
  },
  placePreference: null,
  listType: "",
  isLazyLoading: false,
  countLazyObjects: 0,
  isWidgetFocused: false,
  isCalendarFocused: false,
  isGuestFocused: false,
  searchInMap: false,
  showSearchModal: false,
  objectsOnMap: {},
  noAuthToast: false,
});

// getters
//TODO: add occupied getter based on from and to params
const getters = {
  getIDsOfSelectedVariant: (state) => {
    if (
      state?.selectedVariant?.data &&
      state?.selectedVariant?.data?.type === "hotel"
    ) {
      return state.selectedVariant.data.ids.join();
    }
  },
  getTypeOfSelectedVariant: (state) => {
    return state?.selectedVariant?.data?.type;
  },
  getUsersSearchParams: (state) => {
    if (state.usersSearchParams?.length <= 0) return [];
    return state.usersSearchParams;
  },

  getAvgRatings: (state) => {
    if (state.avgRatings?.length <= 0) return [];
    return state.avgRatings;
  },

  //Check term for is empty
  termEmpty: (state) => {
    if (state.term == "") {
      return true;
    } else {
      return false;
    }
  },

  getObjectPositionByID: (state) => (id) => {
    return state.positionOfObjectsInTheSearch[id] || null;
  },

  getArrayObjectsPosition: (state) => {
    if (!state.positionOfObjectsInTheSearch) return null;
    return Object.values(state.positionOfObjectsInTheSearch).sort(
      (a, b) => a - b
    );
  },

  getCheckIn: (state) => {
    if (!state.checkIn) return null;
    return state.checkIn;
  },

  //days betwee checkIn and checkOut
  days: (state) => {
    if (state.checkIn && state.checkOut) {
      return parseInt((state.checkOut - state.checkIn) / (1000 * 60 * 60 * 24));
    } else {
      return 1;
    }
  },

  maxGuests: (state) => {
    const EXPIRED = getters.occupied
      ? new Date(Math.min(new Date(state.checkIn), Date.now() + 864000000))
      : "1d";
    const PAYLOAD = {
      date_begin: removeTZ(state.checkIn),
      date_end: removeTZ(state.checkOut),
    };

    // if (!Vue.prototype.$isServer) {
    //   cookies.set("calendar_dates", PAYLOAD, EXPIRED, null, DOMAIN);
    // }
    return state.guests.adults + state.guests.childrens.length;
  },
  maxGuestsFreeBabies: (state) => {
    return state.guests.adults + state.guests.childrens.filter(child => child >= 2).length;
  },
  isChildrenWithoutAgeError: (state) => {
    return state.guests.childrens.includes(null);
  },
  occupied: (state) => {
    if (state.checkIn instanceof Date && state.checkOut instanceof Date) {
      let format = (date) => {
        let month = (date.getMonth() + 1).toString().padStart(2, 0),
          day = date.getDate().toString().padStart(2, 0),
          year = date.getFullYear();
        return year + "-" + month + "-" + day;
      };

      return (
        //get data in format YYYY-MM-DD
        format(state.checkIn) + ";" + format(state.checkOut)
      );
    } else {
      return null;
    }
  },
  // getOccupied: state => {
  //   if (state.occupied) {
  //     let [checkIn, checkOut] = state.occupied.match(/(\d{4}-\d{2}-\d{2})/gm);
  //     if (checkIn !== undefined && checkOut !== undefined) {
  //       checkIn = new Date(checkIn);
  //       checkOut = new Date(checkOut);
  //       return {
  //         checkIn,
  //         checkOut,
  //         days: (checkOut - checkIn) / 86400000
  //       };
  //     }
  //   }
  //   return {
  //     checkIn: null,
  //     checkOut: null,
  //     days: 1
  //   };
  // },
  getQuery: (state, getters, rootState, rootGetters) => {
    // TODO: [VUECLIENT-1927][Nuxt] проверить работоспособность
    const bound = {
      "SW.lat": state.bound?.SW.lat,
      "SW.lng": state.bound?.SW.lng,
      "NE.lat": state.bound?.NE.lat,
      "NE.lng": state.bound?.NE.lng,
    };

    let query = {
      ...rootGetters["filter/getFilterRequest"],
      guests_adults: state.guests.adults,
      guests_childrens: state.guests.childrens,
      pets: state.guests.pets?.value,
      occupied: getters.occupied,
      sortings: state.sortings,
      cheap_and_fast: state.cheap_and_fast,
      id: state.location.id,
      type: state.location.type,
      lat: state.map.lat,
      lng: state.map.lng,
      zoom: state.map.zoom,
      // Метка для карты
      point:
        state.place.lat &&
        state.place.lng &&
        `${state.place.lat},${state.place.lng}`,
      term: state.term || state.place.title,
      ...bound,
      objectScroll: state.objectScroll,
    };

    // Собираем массивы в кучку
    for (const [name, value] of Object.entries(query)) {
      if (Array.isArray(value)) {
        if (
          name === "metro" &&
          value.length === 1 &&
          typeof value[0] === "number"
        ) {
          query[name] = [null, ...value].join(",");
        } else if (name === "guests_childrens") {
          query[name] = Array.isArray(value) ? value.join(",") : value;
        } else {
          query[name] =
            Array.isArray(value) && value?.length !== 0
              ? [...new Set(value)].join(",")
              : value;
        }
      }
    }
    // Исключаем пустые параметры
    let pickQuery = null;

    // Проверка наличия кастомных get параметров
    // const routerQuery = router.currentRoute.value.query;
    // TODO: [VUECLIENT-1927][Nuxt] реализовать для ssr
    let queryUrl = {};
    if (typeof window === "undefined") {
      const route = useRoute();
      queryUrl = route.query;
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const keysUrl = urlParams.keys();
      for (const key of keysUrl) {
        queryUrl[key] = urlParams.get(key);
      }
    }

    const routerQuery = JSON.parse(JSON.stringify(queryUrl));
    const queryKeys = Object.keys(query);
    for (const [name, value] of Object.entries(routerQuery)) {
      if ((queryKeys.includes(name) && !query[name]) || !value)
        delete routerQuery[name];
    }

    if (state.isPosition) {
      pickQuery = {
        ...routerQuery,
        isPosition: state.isPosition,
        objectPosition: rootState.searchPr.objectPosition,
        ..._.pickBy(query),
      };
    } else {
      pickQuery = {
        ...routerQuery,
        ..._.pickBy(query),
      };
    }

    // https://jira.webpower.ru/browse/VUECLIENT-1967 - после фикса кастомных гет-параметров, параметры урла перетирают
    // некоторые пар-ры фильтров. Пробуем чекать, что был переход и только тогда применяем параметры урла.
    // TODO: [VUECLIENT-1927][Nuxt] добавить реализацию ssr
    if (typeof document !== "undefined") {
      const origin = document.location.origin;
      const referrer = document.referrer
        ? new URL(document.referrer).origin
        : "";
      if (referrer && referrer !== origin) {
        pickQuery = { ...routerQuery, ...pickQuery };
      }
    }

    const result = _.omit(pickQuery, [
      "1_stars",
      "2_stars",
      "3_stars",
      "4_stars",
      "5_stars",
      "9_review_score",
      "8_review_score",
      "7_review_score",
      "6_review_score",
    ]);

    return { query: result, hash: state.view != "list" && state.view };
  },
  //гости для отправки запроса createOrderDraft (в деталке используется setBookingData)
  guestsSend: (state) => {
    let countChild = 0;
    return _.range(0, state.guests.adults + state.guests.childrens.length).map(
      (g, i) => {
        if (i <= state.guests.adults - 1) {
          return (g = { type: 0 });
        } else {
          g = { type: 3, age: state.guests.childrens[countChild] };
          countChild += 1;
          return g;
        }
      }
    );
  },
  guestsCount: (state) => {
    const COUNT = state.guests.adults + state.guests.childrens.length;
    return COUNT;
  },
  isDragMapMobile: (state) => {
    if (
      state.bound.SW.lat === state.boundFirstLoad.SW.lat &&
      state.bound.SW.lng === state.boundFirstLoad.SW.lng &&
      state.bound.NE.lat === state.boundFirstLoad.NE.lat &&
      state.bound.NE.lng === state.boundFirstLoad.NE.lng
    ) {
      return false;
    } else {
      return true;
    }
  },
  commonParamsForMetrika: (state, getters, rootState, rootGetters) => {
    const {
      searchResultsObjectsIds,
      guests,
      checkIn,
      checkOut,
      location,
      term,
    } = state || {};
    return {
      objectIdsArray:
        searchResultsObjectsIds ||
        (rootGetters["detail/getObjectData"]?.id
          ? [rootGetters["detail/getObjectData"]?.id]
          : []),
      dateIn: checkIn,
      dateOut: checkOut,
      filters: rootGetters["filter/modifiedFiltersForQueries"],
      guests,
      city: location?.title,
      cityID: location?.id,
      term,
      userID: rootState?.user?.auth?.data?.id,
      cost: rootGetters["detail/getObjectPrice"]?.price,
    };
  },
  sumOfDays: (state) => {
    return Math.round(Math.abs(state.checkIn - state.checkOut) / 86400000);
  },
  getNoAuthToast: (state) => {
    return state.noAuthToast;
  }
};

// actions
const actions = {
  async setPlacePreference({ state, commit }, location = null) {
    const stateLocation = location || state.location;
    // TODO - нет необходимости по данным локациям делать данный запрос
    // https://jira.webpower.ru/browse/VUECLIENT-2002
    if ([397366, 397367].includes(stateLocation.id)) {
      return Promise.resolve(false);
    }
    let places = JSON.parse(localStorage.getItem("place_" + stateLocation.id));

    if (places && Date.now() / 1000 - places.timestamp < 84600) {
      places = Object.values(places.data.places);
      commit("setPlacePreference", places);
      return false;
    }

    await HTTP.get("json/locations/getCityPlaces", {
      params: { cityId: stateLocation.id },
    })
      .then((response) => {
        places = response.data;
        localStorage.setItem(
          "place_" + stateLocation.id,
          JSON.stringify(places)
        );
        places = Object.values(places.data.places);
        commit("setPlacePreference", places);
      })
      .catch((error) => {
        console.log("Ajax error: ", error);
      });
  },
  saveVariantInStorage({ commit, state, getters }) {
    if (state.condiateForHistory) {
      const date = {
        in: state.checkIn ? state.checkIn.toISOString() : "",
        out: state.checkOut ? state.checkOut.toISOString() : "",
      };

      const condiateForHistory = { ...state.condiateForHistory };

      condiateForHistory.date = date;
      condiateForHistory.maxGuests = getters.maxGuests
        ? getters.maxGuests
        : null;
      condiateForHistory.guests = state.guests;
      if (
        !state.variantsHistory.find(
          (city) =>
            city?.location?.id === condiateForHistory?.location?.id &&
            city?.date?.in === condiateForHistory?.date?.in &&
            city?.date?.out === condiateForHistory?.date?.out &&
            city?.maxGuests === condiateForHistory?.maxGuests
        )
      ) {
        localStorage.setItem(
          "variantsHistory",
          JSON.stringify([condiateForHistory, ...state.variantsHistory] || [])
        );
      } else {
        // переписывет изменяя последовательность
        const variantsHistory = state.variantsHistory.filter((item) => {
          if (
            item?.location?.id !== condiateForHistory?.location?.id ||
            (item?.location?.id === condiateForHistory?.location?.id &&
              item?.date?.in !== condiateForHistory?.date?.in) ||
            (item?.location?.id === condiateForHistory?.location?.id &&
              item?.date?.out !== condiateForHistory?.date?.out) ||
            (item?.location?.id === condiateForHistory?.location?.id &&
              item?.maxGuests !== condiateForHistory?.maxGuests)
          ) {
            return true;
          }
          return false;
        });
        localStorage.setItem(
          "variantsHistory",
          JSON.stringify([condiateForHistory, ...variantsHistory] || [])
        );
      }
    }
  },
  setUsersSearchParams({ commit }, ids) {
    Users.getUsersSearchParams(ids).then((res) => {
      commit("usersSearchParamsMutate", res.data.data.users);
    });
  },
  setAvgReviewsRatings({ commit }, id) {
    commit("avgRatingsMutate", []);
    return Messages.getAvgReviewsRatings(id).then((response) => {
      if (response.data && response.data.data)
        commit("avgRatingsMutate", response.data.data.objects);
      return response.data.data;
    });
  },
  setHotelRatings({ commit }, id) {
    return Messages.getHotelRating(id).then((response) => {
      if (response.data && response.data.data)
        commit("hotelRatingsMutate", response.data.data);
      return response.data.data;
    });
  },
  reset({ commit, dispatch }) {
    commit("setRoute");
    //commit("setCheckIn");
    //commit("setCheckOut");
    commit("setSortings");
    commit("setCheapAndFast");
    commit("setView");
    commit("setMap");
    commit("setTotalFound");
    dispatch("filter/resetFilter", [], { root: true });
  },
  async setLocationForFilter({ commit, dispatch }, { title_ext, query }) {
    if (!title_ext) {
      commit("setLocationForFilter", {
        id: 1,
        sort: 2,
        visible: 1,
        code: "russia",
        subdomain: null,
        title: "Россия",
        title_w: "России",
        type: null,
        has_sea: false,
      });
      return false;
    }

    const res = await dispatch("getTermSuggestionsWithBoundingBox", title_ext);
    const suggestion = res.length ? res[0] : null;
    console.log("suggestion", suggestion);

    if (suggestion?.location?.id && suggestion?.location?.type) {
      Locations.getLocationByIdAndType(
        suggestion.location.id,
        suggestion.location.type
      )
        .then(async (res) => {
          const location = {
            ...query,
            ...res.data.location,
          };
          await dispatch("setPlacePreference", location);
          commit("setLocationForFilter", location);
        })
        .catch((error) => {
          console.log("Ошибка при смене локации", "Ajax error:", error);
        });
    } else if (suggestion?.title_ext) {
      dispatch("setLocationForFilter", {
        title_ext: suggestion.title_ext,
        query,
      });
    }
  },
  async getLocation({ commit, dispatch }, term) {
    return await Locations.getLocations().then((response) => {
      const result = Object.entries(response.data).reduce(
        (acc, [key, value]) => {
          const keys = key.split("_");
          const country = keys[1].toString().toLowerCase();
          acc[country] = Object.values(value.cities).reduce((a, v) => {
            a[v.title.toString().toLowerCase()] = v;
            return a;
          }, {});
          return acc;
        },
        {}
      );

      for (const cities of Object.values(result)) {
        const city = cities[term.toString().toLowerCase()];
        if (city) {
          return Number(city.id);
        }
      }
      return null;
    });
  },
  async setLocation(
    { commit, state, dispatch },
    {
      id,
      type,
      sight = null,
      bbox = null,
      term = null,
      point = null,
      query,
      customTitle = null,
      title_ext = "",
      isSetTerm = true,
    } = {}
  ) {
    return new Promise((resolve, reject) => {
      if (id && type) {
        Locations.getLocationByIdAndType(id, type)
          .then(async (response) => {
            commit("setSearchProcessActive", true);
            const location = {
              ...query,
              ...response.data.location,
              sight,
            };
            if (customTitle) {
              location.title = customTitle;
            }
            if (isSetTerm) {
              commit("setTerm", location.title);
            }
            localStorage.setItem(
              "location",
              JSON.stringify(response.data.location)
            );
            if (sight) {
              const lat = sight.point.lat;
              const lng = sight.point.lon;
              const zoom = 16;
              commit("setMap", { lat, lng, zoom });
              await dispatch("setPlacePreference", location);
              commit("setLocationForFilter", location);
              commit("setLocation", location);
              commit("setSelectedVariant", location);
              commit("setInitLocation", true);

              resolve();
            } else {
              (point && commit("setPlace", { ...point, title: term })) ||
                commit("setPlace");
              commit("setBound", bbox || location.bbox);
              commit("setMap");
              await dispatch("setPlacePreference", location);
              commit("setLocationForFilter", location);
              commit("setLocation", location);
              commit("setSelectedVariant", location);
              commit("setInitLocation", true);

              resolve();
            }
          })
          .catch((error) => {
            commit("setLoadingLocation", false);
            reject(error);
          });
      } else {
        dispatch("setLocationForFilter", { title_ext, query });
        (point && commit("setPlace", { ...point, title: term })) ||
          commit("setPlace");
        commit("setTerm", term);
        commit("setBound", location.bbox || bbox);
        commit("setMap");
        commit("setLocation", {
          id: 1,
          sort: 2,
          visible: 1,
          code: "russia",
          subdomain: null,
          title: "Россия",
          title_w: "России",
          type: null,
          has_sea: false,
        });

        commit("setInitLocation", true);
        resolve();
      }
    });
  },
  getTermSuggestionsWithBoundingBox({ commit }, value) {
    if (!value) return [];
    commit("updateListSuggestions", {
      isLoading: true,
      isError: false,
    });
    return Search.getTermSuggestionsWithBoundingBox(value)
      .then((res) => {
        if (res.data?.data?.suggestions) {
          commit("updateListSuggestions", {
            isLoading: false,
            isError: false,
          });
          return res.data.data.suggestions;
        } else {
          commit("updateListSuggestions", {
            isLoading: false,
            isError: true,
          });
          console.log(
            "Ошибка при получении направлений поиска, не пришел список направлений"
          );
          return [];
        }
      })
      .catch((error) => {
        commit("updateListSuggestions", {
          isLoading: false,
          isError: true,
        });
        console.log(
          "Ошибка при получении направлений поиска",
          "Ajax error:",
          error
        );
      });
  },
  updateAdults({ commit, state }, adults) {
    commit("setAdults", adults);
    saveGuestsLS(state.guests);
  },
  updateChildrens({ commit, state }, childrens) {
    commit("setChildrens", childrens);
    saveGuestsLS(state.guests);
  },
  updatePets: ({ commit, state }, pets) => {
    commit("setPets", pets);
    saveGuestsLS(state.guests);
  },
  getPricesBronevik(
    { commit },
    {
      guests = null,
      no_time = null,
      time_begin = null,
      time_end = null,
      date_begin = null,
      date_end = null,
      objects = [],
      max_rooms = {},
      currency_id = 1,
    }
  ) {
    commit("setLoadBronevikPrice", true);
    Objects.getPricesAndAvailabilitiesBronevik({
      guests,
      no_time,
      time_begin,
      time_end,
      date_begin,
      date_end,
      objects,
      max_rooms,
      currency_id,
    })
      .then((response) => {
        let priceObjects = response.data?.data;
        if (priceObjects) {
          commit("setPricesBronevik", priceObjects);
        }
        commit("setLoadBronevikPrice", false);
      })
      .catch((error) => {
        console.log(
          "Ajax error: Objects.getPricesAndAvailabilitiesBronevik, ",
          error
        );
      });
  },
  resetDatesAndGuests({ commit }) {
    commit("setAdults");
    commit("setChildrens");
    commit("setDates");
  },
  relevanceFieldValue({ state }, request) {
    const { adults, childrens } = state.guests;
    if (adults && adults === 2 && !childrens.length) {
      request.relevance = "pairs";
    }
    // if(adults && adults >= 3 && !childrens.length) {
    //   request.relevance = "companies";
    // }
    if (childrens.length) {
      request.relevance = "family";
    }
    if (adults && adults < 2 && !childrens.length && "relevance" in request) {
      delete request.relevance;
    }
    return request;
  },
  setCalendarDates(
    { state, commit, getters, rootState },
    payload = { checkIn: null, checkOut: null }
  ) {
    commit("setCheckIn", payload.checkIn);
    commit("setCheckOut", payload.checkOut);

    if (payload.checkIn && payload.checkOut) {
      const EXPIRED = getters.occupied
        ? new Date(
            Math.min(
              new Date(payload.checkIn).setHours(23, 59),
              Date.now() + 864000000
            )
          )
        : "1d";
      const PAYLOAD = {
        date_begin: removeTZ(payload.checkIn),
        date_end: removeTZ(payload.checkOut),
        guests: getters.maxGuests,
        term: state.term,
      };
      VueCookies.set(
        "calendar_dates",
        PAYLOAD,
        EXPIRED,
        null,
        getHost().domainDot
      );
    } else if (VueCookies.isKey("calendar_dates")) {
      VueCookies.remove("calendar_dates", null, getHost().domainDot);
    }
  },

  setQuery: ({ state, commit, dispatch }, route) => {
    let { query, hash } = route;

    query["NE.lat"] = query.NE_lat ? query.NE_lat : query["NE.lat"];
    query["NE.lng"] = query.NE_lng ? query.NE_lng : query["NE.lng"];
    query["SW.lat"] = query.SW_lat ? query.SW_lat : query["SW.lat"];
    query["SW.lng"] = query.SW_lng ? query.SW_lng : query["SW.lng"];

    delete query.NE_lat;
    delete query.NE_lng;
    delete query.SW_lat;
    delete query.SW_lng;

    const exluded = ["marker"]; //исключаемы  параметры из url
    exluded.forEach((v) => {
      const candidateToDelete = query[v] !== "undefined";
      if (candidateToDelete) {
        delete query[v];
      }
    });

    // добавляем objectScroll потому что он будет проставляться в getQuery при пересборке роутера
    commit("setObjectScroll", query.objectScroll || null);
    if (query.max_guests) {
      const adults = isNaN(parseInt(query.max_guests))
        ? 1
        : parseInt(query.max_guests);
      commit("setAdults", adults);
    }

    const isDefaultLocationRender = !(
      query.term ||
      query.id ||
      query["SW.lat"] ||
      query["SW.lng"] ||
      query["NE.lat"] ||
      query["NE.lng"]
    );
    commit("setIsDefaultLocationRender", isDefaultLocationRender);

    if (
      /(\d{4}-\d{2}-\d{2});(\d{4}-\d{2}-\dvalue.index{2})/gm.test(
        query.occupied
      )
    ) {
      let [checkIn, checkOut] = query.occupied.match(/(\d{4}-\d{2}-\d{2})/gm);
      if (checkIn !== undefined && checkOut !== undefined) {
        commit("setCheckIn", new Date(checkIn));
        commit("setCheckOut", new Date(checkOut));
      }
    }
    commit("setSortings", query.sortings || null);
    commit("setCheapAndFast", query.cheap_and_fast || null);

    const [pointLat, pointLng] = (query.point &&
      query.point.toString().includes(",") &&
      query.point.split(",")) || [null, null];
    const pointTerm = query.term || null;
    const place = Object.assign({}, query.place, {
      lat: pointLat || state.place.lat,
      lng: pointLng || state.place.lng,
      title: pointTerm || state.place.title,
    });
    commit("setPlace", place);
    commit("setTerm", pointTerm || "");

    const map = Object.assign({}, query.map, {
      lat: query.lat || state.map.lat,
      lng: query.lng || state.map.lng,
      zoom: query.zoom || state.map.zoom,
      title: query.title || state.map.title,
    });
    commit("setMap", map);

    // RESTORE BOUND
    const bound = {
      SW: {
        lat:
          Number(query["SW.lat"]) ||
          (pointLat && pointLat - 1) ||
          state.bound.SW.lat,
        lng:
          Number(query["SW.lng"]) ||
          (pointLng && pointLng - 0.5) ||
          state.bound.SW.lng,
      },
      NE: {
        lat:
          Number(query["NE.lat"]) ||
          (pointLat && pointLat + 1) ||
          state.bound.NE.lat,
        lng:
          Number(query["NE.lng"]) ||
          (pointLng && pointLng + 0.5) ||
          state.bound.NE.lng,
      },
    };
    commit("setBound", bound);

    if (hash?.length) commit("setView", hash.slice(1));
  },
};

// mutations
const mutations = {
  setWidgetFocused(state, value) {
    state.isWidgetFocused = value;
  },
  setCalendarFocused(state, value) {
    state.isCalendarFocused = value;
  },
  setGuestFocused(state, value) {
    state.isGuestFocused = value;
  },
  setPlacePreference(state, value) {
    state.placePreference = value;
  },
  setLocationForFilter(state, value) {
    state.locationForFilter = value;
  },
  setAnaliticParamForSearchWidList(state, value) {
    state.analiticParamForSearchWidList = value;
  },
  setDateFromHistorylist(state, value) {
    state.dateFromHistorylist = value;
  },
  setVariantsHistory(state, value) {
    state.variantsHistory = value;
  },
  setCondidateForHistory(state, value) {
    state.condiateForHistory = value;
  },
  setSelectedVariant(state, value) {
    state.selectedVariant = value;
  },
  setSearchProcessActive(state, value) {
    state.isSearchProcessActive = value;
  },
  positionMutate: (state, value) => {
    state.isPosition = value;
  },
  usersSearchParamsMutate: (state, value) => {
    state.usersSearchParams = value;
  },
  avgRatingsMutate: (state, value) => {
    state.avgRatings = value;
  },

  hotelRatingsMutate: (state, value) => {
    state.hotelRatings[value.hotel_id] = value;
  },
  setIsLoadFilter: (state, payload) => {
    state.isLoadFilter = payload;
  },
  setFiltersTotalFound: (state, value = null) => {
    state.filtersTotalFound = value;
  },
  setFiltersView: (state, value = "desktop") => {
    // del используется только в FilterMain
    state.filtersView = value;
  },
  setSupportFilters: (state, value) => {
    state.isSupportFilters = value;
  },
  setGuests: (state, value) => {
    state.guests = value;
    localStorage.setItem("guests", JSON.stringify(value));
  },
  setAdults: (state, value = 1) => {
    state.guests.adults = value;
  },
  setPets: (state, value) => {
    state.guests.pets = value;
  },
  setChildrens: (state, value = []) => {
    state.guests.childrens = value;
  },
  updateChildrens: (state, value) => {
    state.guests.childrens[value.index] = value.val;
  },
  deleteChildrens: (state, value) => {
    if (state.guests.childrens.length) {
      state.guests.childrens.splice(value, 1);
    }
  },
  addChildrens: (state, value) => {
    state.guests.childrens.push(value);
  },
  setChildrenWithoutAgeError: (state, value) => {
    state.childrenWithoutAgeError = Boolean(value);
  },
  setRoute: (state, value = null) => {
    state.route = value;
  },
  setCheckIn: (state, value = null) => {
    state.checkIn = value;
  },
  setCheckOut: (state, value = null) => {
    state.checkOut = value;
  },
  //set checkIn and checkOut from query string
  // setOccupied: (state, value = null) => {
  //   // Validate scheme 2019-02-05;2019-02-06
  //   if (/(\d{4}-\d{2}-\d{2});(\d{4}-\d{2}-\d{2})/gm.test(value)) {
  //     let [checkIn, checkOut] = value.match(/(\d{4}-\d{2}-\d{2})/gm);

  //     if (checkIn !== undefined && checkOut !== undefined) {
  //       state.checkIn = new Date(checkIn);
  //       state.checkOut = new Date(checkOut);
  //     }
  //   }
  // },
  setSortings: (state, value = null) => {
    state.sortings = value;
  },
  setCheapAndFast: (state, value = null) => {
    state.cheap_and_fast = value;
  },
  setView: (state, value = "list") => {
    state.view = value;
  },
  setPositionOfObjectsInTheSearch: (state, value) => {
    state.positionOfObjectsInTheSearch = value;
  },
  setSearchResultsObjectsIds: (state, value) => {
    state.searchResultsObjectsIds = value;
  },
  setTotalFound: (state, value = 0) => {
    state.totalFound = value;
  },
  setTotalFoundAll: (state, value = 0) => {
    state.totalFoundAll = value;
  },

  setMap: (
    state,
    value = { lat: null, lng: null, zoom: null, title: null, bound: null }
  ) => {
    state.map = Object.assign({}, state.map, value);
  },
  setPlace: (
    state,
    value = { lat: null, lng: null, id: null, title: null }
  ) => {
    state.place = Object.assign({}, state.place, value);
  },
  setLocation: (vstate, object) => {
    // Если в место объекта falsy то сброс до начального состояния
    if (!object) {
      vstate.location = state().location;
    } else {
      vstate.location = object;
    }
    // Если у локации нет такой сортировки, то сбрасываем
    if (!vstate.location.has_sea && vstate.sortings == "distance_to_sea.asc") {
      vstate.sortings = null;
    }
  },
  setSelectedMapPointId: (state, payload) => {
    state.selectedMapPointId = payload;
  },
  setSelectedMapPointsIds: (state, payload) => {
    state.selectedMapPointsIds = payload;
  },
  setHoverMapPointId: (state, payload) => {
    state.hoverMapPointId = payload;
  },
  setBound: (state, payload) => {
    if (!payload) return;

    const jsonBound = JSON.stringify(payload);
    if (jsonBound !== JSON.stringify(state.bound)) {
      state.bound = JSON.parse(jsonBound);
    }
  },
  setBoundFirstLoad: (state, payload) => {
    state.boundFirstLoad = JSON.parse(JSON.stringify(payload));
  },
  setTerm: (state, value) => {
    state.term = value || "";
  },
  setCardVisible: (state, payload) => {
    state.cardVisible = payload;
  },
  updateSwitchMap: (state, payload) => {
    state.switchMap = payload;
  },
  updateListSuggestions: (state, value) => {
    if (value) {
      state.listSuggestions.isLoading = value.isLoading;
      state.listSuggestions.isError = value.isError;
    }
  },
  setLoadingLocation: (state, value) => {
    state.isLoadingLocation = value;
  },
  /**
   * Изменяем признак инициализации локации в поиске
   * @param { state } state хранилище store
   * @param { boolean } value значение признака инициализации поиска
   *
   * @returns { void }
   */
  setInitLocation: (state, value) => {
    state.isInitLocation = value;
  },
  setPricesBronevik: (state, value) => {
    state.pricesBronevik = value;
  },
  setObjectsBronevik: (state, value) => {
    state.objectsBronevik = value;
  },
  setLoadBronevikPrice: (state, value) => {
    state.loadBronevikPrice = value;
  },
  setFlowPrice: (state, value) => {
    state.flowPrice = value;
  },
  setFacets: (state, value) => {
    state.facets = value;
  },
  setDates: (state, value = [null, null]) => {
    state.checkIn = value[0];
    state.checkOut = value[1];
  },
  setStatusActivePage(state, value = "") {
    state.statusActivePage = value;
  },
  setListType(state, value = "") {
    state.listType = value;
  },
  setIsLazyLoading: (state, payload = false) => (state.isLazyLoading = payload),
  setCountLazyObjects: (state, payload = 0) =>
    (state.countLazyObjects = payload),
  setIsVisibleFiltersMobile: (state, value) => {
    state.isVisibleFiltersMobile = value;
  },
  setObjectScroll: (state, payload = null) => {
    state.objectScroll = payload;
  },
  setIsDefaultLocationRender: (state, payload) => {
    state.isDefaultLocationRender = payload;
  },
  setSearchInMap: (state, payload) => {
    state.searchInMap = payload;
  },
  setShowSearchModal: (state, payload) => {
    state.showSearchModal = payload;
  },
  setObjectsOnMap: (state, {resultsForMap, pageNum}) => {
    if(Array.isArray(resultsForMap)){
      state.objectsOnMap[pageNum] = resultsForMap;
    }
  },
  setNoAuthToast: (state, payload) => {
    state.noAuthToast = payload;
  },
};

const simple = [
  [
    {
      name: "s",
      value: false,
    },
    {
      name: "g",
      value: false,
    },
  ], // s,g - length 2
  [
    {
      name: "l",
      value: false,
    },
  ], // l,b,a,z,s - length 1
  [
    {
      name: "d",
      value: false,
    },
  ], // d,2,p,a - length 1
  [
    {
      name: "c",
      value: false,
    },
    {
      name: "p",
      value: false,
    },
  ], // c,p,g - length 2
  [
    {
      name: "e",
      value: false,
    },
    {
      name: "v",
      value: false,
    },
  ], // e,v - length 2
];

export default {
  namespaced: true,
  namespace: "search",
  state,
  getters,
  actions,
  mutations,
};
