import HTTP from "../http-common";

// Получаем сумму оплаты брони,
// пример ответа:
// "payment": {
//   "cost": 3200,
//     "pre_cost": 800,
//     "days_count": 1,
//     "currency_id": 1,
//     "currency_code": "RUB",
//     "balance": 6,
//     "bonus": 0,
//     "final": 794
// }
// передаем параметры: orderId, currencyId, useBonuses - (true или false) оплата с бонусами или нет

export default (formData) =>
  HTTP.post("json/orders/calculatePrecostPrice", formData, {
    headers: {
      "api-version": "1.9",
    },
    cache: false,
  });
