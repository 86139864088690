import { algorithm } from "@sutochno/utils";
import getUserIP from "@/utils/getUserIP";
// TODO: Nuxt проверить работоспособность
// import router from "@/route";

const getCommonParams = async () => {
  const router = useRouter();

  let sid = sessionStorage.getItem("customSID");
  if (!sid) {
    sid = Math.random().toString(16).slice(2);
    sessionStorage.setItem("customSID", sid);
  }
  let cookie = localStorage.getItem("customCookie");
  if (!cookie) {
    cookie = Math.random().toString(16).slice(2);
    localStorage.setItem("customCookie", cookie);
  }
  let pid = algorithm.md5(document.location.pathname);
  const dim = window.outerWidth + "x" + window.outerHeight;

  const ref = router.options.history.state.back
    ? document.location.origin + router.options.history.state.back
    : document.referrer;

  let ip = sessionStorage.getItem("userIPs")

  if (!ip) {
    ip = await getUserIP();
  }

  return {
    sid,
    cookie,
    pid,
    dim,
    url: document.location.href,
    ref,
    ua: navigator.userAgent,
    ip,
  };
};

export default getCommonParams;
