<i18n lang="json">
{
  "ru": {
    "modalSearchTitle": "Произошла ошибка"
  },
  "en": {
    "modalSearchTitle": "An error has occurred"
  }
}
</i18n>
<template>
  <Modal
    :modelValue="show"
    class="modal-reload"
    :class="{ mobile: viewPort === 'mobile' }"
    :title="t('modalSearchTitle')"
    min-width="auto"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <div class="modal-reload__text">
      {{ "Пожалуйста, обновите страницу и повторите поиск." }}
    </div>
    <button class="btn modal-reload__btn" @click="reload()">{{
      "Обновить"
    }}</button>
  </Modal>
</template>

<script>
import { mapState } from "vuex";
import Modal from "@sutochno/modal";
import { useI18n } from 'vue-i18n';

export default {
  name: "ModaReload",
  components: {
    Modal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  computed: {
    ...mapState("user", ["viewPort"]),
  },
  methods: {
    reload() {
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
$paddingBody: 1rem;
.modal-reload {
  z-index: 1000;

  &__btn {
    margin-top: $paddingBody;
    border-color: #f51449;
    background: #f51449;
    &:active,
    &:hover,
    &:focus {
      color: #fff;
      background-color: #f62c5c !important;
      border-color: #f51449 !important;
    }
  }

  :deep() {
    .sc-modal {
      &-header {
        justify-content: center;
      }
      &-body {
        padding: 0 $paddingBody $paddingBody $paddingBody;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .desktop & {
    :deep(.icon-app-cross) {
      display: none;
    }
  }
  .mobile & {
    :deep() {
      .sc-modal {
        &-body {
          padding-top: $paddingBody;
          text-align: center;
        }
      }
    }
    :deep(.icon-app-arrow-left) {
      display: none;
    }
  }
}
</style>
