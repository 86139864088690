<i18n lang="json">
{
	"ru": {
    "day": "{n} суток | {n} сутки | {n} суток | {n} суток"
	},
  "en": {
    "day": "{n} day | {n} day | {n} days | {n} days"
	}
}
</i18n>
<template>
  <td
    data-cy="sc-datepickerext-day"
    :data-cy-date="timeCurrentDay.toLocaleDateString()"
    class="sc-datepickerext-day hint-datepicker"
    @click="onClickDay()"
    :class="{
      'sc-datepickerext-active': isActive || isHoverDate,
      'sc-datepickerext-select': isSelectClass || isInterval,
      'sc-datepickerext-current-date': isCurrentDate,
      'sc-datepickerext-disabled': isDisabled,
      'sc-datepickerext-error open': isError,
      'sc-datepickerext-day-employment__both': isEmploy,
      'sc-datepickerext-day--left': isActive === 'left',
      'sc-datepickerext-day--right': isActive === 'right' || isHoverDate,
    }"
    @mouseover="onHoverSet"
    @mouseleave="onHoverRemove"
  >
    <div class="day-wrapper">
      <div class="sc-datepickerext-baloon" v-if="dateBaloonShow">
        {{ t("day", dateBaloon) }}
      </div>
      <!-- open -->
      <span>{{ date }}</span>
      <div
        class="hint-datepicker__body tmp-font--small"
        :class="{
          'left-left': index === 1,
          left: index === 2,
          right: index === 6,
          'right-right': index === 7,
        }"
        v-if="isError"
      >
        <template v-if="errorText === 'cntMin'">{{
          `Минимальный срок проживания ${cntMin} суток`
        }}</template>
        <template v-else-if="errorText === 'checkout'">{{
          `Доступен только отъезд`
        }}</template>
      </div>
    </div>
  </td>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  name: "Day",
  components: {},
  props: {
    dateMonth: {
      type: Date,
      required: true,
    },
    date: {
      type: Number,
      required: true,
    },
    startDisable: {
      type: Date,
      required: true,
      default: () => new Date(),
    },
    endDisable: {
      type: Date,
    },
    index: {
      type: Number,
      required: true,
    },
    cntMin: {
      type: Number,
      default: null,
    },
    employment: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      isHoverDate: false,
      dateBaloon: null,
      dateBaloonShow: false,
    };
  },
  computed: {
    dateIn() {
      return this.$parent.$parent.dateIn;
    },
    dateOut() {
      return this.$parent.$parent.dateOut;
    },
    dateError() {
      return this.$parent.$parent.date.error;
    },
    dateSingle() {
      return this.$parent.$parent.dateSingle;
    },
    errorText() {
      return this.$parent.$parent.errorText;
    },
    timeCurrentDay() {
      return new Date(
        this.dateMonth.getFullYear(),
        this.dateMonth.getMonth(),
        this.date
      );
    },
    isError() {
      return (
        this.dateError &&
        this.dateError.getTime() == this.timeCurrentDay.getTime()
      );
    },
    isActive() {
      // Если выбрана левая часть
      let isLeft =
        this.dateIn &&
        this.timeCurrentDay.getFullYear() == this.dateIn.getFullYear() &&
        this.timeCurrentDay.getMonth() == this.dateIn.getMonth() &&
        this.timeCurrentDay.getDate() == this.dateIn.getDate()
          ? "left"
          : false;
      // Если выбрана как правая часть
      let isRight =
        this.dateOut &&
        this.timeCurrentDay.getFullYear() == this.dateOut.getFullYear() &&
        this.timeCurrentDay.getMonth() == this.dateOut.getMonth() &&
        this.timeCurrentDay.getDate() == this.dateOut.getDate()
          ? "right"
          : false;
      let isSingle =
        this.dateSingle &&
        this.timeCurrentDay.getFullYear() == this.dateSingle.getFullYear() &&
        this.timeCurrentDay.getMonth() == this.dateSingle.getMonth() &&
        this.timeCurrentDay.getDate() == this.dateSingle.getDate();
      return isLeft || isRight || isSingle;
    },
    isSelectClass() {
      const timeCurrentDay =
        this.timeCurrentDay && this.timeCurrentDay.getTime();
      const dateError = this.dateError && this.dateError.getTime();
      const dateIn = this.dateIn && this.dateIn.getTime();

      //  Если дата правее выбраной левой части
      const isLeft = this.dateIn && timeCurrentDay > dateIn;
      // Если дата левее выбраной правой части
      const isRight = this.dateOut && timeCurrentDay < this.dateOut.getTime();

      const select =
        (isLeft && isRight) ||
        (this.dateIn &&
          this.dateError &&
          ((timeCurrentDay > dateError && timeCurrentDay < dateIn) ||
            (timeCurrentDay < dateError && timeCurrentDay > dateIn)));
      return select;
    },
    isSelect() {
      const timeCurrentDay =
        this.timeCurrentDay && this.timeCurrentDay.getTime();
      const dateError = this.dateError && this.dateError.getTime();
      const dateIn = this.dateIn && this.dateIn.getTime();
      const fillMode = this.$parent.$parent.fillMode;

      //  Если дата правее выбраной левой части
      const isLeft =
        this.dateIn &&
        (fillMode === "in"
          ? timeCurrentDay >= dateIn || timeCurrentDay < dateIn
          : timeCurrentDay > dateIn);
      // Если дата левее выбраной правой части
      const isRight = this.dateOut && timeCurrentDay < this.dateOut.getTime();

      const select =
        (isLeft && isRight) ||
        (this.dateIn &&
          this.dateError &&
          ((timeCurrentDay > dateError && timeCurrentDay < dateIn) ||
            (timeCurrentDay < dateError && timeCurrentDay > dateIn)));
      return select;
    },
    isInterval() {
      if (this.dateIn && this.dateOut) {
        return false;
      } else {
        const timeCurrentDay = this.timeCurrentDay.getTime();
        const dateIn = this.dateIn && this.dateIn.getTime();

        //если дата в календаре больше первой выбранной даты
        const isLeft = timeCurrentDay > dateIn;
        //если дата в календаре и первая выбранная дата меньше даты при наведении
        const isRight =
          dateIn &&
          timeCurrentDay < this.$parent.$parent.hoverDate &&
          this.$parent.$parent.hoverDate.getTime();

        //выделяем даты и простраиваем интервал
        return isLeft && isRight;
      }
    },
    isSelectMoreRight() {
      const timeCurrentDay =
        this.timeCurrentDay && this.timeCurrentDay.getTime();
      const dateError = this.dateError && this.dateError.getTime();
      const dateIn = this.dateIn && this.dateIn.getTime();

      //  Если дата правее выбраной левой части
      const isLeft = this.dateIn && timeCurrentDay > dateIn;
      // Если дата правее выбраной правой части
      const isRight = this.dateOut && timeCurrentDay > this.dateOut.getTime();

      const select =
        (isLeft && isRight) ||
        (this.dateIn &&
          this.dateError &&
          ((timeCurrentDay > dateError && timeCurrentDay < dateIn) ||
            (timeCurrentDay < dateError && timeCurrentDay > dateIn)));
      return select;
    },
    isCurrentDate() {
      let toDay = new Date();
      return (
        this.timeCurrentDay.getFullYear() == toDay.getFullYear() &&
        this.timeCurrentDay.getMonth() == toDay.getMonth() &&
        this.timeCurrentDay.getDate() == toDay.getDate()
      );
    },
    isDisabled() {
      return (
        this.timeCurrentDay.getTime() + 86400000 <
          this.startDisable.getTime() || // -1 день (в мсек)
        (this.endDisable &&
          this.timeCurrentDay.getTime() > this.endDisable.getTime())
      );
    },
    isEmploy() {
      return this.$parent &&
        this.$parent.$parent &&
        this.$parent.$parent.choiceEployment &&
        this.timeCurrentDay.getTime() >
          this.$parent.$parent.choiceEployment.getTime()
        ? true
        : false;
    },
  },
  methods: {
    onHoverSet() {
      if (this.dateIn && !this.dateOut) {
        //расчитываем разницу в днях между датой при наведении и первой выбранной датой
        this.dateBaloon = Math.ceil(
          (this.timeCurrentDay.getTime() - this.dateIn.getTime()) /
            (1000 * 3600 * 24)
        );
        if (this.dateBaloon <= 0) {
          this.dateBaloonShow = false;
        } else {
          this.dateBaloonShow = true;
        }
        this.isHoverDate = true;
      }
      this.$parent.$parent.hoverDate = this.timeCurrentDay;
    },
    onHoverRemove() {
      this.isHoverDate = false;
      this.dateBaloonShow = false;
      this.$parent.$parent.hoverDate = null;
    },
    onClickDay() {
      if (this.$parent.$parent.type == "range") {
        this.rangeDayClick();
      }
      if (this.$parent.$parent.type == "single") {
        this.singleDayClick();
      }
    },
    singleDayClick() {
      this.$parent.$parent.dateSingle = this.timeCurrentDay;
    },
    rangeDayClick() {
      // Если есть заблокированые даты проверяем их
      if (this.$parent.employment && (this.dateIn || this.dateOut)) {
        const anotherPoint = [this.dateIn, this.dateOut]
          .filter((v) => v)
          .map((v) => v.getTime());
        // (this.dateIn && this.dateIn.getTime()) ||
        // (this.dateOut && this.dateOut.getTime());
        const leftPart =
          Math.min(this.timeCurrentDay.getTime(), ...anotherPoint) +
          (86400000 - 1);
        const rightPart = Math.max(
          this.timeCurrentDay.getTime(),
          ...anotherPoint
        );

        // Пример расчетных дат
        // Mon Sep 09 2019 23:59:59 GMT+0400 (Самарское стандартное время)
        // Tue Sep 10 2019 00:00:00 GMT+0400 (Самарское стандартное время)

        const isEmploy = this.$parent.employment.find((value) => {
          // console.log(
          //   "Занятость внутри промежутка",
          //   value.from.getTime() <= leftPart && leftPart < value.to.getTime() ,
          //   value.from.getTime() <= rightPart && rightPart < value.to.getTime(),

          //   "Между выбраным есть занятость",
          //   leftPart <= value.from.getTime() && value.from.getTime() < rightPart,
          //   leftPart <=  value.to.getTime() && value.to.getTime() < rightPart
          // );
          return (
            (value.from.getTime() <= leftPart &&
              leftPart < value.to.getTime() &&
              value.from.getTime() <= rightPart &&
              rightPart < value.to.getTime()) ||
            (leftPart <= value.from.getTime() &&
              value.from.getTime() < rightPart) ||
            (leftPart <= value.to.getTime() && value.to.getTime() < rightPart)
          );
        });

        // Можно сделать выбрасывание ошибки, но хер с ней
        if (isEmploy) {
          if (this.dateIn && this.dateOut) {
            this.$parent.$parent.dateOut = null;
            this.$parent.$parent.dateIn = null;
          } else {
            // return;
            // this.$parent.$parent.dateOut = null;
            // console.log('ya');
            return;
          }
        }
      }

      if (!this.dateIn && this.dateError) {
        this.$parent.$parent.date.error = null;
      }

      if (
        this.dateIn &&
        this.dateOut &&
        this.isSelect &&
        this.$parent.$parent.fillMode
      ) {
        if (this.$parent.$parent.fillMode === "in")
          this.$parent.$parent.dateIn = this.timeCurrentDay;
        else if (this.$parent.$parent.fillMode === "out")
          this.$parent.$parent.dateOut = this.timeCurrentDay;
      }

      if (
        this.dateIn &&
        this.dateOut &&
        this.isSelectMoreRight &&
        this.$parent.$parent.fillMode === "out"
      ) {
        this.$parent.$parent.dateOut = this.timeCurrentDay;
      }

      // логика проставления ошибки когда день занят и у него доступен только отъезд
      if (!this.dateIn) {
        if (
          this.$parent.$parent.date.error !== this.timeCurrentDay &&
          this.employment === "right"
        ) {
          this.$parent.$parent.date.error = this.timeCurrentDay;
          this.$parent.$parent.errorText = "checkout";

          return;
        } else if (this.$parent.$parent.date.error === this.timeCurrentDay) {
          this.$parent.$parent.date.error = null;

          return;
        }
      } else if (this.dateIn && this.dateOut && this.employment === "right") {
        this.$parent.$parent.dateIn = null;
        this.$parent.$parent.dateOut = null;
        this.$parent.$parent.date.error = this.timeCurrentDay;
        this.$parent.$parent.errorText = "checkout";
        return;
      }

      // логика проставления ошибки за минимальный срок бронирования
      if (
        this.dateIn &&
        !this.dateOut &&
        this.timeCurrentDay.getTime() !== this.dateIn.getTime()
      ) {
        let check = this.dateOut
          ? this.timeCurrentDay.getTime() - this.dateOut.getTime()
          : this.timeCurrentDay.getTime() - this.dateIn.getTime();
        let cntDays = Math.floor(
          (check > 0 ? check : check * -1) / (1000 * 60 * 60 * 24)
        );

        if (
          this.dateOut &&
          this.timeCurrentDay.getTime() == this.dateOut.getTime()
        ) {
          this.$parent.$parent.dateOut = null;
          this.$parent.$parent.date.error = null;
          return;
        } else if (this.timeCurrentDay.getTime() < this.dateIn.getTime()) {
          this.$parent.$parent.dateIn = this.timeCurrentDay;
          this.$parent.$parent.dateOut = null;
          this.$parent.$parent.date.error = null;
          return;
        } else if (cntDays < this.cntMin) {
          if (this.dateOut) {
            this.$parent.$parent.dateIn = this.$parent.$parent.dateOut;
          }
          this.$parent.$parent.dateOut = null;
          this.$parent.$parent.date.error = this.timeCurrentDay;
          this.$parent.$parent.errorText = "cntMin";
          return;
        } else {
          this.$parent.$parent.date.error = null;
        }
      }

      // при отсутствии даты выезда добавляет её автоматически +1 день
      if (
        this.dateIn &&
        !this.dateOut &&
        this.timeCurrentDay.getTime() === this.dateIn.getTime()
      ) {
        this.$parent.$parent.dateOut = new Date(
          new Date(this.dateIn).setDate(this.dateIn.getDate() + 1)
        );
      }

      if (
        !this.dateIn ||
        (this.$parent.$parent.changeRange == "in" &&
          this.dateOut &&
          this.timeCurrentDay.getTime() != this.dateOut.getTime())
      ) {
        // На случай если есть дата отъезда и мы нажали на нее
        if (
          !this.dateOut ||
          (this.dateOut &&
            this.timeCurrentDay.getTime() != this.dateOut.getTime())
        ) {
          if (
            this.dateOut &&
            this.timeCurrentDay.getTime() != this.dateIn.getTime()
          ) {
            this.$parent.$parent.dateOut = null;
            this.$parent.$parent.dateIn = this.timeCurrentDay;
            this.$parent.$parent.date.error = null;
          } else {
            this.$parent.$parent.dateIn = this.timeCurrentDay;
          }
        } else {
          this.$parent.$parent.dateOut = null;
          this.$parent.$parent.date.error = null;
          // return;
        }
        return;
      } else {
        if (this.timeCurrentDay.getTime() == this.dateIn.getTime()) {
          // this.$parent.$parent.dateIn = null;
          // this.$parent.$parent.date.error = null
          return;
        }
      }

      if (!this.dateOut || this.$parent.$parent.changeRange == "out") {
        this.$parent.$parent.dateOut = this.timeCurrentDay;
        this.$parent.$parent.date.error = null;
        return;
      } else {
        if (this.timeCurrentDay.getTime() == this.dateOut.getTime()) {
          // this.$parent.$parent.dateOut = null;
          // this.$parent.$parent.date.error = null
          return;
        }
      }
      if (this.dateIn && this.dateOut) {
        this.$parent.$parent.dateOut = null;
        this.$parent.$parent.date.error = null;
        this.$parent.$parent.dateIn = this.timeCurrentDay;
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sc-datepickerext-day {
  text-align: center;
  width: 46px;
  min-width: 46px;
  height: 40px;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  // box-sizing: border-box;
  vertical-align: middle;

  .day-wrapper {
    width: 100%;
    height: 100%;
  }
  span {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }

  &.sc-datepickerext-day-employment {
    &__both {
      pointer-events: none;
      color: #999999;
      text-decoration: line-through;
    }
    // &__left,
    &__right {
      span {
        color: #717171;
      }
    }
  }

  &.sc-datepickerext-disabled {
    pointer-events: none;
    color: #c0c3d3;
    text-decoration: line-through;
  }
  &.sc-datepickerext-select {
    // background: #F1F3FB;
    span {
      background: #f1f3fb;
      // background-color: #f6f6f6;
    }
  }
  &.sc-datepickerext-active {
    span {
      background-color: #252525;
      color: white;
      border-radius: 100%;
    }
  }
  &.sc-datepickerext-error {
    span {
      background-color: #d8d8d8;
      color: #717171;
      border-radius: 100%;
    }
  }
  // &:hover {
  //   span {
  //     background-color: #444444;
  //     color: white;
  //     border-radius: 3px;
  //   }
  //   // border-color: rgba(111, 167, 89, 0.75);
  // }
  &.sc-datepickerext-current-date:not(.sc-datepickerext-active) {
    span {
      outline: 1px solid #e7e9f3;
      outline-offset: -1px;
      // position: relative;
      // &:before {
      //   position: absolute;
      //   content: "";
      //   display: block;
      //   width: calc(100% + 1px);
      //   height: calc(100% + 1px);
      //   top: 0;
      //   left: 0px;
      //   border: 1px solid #444;
      //   box-sizing: border-box;
      // }
    }
  }
  @media (max-width: 340px) {
    width: 40px;
    min-width: 40px;
  }
}
.hint-datepicker {
  position: relative;

  &__body {
    display: none;
    position: absolute;
    z-index: 11;
    top: -11px;
    left: 50%;
    padding: 10px 15px;
    background-color: #f6f6f6;
    border: solid 1px #d8d8d8;
    color: #000;
    transform: translate(-50%, -100%);
    max-width: 180px;
    width: 180px;
    width: max-content;
    text-align: left;

    &.left-left {
      transform: translate(-20%, -100%);
    }
    &.left {
      transform: translate(-40%, -100%);
    }
    &.right {
      transform: translate(-60%, -100%);
    }
    &.right-right {
      transform: translate(-80%, -100%);
    }
  }

  &::after,
  &::before {
    display: none;
    content: "";
    backface-visibility: hidden;
    transform: translateZ(0) scale(1, 1) translate(-50%, -60%);
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    z-index: 12;
    border-style: solid;
    border-width: 10px 10px 10px 10px;
    border-color: #f6f6f6 transparent transparent transparent;
  }
  &::before {
    transform: translateZ(0) scale(1, 1) translate(-50%, -55%);
    z-index: 1;
    border-color: #d8d8d8 transparent transparent transparent;
  }

  &.open {
    .hint-datepicker__body,
    &::after,
    &::before {
      display: block;
    }
  }
}
.sc-datepickerext-baloon {
  background-color: #545454;
  -webkit-border-radius: 2px;
  line-height: 1.1;
  border-radius: 2px;
  text-align: left;
  color: #fff;
  font-size: 11px;
  padding: 6px 10px;
  position: absolute;
  left: -2px;
  z-index: 3;
  top: -41px;
  width: 51px;
  white-space: normal;

  &::after {
    content: "";
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #545454;
    bottom: -4px;
    left: 50%;
    margin-left: -4px;
    position: absolute;
  }
}

.sc-datepickerext-day--left + .sc-datepickerext-select,
.sc-datepickerext-select + .sc-datepickerext-day--right,
.sc-datepickerext-select + .sc-datepickerext-baloon,
.sc-datepickerext-select + .sc-datepickerext-select,
.sc-datepickerext-day--left + .sc-datepickerext-day--right {
  .day-wrapper:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #f1f3fb;
    transform: translateX(-50%);
    z-index: -1;
  }
}
</style>
