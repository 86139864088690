import HTTP, { TOKEN } from "../http-common";

/**
 * @description Список опубликованных объектов пользователя
 * 
 * @param int $limit
 * @param int $offset
 * 
 * @access "auth"
 *
 * @module Objects/getObjectsUser
 * @return {object} Promise
 */
export default (params, headers = {}) =>
  HTTP.get("json/objects/getObjectsUser", {
    params,
    headers: {
      "api-version": '1.10',
      ...headers,
      token: TOKEN,
    },
    cache: false,
  });
