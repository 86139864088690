<i18n>
{
  "ru": {
    "from": "Заезд",
    "select": "Выберите",
    "to": "Отъезд"
  },
  "en": {
    "from": "from",
    "select": "Select",
    "to": "to"
  }
}
</i18n>
<template>
  <div class="dates">
    <div
      class="date date-in"
      :class="{ 'active-selected': activeField === 'in' }"
      @click="clickDateIn"
    >
      <div class="date__title regular-p6">
        {{ t("from") }}
      </div>
      <div class="medium-p5" v-if="activeField === 'in' && !dateForamted.in">
        {{ t("select") }}
      </div>
      <template v-if="dateForamted.in">
        <div class="date__date-text medium-p5">{{ dateForamted.in }}</div>
        <button
          class="date__button"
          @click.stop="removeDates"
          v-if="activeField === 'in'"
        ></button>
      </template>
    </div>
    <div
      class="date date-out"
      :class="{ 'active-selected': activeField === 'out' }"
      @click="clickDateOut"
    >
      <div class="date__title regular-p6">
        {{ t("to") }}
      </div>
      <div class="medium-p5" v-if="activeField === 'out' && !dateForamted.out">
        {{ t("select") }}
      </div>
      <template v-if="dateForamted.out">
        <div class="date__date-text medium-p5">{{ dateForamted.out }}</div>
        <button
          class="date__button"
          @click.stop="removeDates"
          v-if="activeField === 'out'"
        ></button>
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { locale, t } = useI18n();

const emits = defineEmits(["update:activeField", "update:dates"]);
const props = defineProps({
  activeField: {
    type: String,
    default: "", // in, out
  },
  dates: {
    type: Object,
    default: function () {
      return {
        in: null,
        out: null,
      };
    },
  },
});

// отображение
const isOneYear = computed(() => {
  if (props.dates.in && props.dates.out) {
    return props.dates.in.getFullYear() === props.dates.out.getFullYear();
  }
  return true;
});
const dateForamted = computed(() => {
  const result = {
    in: "",
    out: "",
  };
  if (props.dates.in) {
    const weekday = props.dates.in.toLocaleString(locale, {weekday: "short"});
    let day = props.dates.in.toLocaleString(locale, {
      day: "numeric",
      month: "short",
    });
    if (day.slice(-1) === ".") {
      day = day.slice(0, -1)
    }
    if (isOneYear.value) {
      result.in = `${day}, ${weekday}`
    } else {
      const year = props.dates.in.toLocaleString(locale, {year: "numeric"});
      result.in = `${day} ${year}, ${weekday}`;
    }
  }
  if (props.dates.out) {
    const weekday = props.dates.out.toLocaleString(locale, {weekday: "short"});
    let day = props.dates.out.toLocaleString(locale, {
      day: "numeric",
      month: "short",
    });
    if (day.slice(-1) === ".") {
      day = day.slice(0, -1)
    }
    if (isOneYear.value) {
      result.out = `${day}, ${weekday}`;
    } else {
      const year = props.dates.out.toLocaleString(locale, {year: "numeric"});
      result.out = `${day} ${year}, ${weekday}`;
    }
  }
  return result;
});

// изменение
function clickDateIn(){
  emits("update:activeField", "in")
}
function clickDateOut(){
  emits("update:activeField", "out")
}
function removeDates(){
  emits("update:dates", {
    in: null,
    out: null
  })
}
</script>

<style lang="scss" scoped>
.dates {
  padding: 0 16px;
  display: flex;
  border-radius: 10px;
}
.date-in,
.date-out {
  width: 50%;
  border: 1px solid #e7e9f3;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50px;
}

.date-in {
  border-radius: 10px 0 0 10px;
  border-right: 0;
}

.date-out {
  border-radius: 0 10px 10px 0;
}

.date {
  &__title {
    color: #5a5d63;
  }

  &__button {
    padding: 0;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    border-radius: 50%;
    border: none;
    background-color: #e7e9f3;

    &::after {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      background: url("~/assets/img/icon/close.svg") no-repeat center;
      background-size: cover;
      transform: translateY(-0.5px);
    }
  }
}

.active-selected {
  background-color: #f8faff;
}
</style>
