import { useGtm } from '@gtm-support/vue-gtm';

/**
 *
 * Карточка объекта - Отправка заявки на странице объекта
 *
 * @module detail
 *
 */

export default function () {
  useGtm().trackEvent({
    event: "step1",
  });
}
