import {
  BButton,
  BContainer,
  BRow,
  BCol,
  BFormInput,
  BImg,
  // BImgLazy, 
} from "bootstrap-vue-next";
// import BFormCheckbox from "@sutochno/checkbox"; //"@/components/base/Form/BaseCheckbox.vue";
// import BFormSelect from "@sutochno/select"; //"@/components/base/Form/BaseSelect.vue";

export default (vueApp) => {
  vueApp.component("b-button", BButton);
  vueApp.component("b-container", BContainer);
  vueApp.component("b-row", BRow);
  vueApp.component("b-col", BCol);
  // vueApp.component("b-form-checkbox", BFormCheckbox);
  // vueApp.component("b-form-select", BFormSelect);
  vueApp.component("b-form-input", BFormInput);
  vueApp.component("b-img", BImg);
  // vueApp.component("b-img-lazy", BImgLazy);
};
