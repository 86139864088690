// import HTTP from "../http-common";
import axios from "axios";

/**
 * Получение данных о странах и городах из файла JSON,
 * который расположен ./public/countries.json
 *
 * @module getLocations
 * @return {object} Promise
 */
export default (locale) => {
  let isExtranet = location?.host.includes("extranet") || location?.host.includes("webview");
  let locationHost = isExtranet
    ? document?.location?.origin
    : process.env.VUE_APP_BASEPATH;
  if (locale == "en") {
    return axios.get(
      `${locationHost}/main_all_locations_en.json`
    );
  } else {
    return axios.get(
      `${locationHost}/main_all_locations_ru.json`
    );
  }
};
