import { event } from "vue-gtag";
/**
 *
 * Отправка данных в аналитику, при клике на кнопку показа новой порции с отзывами
 *
 * @function
 *
 * @param {string} device  - 'desktop' или 'mobile' - тип устройства
 * @param {number} id - id объекта
 *
 */
export default function (device, id) {
  if (!window.gtag || !id) return false;
  if (device === "desktop") {
    event("object_sa", {
      click: "reviews_more",
      value: id,
    });
  } else if (device === "mobile") {
    event("object_sa_m", {
      click: "reviews_more",
      value: id,
    });
  }
}