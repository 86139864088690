import HTTP from "../http-common";

/**
 * Авторизация пользователя по коду
 *
 * @module code
 * @return {object} Promise
 * @example
 */
export default (payload) => {
  let formData = new FormData();
  
  if(payload.phone) formData.append("phone", payload.phone || "");
  if(payload.forgot) formData.append("forgot", payload.forgot || "");
  if(payload.value) formData.append("value", payload.value || "");
  if(payload.code) formData.append("code", payload.code || "");
  if(payload.terms) formData.append("terms", payload.terms || 0);
  if(payload.captcha_code) formData.append("captcha_code", payload.captcha_code || 0);
  if(payload.captcha_value) formData.append("captcha_value", payload.captcha_value || 0);

  return HTTP.post("json/users/code", formData, {
    headers: {
      "api-version": 1, // Если указать версию, то будет минимум данных
    },
  });
};
