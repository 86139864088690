import HTTP from "../http-common";

/**
 * Создание черновика отзыва на объект
 * @module addObjectReviewResponse
 * @param {Number} $messageId
 * @return {Array}
 */
export default (messageId) => {
  let formData = new FormData();
  formData.append("messageId", messageId || "");

  return HTTP.post("json/messages/addObjectReviewResponse", formData, {
    headers: {
      "api-version": String(null),
    },
    cache: false,
  });
};
