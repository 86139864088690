// /orders/getOrderDraft?draftCode=EK8RY5kwV&token=mJVruGMgV39AkYqSfFML8Q178283272
import HTTP, { TOKEN } from "../http-common";
import { formatWithTime } from "@/utils/format";

/**
 * Получения данных о свободных заявках
 *
 * @module getOffersList
 * @param {object} payload Данные для свободных заявок
 * @param {string} payload.hotId Идентификатор offers
 * @return {object} Promise
 */

export default (payload) => {
  let objectData = new FormData();
  objectData.append("hotId", payload.hotId || "");

  return HTTP.post("json/offers/getOffersList", objectData, {
    headers: {
      "api-version": 1.8, // Если указать версию, то будет минимум данных
      token: TOKEN,
    },
  });
};
