<template>
  <div class="pageModal">
    <div class="header">
      <div class="back" @click.stop="emits('back')">
        <img :src="getSrc" alt="" />
      </div>
      <div class="header__main">
        <slot name="header"></slot>
      </div>
    </div>
    <div class="main">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import ArrowBack from '~/assets/img/arrow-back.svg';
import CloseWhiteIcon from '~/assets/img/close-white-icon.svg';

const emits = defineEmits(["back"]);
const props = defineProps({
  closeArrow: Boolean,
});

const getSrc = computed(() => {
  return props.closeArrow
    ? ArrowBack
    : CloseWhiteIcon;
});
</script>

<style lang="scss" scoped>
.pageModal {
  position: fixed;
  left: 0;
  top: 0;
  width: calc(100vw + 1px);
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #1c1c1c;
  z-index: 1000;

  .header {
    color: #fff;
    display: grid;
    grid-template-columns: 50px auto;
    grid-template-rows: auto 1fr;
    column-gap: 10px;

    &__main {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &::after {
        content: "";
        display: block;
        width: 50px;
        flex-shrink: 20px;
      }
    }
  }
  .back {
    position: relative;
    min-height: 50px;
    height: 100%;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
      content: "";
      width: 1px;
      height: calc(100% - 16px);
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background: #ffffff08;
    }
  }
  .main {
    border-radius: 20px 20px 0 0;
    background: #ffffff;
    width: 100%;
    height: 100%;
  }
}
</style>
