import HTTP from "../http-common";

/**
 * Получение данных об организации, по реквизитам ИНН и БИК
 *
 * @module suggest_company
 * @param {object} payload Данные об организации, по реквизитам ИНН и БИК
 * @param {string} payload.orderCode Сгенерированный хэш, генерируется в ответе от updateOrderDraft и в query
 * @param {string} payload.inn ИНН организации
 * @param {string} payload.bik БИК организации
 * @return {object} Promise
 */

export default (payload) => {
  let objectData = new FormData();
  objectData.append("orderCode", payload.orderCode || "");
  objectData.append("params[inn]", payload.inn || "");
  objectData.append("params[bik]", payload.bik || "");

  return HTTP.post("json/users/suggest_company", objectData, {
    headers: {
      // "api-version":  // Если указать версию, то будет минимум данных
    },
  });
};
