<template>
  <div class="main__notfound">
    <Header />

    <BaseNotFound />

    <Footer v-if="!isNotShowFooter" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Header } from "@sutochno/header";
import { Footer } from "@sutochno/footer";
import BaseNotFound from "@/components/base/BaseNotFound.vue";

export default {
  components: {
    Header,
    Footer,
    BaseNotFound,
  },
  computed: {
    ...mapState("user", ["whitelabel"]),
    isNotShowFooter() {
      return this.whitelabel && (
        this.whitelabel.name === "utair"
        || this.whitelabel.name === "aeroflot"
        || this.whitelabel.name === "leveltravel"
        || this.whitelabel.name === "pobeda"
      );
    },
  }
};
</script>

<style lang="scss" scoped>
.main__notfound {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: #f1f3fb;
}
</style>
