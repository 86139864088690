import { event } from "vue-gtag";
/**
 *
 * Отправка данных в аналитику, при клике на "полезно" или "бесполезно"
 *
 * @function
 *
 * @param {string} device  - 'desktop' или 'mobile' - тип устройства
 * @param {string} benefit - 'plus' или 'minus' - полезность
 * @param {number} id - id отзыва
 *
 */
export default function (device, benefit, id) {
  if (!window.gtag || !id) return false;
  if (device === "desktop") {
    if (benefit === "plus") {
      event("object_sa", {
        click: "review_plus",
        value: id,
      });
    } else if (benefit === "minus") {
      event("object_sa", {
        click: "review_minus",
        value: id,
      });
    }
  } else if (device === "mobile") {
    if (benefit === "plus") {
      event("object_sa_m", {
        click: "review_plus",
        value: id,
      });
    } else if (benefit === "minus") {
      event("object_sa_m", {
        click: "review_minus",
        value: id,
      });
    }
  }
}