import HTTP from "../http-common";

/**
 * Подтверждение контакта
 *
 * @module users
 *
 * @return {object} Promise
 *
 */
export default (ids) => {

  let formData = new FormData();
  ids.map(item => {
    formData.append("ids[]", item);
  })

  return HTTP.post(
    "/json/users/getUsersSearchParams/", 
    formData, 
    {
      headers: {
        "api-version": "1.10"
      },
      cache: false,
    }
  );
};
