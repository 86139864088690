import { Orders, Payments, Users } from "@/components/api/";

const state = () => ({
  order: null,
  orderLoading: false,
  dataWithBonuses: null,
  dataWithoutBonuses: null,
  paymentLoading: false,
  payOrder: null,
  payOrderLoading: false,
  isBonuses: false,
  createpaymentFormData: null,
  invoiceForId: null,
  invoiceStatus: null,
});

const getters = {
  payment: (state) => {
    return state.isBonuses ? state.dataWithBonuses : state.dataWithoutBonuses;
  },
  prepaymentReserved: (state) => {
    return state.invoiceStatus === 'hold' || state.invoiceStatus === 'checked';
  },
};

const actions = {
  getGuestOrderData({ commit }, formData) {
    commit("setOrderLoading", true);
    return Orders.getGuestOrderData(formData)
      .then(response => {
        const data = response?.data?.data;
        if (data.order.status_title === "Забронировано") {
          // const hostname = location.hostname.split('.');
          // const domain = hostname.length === 1 ? "sutochno.ru" : hostname.length === 2 ? location.hostname : `${hostname[1]}.${hostname[2]}`;
          // location.href = `//${domain}/cabinet/orders`;
          location.href = `https://sutochno.ru/spay/${data?.order?.id}/success`;
          return false;
        }
        commit("setOrder", data?.order || null);
        return response;
      })
      .catch(error => {
        const hostname = location.hostname.split('.');
        const domain = hostname.length === 1 ? "sutochno.ru" : hostname.length === 2 ? location.hostname : `${hostname[1]}.${hostname[2]}`;
        location.href = `//${domain}/cabinet/orders`;
        console.log(`getGuestOrderData: ${error}`);
      })
      .finally(() => {
        commit("setOrderLoading", false);
      });
  },

  calculatePrecostPrice({ commit }, payload) {
    const formData = new FormData();
    formData.append("orderId", payload.orderId);
    formData.append("currencyId", payload.currencyId);
    formData.append("useBonuses", payload.useBonuses);
    if (payload.isLoading) {
      commit("setPaymentLoading", true);
    }
    return Orders.calculatePrecostPrice(formData)
      .then(response => {
        if (payload.useBonuses) {
          commit("setDataWithBonuses", response.data?.data?.payment || null);
        } else {
          commit("setDataWithoutBonuses", response.data?.data?.payment || null);
        }
        return response;
      })
      .catch(error => console.log(`calculatePrecostPrice: ${error}`))
      .finally(() => {
        commit("setPaymentLoading", false);
      });
  },

  payOrder({ commit }, formData) {
    commit("setPayOrderLoading", true);
    return Orders.payOrder(formData)
      .then(response => {
        commit("setPayOrder", response.data?.data || null);
        return response;
      })
      .catch(error => console.log(`payOrder: ${error}`))
      .finally(() => {
        commit("setPayOrderLoading", false);
      });
  },

  cancelOrderForChangeParams({ commit }, formData) {
    return Orders.cancelOrderForChangeParams(formData)
      .then(response => {
        return response;
      })
      .catch(error => console.log(`cancelOrderForChangeParams: ${error}`));
  },

  createOrderDraft({ commit }, formData) {
    return Orders.createOrderDraft(formData)
      .then(response => {
        return response;
      })
      .catch(error => console.log(`createOrderDraft: ${error}`));
  },

  cancelInvoiceForByInvoice({ commit }, formData) {
    return Payments.cancelInvoiceForByInvoice(formData)
      .then(response => {
        return response;
      })
      .catch(error => console.log(`cancelInvoiceForByInvoice: ${error}`));
  },

  testPayedBonus({ commit }, formData) {
    return Payments.testPayedBonus(formData)
      .then(response => {
        return response;
      })
      .catch(error => console.log(`testPayedBonus: ${error}`));
  },

  createpayment({ commit }, formData) {
    return Payments.createpayment(formData)
      .then(response => {
        return response;
      })
      .catch(error => console.log(`createpayment: ${error}`));
  },

  addContact({ commit }, formData) {
    return Users.addContact(formData)
      .then(response => {
        return response;
      })
      .catch(error => console.log(`addContact: ${error}`));
  },

  setProperties({ commit }, formData) {
    return Orders.setProperties(formData)
      .then(response => {
        return response;
      })
      .catch(error => console.log(`setProperties: ${error}`));
  },

  checkInvoiceforByInvoice({ commit }, formData) {
    return Payments.checkInvoiceforByInvoice(formData)
      .then(response => {
        return response;
      })
      .catch(error => console.log(`checkInvoiceforByInvoice: ${error}`));
  },

}

const mutations = {
  setOrder(state, value) {
    state.order = value;
  },
  setOrderLoading(state, value) {
    state.orderLoading = value;
  },
  setDataWithBonuses(state, value) {
    state.dataWithBonuses = value;
  },
  setDataWithoutBonuses(state, value) {
    state.dataWithoutBonuses = value;
  },
  setPaymentLoading(state, value) {
    state.paymentLoading = value;
  },
  setPayOrder(state, value) {
    state.payOrder = value;
  },
  setPayOrderLoading(state, value) {
    state.payOrderLoading = value;
  },
  setIsBonuses(state, value) {
    state.isBonuses = value;
  },
  setCreatepaymentFormData(state, value) {
    state.createpaymentFormData = value;
  },
  setInvoiceForId(state, value) {
    state.invoiceForId = value;
  },
  setInvoiceStatus(state, value) {
    state.invoiceStatus = value;
  },
};

export default {
  namespaced: true,
  namespace: "payments",
  state,
  getters,
  actions,
  mutations,
};
