import getOrdersByObject from "./getOrdersByObject";
import updateOrderDraft from "./updateOrderDraft";
import getOrderDraft from "./getOrderDraft";
import bookingTo from "./bookingTo";
import createOrderDraft from "./createOrderDraft";
import addOrderMessage from "./addOrderMessage";
import getOrderForLeavingReview from "./getOrderForLeavingReview";
import calculatePrecostPrice from "./calculatePrecostPrice";
import getGuestOrderData from "./getGuestOrderData";
import payOrder from "./payOrder";
import cancelOrderForChangeParams from "./cancelOrderForChangeParams";
import setProperties from "./setProperties";

/**
 * Работа с пользовательскими данными
 *
 * @module Users
 */
export default {
  getOrdersByObject,
  updateOrderDraft,
  getOrderDraft,
  bookingTo,
  createOrderDraft,
  addOrderMessage,
  getOrderForLeavingReview,
  calculatePrecostPrice,
  getGuestOrderData,
  payOrder,
  cancelOrderForChangeParams,
  setProperties,
};
