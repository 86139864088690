/**
@module sutochnoUIComponents
@description тут происходит импортирование и подключение компонентов
*/
import Select from "@sutochno/select";
import InputQuantity from "@sutochno/inputQuantity";
import Checkbox from "@sutochno/checkbox";
import Switcher from "@sutochno/switcher";
import ToggleBlock from "@sutochno/toggleblock";
import Radio from "@sutochno/radio";
import Input from "@sutochno/input";
import { VClosePopper, VTooltip, Dropdown, Menu } from "floating-vue";
import { VueMaskDirective } from "v-mask";
import { ObserveVisibility } from "vue-observe-visibility";
// import vClickOutside from "@sutochno/v-click-outside";
// import Button from "@sutochno/button";
import initBootstrap from "./directives/bootstrap.js";
import initDirectives from "./directives/directives";
 
 // TODO: переписать v-tooltip https://floating-vue.starpad.dev/migration/migration-from-v2.html
export default defineNuxtPlugin(({ vueApp }) => {
  initBootstrap(vueApp);
  // directives
  vueApp.directive("tooltip", VTooltip);
  vueApp.directive("close-popper", VClosePopper);
  vueApp.directive("mask", VueMaskDirective);
  vueApp.directive("observe-visibility", ObserveVisibility);
  // Регистрируем глобальную пользовательскую директиву `v-focus`
  // vueApp.directive("click-outside", vClickOutside);
  initDirectives(vueApp);

  // components
  vueApp.component("Select", Select);
  vueApp.component("b-form-select", Select);
  vueApp.component("InputQuantity", InputQuantity);
  vueApp.component("Checkbox", Checkbox);
  vueApp.component("b-form-checkbox", Checkbox);
  vueApp.component("Switcher", Switcher);
  vueApp.component("ToggleBlock", ToggleBlock);
  vueApp.component("Radio", Radio);
  vueApp.component("Input", Input);
  // vueApp.component("b-form-input", Input);
  vueApp.component("VDropdown", Dropdown);
  vueApp.component("VMenu", Menu);
  // vueApp.component("b-button", Button);
});