export default function (date) {
  const formatter = new Intl.DateTimeFormat("ru", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: 'numeric',
    minute: 'numeric',
  });
  return formatter.format(date);
}
