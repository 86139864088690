import getRates from "./getRates";

/**
 * Работа с валютой
 *
 * @module Currencies
 */
export default {
    getRates,
};
