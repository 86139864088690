import HTTP from "../http-common";

// Отменяем бронь

export default (formData) =>
  HTTP.post("json/orders/setProperties", formData, {
    headers: {
      "api-version": "null",
    },
    cache: false,
  });
