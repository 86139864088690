const isDev = process.env.NODE_ENV === "development";
const METRIKA_ENV_DOMAIN = process.env.VUE_APP_API_ADDRESS.replace("/api", "").replace("://", "://metrika.");

export default async (data) => {
  if(process.server) return;
  const params = new URLSearchParams(data);
  const { protocol, hostname } = document.location;
  const DOMAIN = hostname.match(/([a-zA-Z0-9-]+)(\.[a-zA-Z]{2,5})?(\.[a-zA-Z]+$)/gm)?.[0] || METRIKA_ENV_DOMAIN;
  const targetURL = new URL(protocol + "metrika." + DOMAIN);

  if (isDev) {
    await fetch("/metrika.sutochno.me?" + params);
  } else {
    await fetch(`${targetURL}?${params}`);
  }
};