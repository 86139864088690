import HTTP, { TOKEN } from "../http-common";
/**
 * Получение списка локаций
 *
 * @module getTermSuggestions
 * @return {object} Promise
 */
let cancelSource = null;
export default (query) => {
  if (cancelSource) {
    cancelSource.cancel("Operation canceled by the user.");
  }
  // cancelSource = CancelToken.source();
  return HTTP.get("rest/search/getTermSuggestions?query=" + query, {
    headers: {
      "api-version": String(null), // Если указать версию, то будет минимум данных
      token: TOKEN,
    },
    // cancelToken: cancelSource.token
  }).then((v) => v.data.data.suggestions);
};
