<i18n lang="json">
{
  "ru": {
    "guests": "Гости | {n} гость | {n} гостя | {n} гостей",
    "adult": "0|{n} взрослый | {n} взрослых | {n} взрослых",
    "child": " без детей | {n} ребёнок | {n} ребёнка | {n} ребёнка",
    "children": "{n} детей",
    "withPets": "питомец",
    "done":"Готово"
  },
  "en": {
    "guests": "Guests",
    "adult": "0|{n} adult | {n} adult | {n} adult",
    "child": " without children |{n} child | {n} child's | {n} child's",
    "children": "{n} children's",
    "withPets": "pet",
    "done":"Done"
  }
}
</i18n>
<template>
  <div
    v-click-outside="close"
    class="select-guests"
    :class="{
      active: isOpenGuestsSelect,
    }"
  >
    <button
      class="select-guests--btn"
      :class="{
        active: isOpenGuestsSelect,
        'active-simple': isOpenGuestsSelect,
      }"
      @click="showSelect()"
    >
      <div class="title">
        {{ t("guests", 0) }}
      </div>
      <div class="body" ref="bodyText">
        {{ getGuestsText }}
        <span v-if="!guestsChildrensCount && !isPets">
          {{ t("child", 0) }}
        </span>
      </div>
    </button>
    <transition name="select-guests--fade">
      <div
        class="select-guests__main tmp-font--medium"
        id="sel"
        v-if="isOpenGuestsSelect"
      >
        <div class="select-guests__main-wrapper" ref="selectGuests">
          <LightGuestOptions
            :guests="guests"
            @changeChildren="changeChildren"
            @changeAdults="changeAdults"
            @changePetsValue="changePetsValue"
          />
        </div>
        <div class="button-wrapper">
          <UIButton @click="save" buttonTheme="dark">
            {{ t("done") }}
          </UIButton>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import LightGuestOptions from "@/components/base/SelectGuestsCopy/LightGuestOptions";
import UIButton from "@/components/UIKit/UIButton";
import { mapState, mapMutations } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "SelectGuestsDesktop",
  components: {
    LightGuestOptions,
    UIButton,
  },
  data() {
    return {
      isOpenGuestsSelect: false,
    };
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  computed: {
    ...mapState("search", ["guests"]),
    guestsAdults() {
      return this.guests?.adults || 0;
    },
    guestsChildrens() {
      return this.guests?.childrens || [];
    },
    guestsChildrensCount() {
      return this.guestsChildrens.length;
    },
    isPets() {
      return this.guests.pets?.value || false;
    },
    getGuestsText() {
      let result = this.t("adult", this.guestsAdults);
      if (this.guestsChildrensCount) {
        let children =
          this.guestsChildrensCount >= 5
            ? this.t("children", { n: this.guestsChildrensCount })
            : this.t("child", this.guestsChildrensCount);
        result += ", " + children;

        if (this.isPets) {
          result += ", ..."
        }
      }

      if (!this.guestsChildrensCount && this.isPets) {
        result += ", " + this.t("withPets");
      }
      if(!this.guestsChildrensCount && !this.isPets){
        result += ","
      }
      return result;
    },
  },
  watch: {
    isOpenGuestsSelect(val) {
      this.setGuestFocused(val);
    },
  },
  methods: {
    ...mapMutations("search", ["setGuestFocused", "setChildrenWithoutAgeError"]),
    showSelect(val) {
      this.isOpenGuestsSelect = val ? val : !this.isOpenGuestsSelect;
      this.$emit("clickGuests");
    },
    close(evt) {
      if (evt.target?.closest(".btn-search-main")?.classList?.contains('btn-search-main')) {
        return;
      }
      this.isOpenGuestsSelect = false;
    },
    save() {
      if(this.guestsChildrens.includes(null)) {
        this.setChildrenWithoutAgeError(true);
        return;
      }
      this.setChildrenWithoutAgeError(false);
      if (this.$router.currentRoute.value.name === "Search") {
        setTimeout(() => {
          this.$emit("closeGuest");
        }, 0);
      }

      this.isOpenGuestsSelect = false;
    },
    changeChildren(val) {
      this.$store.dispatch("search/updateChildrens", val);
    },
    changeAdults(val) {
      this.$store.dispatch("search/updateAdults", val);
    },
    changePetsValue(val) {
      this.$store.dispatch("search/updatePets", {
        value: val,
        description: this.guests?.pets?.description || "",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.select-guests {
  position: relative;
  width: 100%;
  &.active {
    background: #f8faff;
  }

  &--fade-enter-active,
  &--fade-leave-active {
    opacity: 1;
    transition: opacity 0.15s ease;
  }
  &--fade-enter,
  &--fade-leave-to {
    opacity: 0;
  }

  &--btn {
    padding: 12px 46px 12px 20px;
    height: 60px;
    width: 100%;
    min-width: 264px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
    margin: 0;
    outline-style: none;
    background: #fff;
    line-height: 1;
    color: #000;
    border: none;
    border-radius: 0px 2px 2px 0px;
    position: relative;
    white-space: nowrap;
    box-shadow: inset 0 0 0 1px #fff;
    transition: box-shadow 0.3s;
    .title {
      font-size: 14px;
    }
    .body {
      width: 100%;
      text-align: left;
      span {
        &.empty {
          color: #717171;
          padding-right: 10px;
        }
      }
    }
    &.active-simple {
      .ico-arrow {
        transform: translateY(-50%) scaleY(-1);
      }
    }
    .ico-arrow {
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);
      transition: transform 0.3s;
      font-size: 14px;
    }
    &.active {
      box-shadow: inset 0 0 0 1px #f51449;
    }
    &.page-inner {
      flex-direction: row;
      justify-content: flex-start;
    }
  }
  &__main {
    
    position: absolute;
    top: 100%;
    z-index: 4;
    width: 280px;
    margin-top: 4px;
    background: #ffffff;
    box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.08),
      0px 4px 15px rgba(20, 45, 136, 0.06);
    border-radius: 20px;
    
  }
  &__main-wrapper{
    padding: 15px 20px 100px;
    position: relative;
    width: 100%;
    height: 100%;
  }
  &.page-search {
    .select-guests--btn {
      height: 60px;
      flex-direction: row;
      justify-content: flex-start;
      min-width: 100%;
      transition: border 0.3s;
      &.select-guests_current {
        color: #000;
        border: 1px solid #000;
      }
    }
  }
  &.page-search,
  &.page-hotel {
    .select-guests--btn {
      height: 42px;
      border: 1px solid #000000;
      border-radius: 3px;
      outline: 0;
      box-shadow: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      // padding: 15px 20px;
    }
    .select-guests--main {
      margin-left: 0;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.15);
      border-radius: 8px;
    }
  }
  &.page-hotel {
    margin-left: 20px;
    .select-guests--main {
      margin-top: 4px;
    }
    .select-guests--btn {
      height: 42px;
    }
  }
  &.page-detail {
    .select-guests--btn {
      height: 42px;
      border: 1px solid #d1d1d1;
      border-radius: 3px;
      font-size: 14px;
      padding: 12px 46px 12px 15px;
    }
    .select-guests--main {
      outline: 1px solid #d1d1d1;
      margin-left: 0;
      margin-top: 3px;
      left: auto;
      right: 0;
      width: 100%;
    }
  }
}
.error {
  border: 1px solid #d12d1e;
  animation: blink 0.4s ease-in-out 4 alternate;
}
.error-text {
  margin-top: 5px;
  color: #d12d1e;
  font-size: 12px;
  line-height: 16px;
}
@keyframes blink {
  from {
    background-color: #fff;
  }
  to {
    background-color: #ffe8e8;
  }
}
::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  width: 4px;
  background: transparent;
  border-radius: 3px;
  box-shadow: inset 0 0 0 2px grey;
  border: 3px solid transparent;
}

.button-wrapper {
  bottom: 0;
  left: 0;
  padding: 20px 20px;
  position: absolute;
  width: 100%;
  z-index: 20;
  border-top: 1px solid #E7E9F3;
  background: #fff;
  border-radius: 0 0 20px 20px;
}
</style>
