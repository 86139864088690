const cookieParams = {
  mounted() {
    //поиск дат в url
    const URL_PARAMS = new URLSearchParams(window.location.search);
    //подстановка дат в store
    if (URL_PARAMS.has("occupied")) {
      const OCCUPIED = URL_PARAMS.get("occupied").split(/\s*;\s*/);
      if (!isNaN(Date.parse(OCCUPIED[0])) && !isNaN(Date.parse(OCCUPIED[1]))) {
        this.$store.dispatch("search/setCalendarDates", {
          checkIn: new Date(OCCUPIED[0]),
          checkOut: new Date(OCCUPIED[1]),
        });
      }
    } else {
      //restore dates and guests from cookies
      const COOKIE_DATES = this.$cookies.get("calendar_dates");
      if (
        typeof COOKIE_DATES == "object" &&
        COOKIE_DATES != null &&
        COOKIE_DATES != "null"
      ) {
        let { date_begin, date_end, term } = COOKIE_DATES;
        if (!isNaN(Date.parse(date_begin)) && !isNaN(Date.parse(date_end))) {
          this.$store.commit("search/setTerm", this.$route.query?.term || term);
          
          this.$store.dispatch("search/setCalendarDates", {
            checkIn: new Date(date_begin),
            checkOut: new Date(date_end),
          });
        }
      }
    }
  },
};

export { cookieParams };
