import getCommonParams from "@/utils/sutochnoMetrika/commonParams";
import dateFormatter from "@/utils/dateFormatter";
import { Metrika } from "@/components/api";
import removeEmptyProperties from "@/utils/removeEmptyProperties";

export default async function (
  action = "",
  screenBlock = "",
  {
    objectPosition,
    objectIdsArray,
    userID,
    dateIn,
    dateOut,
    filters,
    guests,
    city,
    cityID,
    term,
    page,
  } = {
    objectPosition: null,
    objectIdsArray: [],
    userID: null,
    dateIn: "",
    dateOut: "",
    filters: "",
    guests: "",
    city: "",
    cityID: null,
    term: "",
    page: "",
  }
) {
  let commonParams = await getCommonParams()
  let data = {
    ...commonParams,
    a: action,
    d1: dateFormatter(dateIn),
    d2: dateFormatter(dateOut),
    f: JSON.stringify(filters),
    g: JSON.stringify(guests),
    city: city,
    c: cityID,
    term,
    page,
  };

  if (objectPosition && objectPosition.length) {
    data["p"] = objectPosition;
  }

  if (objectIdsArray && objectIdsArray.length) {
    data["o"] = objectIdsArray;
  }

  if (userID) {
    data["u"] = userID;
  }

  if (screenBlock) {
    data["b"] = screenBlock;
  }

  data = removeEmptyProperties(data);

  await Metrika(data);
}
