import HTTP from "../http-common";

/**
 * Получение данных об объекте
 *
 * @module update_object_review_draft
 *
 * @return {object} Promise
 *
 */
export default (formData) => {
  return HTTP.post("json/messages/update_object_review_draft", formData, {
    headers: {
      "api-version": String(null),
    },
    cache: false,
  });
};
