import VueGtag from "vue-gtag";
import { whiteLabelInfo } from "@sutochno/whitelabel";

const isProd = process.env.NODE_ENV === "production";

export default defineNuxtPlugin(({ vueApp }) => {

  let VueGtagIncludes = [];

  if (whiteLabelInfo.name === "s7") {
    VueGtagIncludes.push({
      id: "G-8ZRKYG233B",
    })
  }


  vueApp.use(
    VueGtag,
    {
      enabled: isProd,
      includes: VueGtagIncludes,
      config: {
        id: "G-B0K4L0V6J8",
      },
    },
  );
});