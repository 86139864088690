import {useStore} from "vuex";
import {computed} from "vue";

function useIsS7 () {
  const store = useStore();
  return computed(() => {
    const wl = store.state.user.whitelabel
    return wl && wl.name === "s7";
  });
};

export default useIsS7;
