import getTermSuggestions from "./getTermSuggestions";
import getTermSuggestionsWithBoundingBox from "./getTermSuggestionsWithBoundingBox";
import searchObjectsPr from "./searchObjectsPr";
import getTotalFound from "./getTotalFound";
import getFilter from "./getFilter";
import searchObjectsByLocation from "./searchObjectsByLocation";
import searchObjects from "./searchObjects";

/**
 * Подтягивание поисковых методов
 *
 * @module Search
 */
export default {
  getTermSuggestions,
  getTermSuggestionsWithBoundingBox,
  searchObjectsPr,
  getTotalFound,
  getFilter,
  searchObjectsByLocation,
  searchObjects
};
