<i18n lang="json">
{
  "ru": {
    "get_bonus": "Получить бонус",
    "register_to_receive": "Зарегистрируйтесь, чтобы получить",
    "bonus_sum": "бонус 500 ₽",
    "on_booking": " на бронирования!",
    "congratulations_you_have_received_bonus": "Поздравляем, вам начислен бонус 500 ₽!",
    "choose_property": "Выбирайте жильё и используйте бонус для внесения предоплаты по бронированиям.",
    "register_and_get": "Зарегистрируйтесь и получите ",
    "on_booking2": " на бронирования!",
    "congratulations_you_have_received": "Поздравляем, вам начислен",
    "get": "Получить"
  },
  "en": {
    "get_bonus": "Get bonus",
    "register_to_receive": "Register to receive",
    "bonus_sum": " bonus 500 ₽",
    "on_booking": " on booking!",
    "congratulations_you_have_received_bonus": "Congratulations, you have received a bonus of 500 ₽!",
    "choose_property": "Choose a property and use the bonus to prepay your reservations.",
    "register_and_get": "Register and get ",
    "on_booking2": " on booking!",
    "congratulations_you_have_received": "Congratulations, you have received",
    "get": "Get"
  }
}
</i18n>

<template>
  <div
    class="friend-bonus-wrapper"
    :class="{
      'active':(isBonusesAccrued && viewPort == 'mobile') || isRegistrationBonus && viewPort == 'mobile',
      'hide': hideMapRoute
    }"
  >
    <template v-if="viewPort == 'desktop'">
      <template v-if="isRegistrationBonus">
        <div class="friend-bonus" v-if="isBlockFriend">
          <div class="friend-bonus__inner">
            <div class="friend-bonus__text">
              {{ t("register_to_receive") }} <strong>{{ t("bonus_sum") }}</strong> {{ t("on_booking") }}
            </div>
            <button class="friend-bonus__button" @click="showRegistration">
              {{ t("get_bonus") }}
            </button>
          </div>
          <div class="btn-close" @click="hidePanelFriend"></div>
        </div>
        <div class="btn-gift" v-else @click="showPanelFriend">
          <img src="~/assets/img/gift.svg" alt="">
        </div>
      </template>
      <div class="friend-bonus success" v-if="isBonusesAccrued">
        <div class="friend-bonus__inner">
          <div class="friend-bonus__text">
            <strong>{{ t("congratulations_you_have_received_bonus") }}</strong><br>
            {{ t("choose_property") }}
          </div>
        </div>
        <div class="btn-close" @click="closeBonusePanel"></div>
      </div>
    </template>

    <template v-else>
      <div class="friend-bonus-mobile" v-if="isRegistrationBonus" @click="showRegistration">
        <div class="friend-bonus-mobile__text">
          {{ t("register_and_get") }} <br> <strong>{{ t("bonus_sum") }}</strong> {{ t("on_booking2") }}
        </div>
        <button class="friend-bonus-mobile__button">
          {{ t("get") }}
        </button>
      </div>
      <transition name="slide-top">
        <div class="friend-bonus-success-mobile" v-if="isBonusesAccrued">
          <div class="friend-bonus-success-mobile__text">
            {{ t("congratulations_you_have_received") }} <strong>{{ t("bonus_sum") }}</strong>
          </div>
          <div class="btn-close" @click="closeBonusePanel"></div>
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "FriendBonus",
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      isBonusesAccrued: false,
      isRegistrationBonus: false,
      isBlockFriend: true,
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    isBlockFriend(val) {
      this.setPanelFriend(val)
      this.$cookies.set("panel-friend", val, "1d")
    },
    isAuth() {
      this.init()
    },
    $route() {
      if (this.isAuth && this.balanceUser === 1000) {
        this.closeBonusePanel()
      }
    }
  },
  computed: {
    ...mapState("user", ["viewPort", "isAuth", "userData"]),
    ...mapState("search", ["switchMap"]),
    balanceUser() {
      if (this.userData.balance) {
        return Number(this.userData.balance.split('<span')[0].replace(/\s/g, ''))
      } else {
        return 0
      }
    },
    hideMapRoute() {
      return ((this.$route.name === 'Search' && this.$route.hash === '#map') || this.switchMap) ? true : false
    }
  },
  methods: {
    ...mapMutations("application", ["setPanelFriend"]),
    showRegistration() {
      const event = new CustomEvent('OPEN_REGISTER_BY_TELEPHONE')
      document.dispatchEvent(event);
    },
    hidePanelFriend() {
      this.isBlockFriend = false
    },
    showPanelFriend() {
      this.isBlockFriend = true
    },
    closeBonusePanel() {
      this.$cookies.remove("friend")
      this.isBonusesAccrued = false
      this.setPanelFriend(false)
    },
    init() {
      // Зарегистрируйтесь, чтобы получить бонус
      if (this.isAuth === false) {
        this.isRegistrationBonus = true
        this.isBonusesAccrued = false

        // состояние плашки (закрыта-открыта)
        if (this.$cookies.get("panel-friend")) {
          this.isBlockFriend = (this.$cookies.get("panel-friend") === 'true') ? true : false
        }
        this.setPanelFriend(this.isBlockFriend)
      }

      // Поздравляем, вам начислен бонус
      if (this.isAuth && this.balanceUser === 1000) {
        this.isRegistrationBonus = false
        this.isBonusesAccrued = true
        this.setPanelFriend(true)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.slide-top-enter-active, .slide-top-leave-active {
  transition: transform .3s;
}
.slide-top-enter, .slide-top-leave-to {
  transform: translate(0, -60px);
}

.friend-bonus-wrapper {
  height: 0;
  transition: height 0.3s ease;
  &.active {
    height: 60px;
  }
  &.hide {
    display: none;
  }
}
.friend-bonus {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #f0f8ff;
  z-index: 100000;
  font-size: 14px;
  color: #000000;
  overflow: hidden;
  &.success {
    background-color: #6fa759;
    color: #ffffff;
    .btn-close {
      background: #588e2f;
      &:after, &:before {
        background: #fff;
      }
    }
  }
  &__inner {
    width: 100%;
    max-width: 952px;
    height: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &:after, &:before {
      content: '';
      width: 260px;
      position: absolute;
      top: 0;
      bottom: 0;
    }
    &:after {
      left: 0;
      background: url('~/assets/img/confetti-1.svg') 0 0 no-repeat;
    }
    &:before {
      right: 0;
      background: url('~/assets/img/confetti-2.svg') 0 0 no-repeat;
    }
  }
  &__text {
    position: relative;
    z-index: 10;
    text-align: center;
  }
  &__button {
    cursor: pointer;
    outline-style: none;
    border-radius: 3px;
    background: #fff;
    border: none;
    padding: 7px 20px;
    margin: 0 0 0 20px;
    position: relative;
    z-index: 10;
  }
}

.btn-close {
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-color: #c9dcf0;
  position: absolute;
  top: 23px;
  right: 20px;
  &:after, &:before {
    content: '';
    display: block;
    width: 14px;
    height: 1px;
    background: #464646;
    position: absolute;
    top: 0;
    left: 0;
    margin: 7px 0 0 1px;
  }
  &:after {
    transform: rotate(45deg);
  }
  &:before {
    transform: rotate(-45deg);
  }
}

.btn-gift {
  cursor: pointer;
  position: fixed;
  bottom: 30px;
  right: 20px;
  z-index: 100000;
  width: 60px;
  height: 60px;
  border-radius: 3px;
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.25);
  background-color: #2d6cb4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.friend-bonus-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background: #f0f8ff url('~/assets/img/confetti-3.svg') 156% -12px no-repeat;
  color: #000000;
  font-size: 13px;
  padding: 0 20px;
  &__text {
    line-height: 1.4;
  }
  &__button {
    cursor: pointer;
    outline-style: none;
    border-radius: 3px;
    background: #fff;
    border: none;
    padding: 7px 14px;
    margin: 0;
    font-size: 14px;
  }
}
.friend-bonus-success-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  background: #6fa759 url('~/assets/img/confetti-3.svg') 156% -12px no-repeat;
  color: #fff;
  font-size: 13px;
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  .btn-close {
    background: #588e2f;
    &:after, &:before {
      background: #fff;
    }
  }
}
</style>
