<i18n lang="json">
{
	"ru": {
		"label": "Курорт, город или адрес",
    "where": "Куда едем"
	},
	"en": {
    "where": "Where"
	}
}
</i18n>
<template>
  <div
    v-click-outside.mousedown="clickOutside"
    class="suggest-wrapper"
    :class="{ 'suggest-wrapper--active': visibleList }"
  >
    <div class="input-wrapper" @click="inputClickHandler">
      <span class="input-clear-btn" v-if="query && visibleList"></span>
      <input
        v-show="true"
        ref="input"
        id="suggest"
        v-model="query"
        class="suggest-input"
        :class="{ 'suggest-input--fill': query && query.length }"
        :placeholder="visibleList ? '' : t('where')"
        autocomplete="off"
        type="text"
        name="suggest"
        @focus="onFocus"
      />
      <label class="suggest-label tmp-font--average" for="suggest">{{
        t("label")
      }}</label>
    </div>
    <!-- <div class="suggest-close icon-app-cross-min" @click="onClear"></div> -->
    <div class="list-wrapper" ref="list">
      <List
        v-if="visibleList"
        class="suggest-list"
        :value="query"
        :is-complete="true"
        :longList="longList"
        @choice="onChoice"
      />
    </div>
    <ModalReload :show="listSuggestions.isError" />
  </div>
</template>
<script>
import List from "./List";
import { mapState, mapGetters, mapMutations } from "vuex";
import ModalReload from "./ModalReload";
import { useI18n } from 'vue-i18n';

export default {
  name: "Bar",
  props: {
    longList: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    List,
    ModalReload,
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      query: "",
      visibleList: false,
    };
  },
  created() {
    this.setModelInput(this.query, this.term);
  },
  computed: {
    ...mapState("search", ["term", "listSuggestions"]),
    ...mapGetters("search", ["getTypeOfSelectedVariant"]),
    ...mapGetters("user", ["getScreenViewport"]),
  },
  watch: {
    term: {
      deep: true,
      handler(term) {
        this.setModelInput(this.query, term);
      },
    },
    visibleList(val) {
      this.setWidgetFocused(val);
    },
  },
  methods: {
    ...mapMutations("search", ["setWidgetFocused", "setSelectedVariant"]),
    inputClickHandler(evt) {
      if (evt.target.classList.contains("input-clear-btn")) {
        this.query = "";
        this.setSelectedVariant(null);
      }
      this.$refs.input.focus();
    },
    onChoice(variant) {
      this.visibleList = false;
      this.query = variant.title;
    },
    clickOutside() {
      this.visibleList = false;
    },
    onClear() {
      this.query = "";
      // Сброс локации города
      this.$store.commit("search/setLocation");
      this.$refs.input.focus();
      this.$store.commit("search/setTerm");
    },
    onFocus() {
      this.visibleList = true;
      this.$nextTick(() => {
        const element = this.$refs.list;

        if (
          this.getScreenViewport === "laptop" &&
          window.innerHeight > element.getBoundingClientRect().bottom
        ) {
          return;
        }
        element.scrollIntoView({
          block: "end",
          behavior: "smooth",
        })

      });
    },
    showList() {
      this.visibleList = true;
      this.$refs.input.focus();
    },
    setModelInput(query, term) {
      if (query != term) {
        this.query =
          this.getTypeOfSelectedVariant === "hotel" ? query : term;
        this.$nextTick(() => {
          this.visibleList = false;
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.suggest {
  &-wrapper {
    position: relative;

    .input-wrapper {
      padding-left: 26px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      border-radius: 30px 0 0 30px;
      background: #ffffff;
      cursor: pointer;
      transition: background-color 0.2s;

      .input-clear-btn {
        position: absolute;
        right: 0;
        margin-right: 20px;
        width: 18px;
        height: 18px;
        background-color: var(--color-gray-g3);
        background-image: url("@/assets/img/icon/close-btn.svg");
        background-position: center center;
        background-repeat: no-repeat;
        border-radius: 32px;
        background-size: contain;
        transition: all 0.3s;
      }
    }

    &--active {
      width: 100%;
      .input-wrapper {
        background: var(--color-gray-g1);
      }
    }
  }
  &-label {
    margin: 0;
    color: var(--color-gray-g5);
    font-size: 12px;
    letter-spacing: 0.2px;
    line-height: 16px;
    pointer-events: none;
    transition: all 0.15s;
  }
  &-input {
    padding: 0;
    width: 100%;
    height: 20px;
    border: none;
    transition: height 0.2s;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-main-black);
    background: inherit;
    &:focus {
      outline: none;
    }
    &::placeholder {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: var(--color-main-black);
    }
  }

  // &-input:focus + label,
  // &-input--fill + label{
  //   font-size: 12px;
  //   line-height: 16px;
  //   letter-spacing: 0.2px;
  // }

  &-close {
    position: absolute;
    right: 0px;
    top: 50%;
    font-size: 16px;
    padding: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0;
  }

  .icon-app-cross-min {
    &::before {
      color: red;
    }
  }
  &-input--fill ~ &-close {
    opacity: 1;
  }
  // &-list {
  //   display: none;
  // }
  &-list {
    position: relative;
    max-height: 80vh;
    // width: 592px;
  }
}
.list-wrapper{
  padding-bottom: 20px;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 4px;
  width: 100%;
  height: auto;
}
</style>
